import { render, staticRenderFns } from "./CenterManagement.vue?vue&type=template&id=fa01ba40&scoped=true&"
import script from "./CenterManagement.vue?vue&type=script&lang=js&"
export * from "./CenterManagement.vue?vue&type=script&lang=js&"
import style0 from "./CenterManagement.vue?vue&type=style&index=0&id=fa01ba40&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa01ba40",
  null
  
)

export default component.exports