<template>
    <div class="fab-container">
  <div class="fab fab-icon-holder">
    <i class="fas fa-question"></i>
  </div>
  <ul class="fab-options">
   
    <li>
      <span class="fab-label">Refund Amount <strong>{{refundamount}} </strong></span>
      
      <div class="fab-icon-holder">
        <i class="fas fa-comments"></i>
      </div>
    </li>
    <li>
      <span class="fab-label">Refund Quantity <strong> {{refundbalance}}</strong></span>
      <div class="fab-icon-holder">
        <i class="fas fa-comment-alt"></i>
      </div>
    </li>
  </ul>
</div>
</template>

<script>
    export default {
        props: ['refundamount', 'refundbalance', 'filtered']
    }
</script>
