<template>
    <div>        
        <div class="table-responsive invoice-table">
                <form>
                    <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                        <th style="width: 30%">Ticket Type </th>
                        <th style="width: 20%"  class="text-center">Ticket SKU {{ ErrorReset }} </th>
                        <th style="width: 20%" class="text-center">Ticket Quantity</th>
                        <th style="width: 20%" class="text-center">Ticket Full Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Sample data rows -->      
                        <tr  style="border-bottom:5pt solid white;" class="ticket-row align-middle"  v-for="(ticket, index) in LoadAllTicekts" :id="index" ref="tabale" :class="[ticket.sku == 'G' ? 'G_class' : ticket.sku == 'SC'? 'SC_class' : ticket.sku == 'C' ? 'C_class' :ticket.sku == 'T' ? 'T_class' :  ticket.sku == 'TC'? 'TC_class' :'FS_class'  ] " >
                        <td>{{ ticket.ticket_display_name}}</td>
                        <td  class="text-center">{{ ticket.sku}}</td>
                        <td >
                            <div class="quantity-input">
                            <button type="button" class="decrement" :id='"decrement"[index]' @click="decrement(index,ticket)"  ref="decbtn" :disabled="disableFields">-</button>
                            <span tabindex="1" :id='"span"[index]'>
                            <input type="number" @keydown="blockNonNumericInput"  v-model.number="Ticketfields[index]" @input="addTicket(index,ticket)" 
                                :name='Ticketfields+[index]'
                                :id='Ticketfields+[index]'
                                ref="Ticketfields" autofocus @click="getfield(index)" :disabled="disableFields"/>
                                    </span>
                            <button type="button" :id='"increment"[index]' class="increment" @click="increment(index,ticket)" ref="incbtn" :disabled="disableFields">+</button>
                            </div>
                        </td>     
                        <td class="text-center">{{ calculateAmount(ticket, index) }}</td>  
                        </tr>                           
                    </tbody>
                    <!-- Total summary row -->
                    <tfoot class="information_section ">                    
                        <tr style="background-color: rgb(245, 149, 132);">
                            <td class="font_20 ">{{ InvoiceNote }}</td>
                            <td class="font_20">Total</td>
                            <td class="text-center font_20"> {{ AllTicket }}</td>
                            <td class="text-center font_20">RS: {{ InvoiceAmount }}</td>
                        </tr>
                        <tr class="text-left">      
                                                  
                            <td class="font_20  ">  
                                <div class="col-md-12">
                                    <div
                                        class="form-group"
                                        :class="{
                                            'is-invalid':
                                                $v.CashAmount.$error ||
                                                errors.CashAmount,
                                            ' is-valid focus':
                                                !$v.CashAmount.$invalid,
                                        }"
                                    >
                                    <label for="CashAmount">Cash</label>
                                        <input
                                            type="number"
                                            min="0"
                                            pattern="[0-9]*"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    $v.CashAmount.$error ||
                                                    errors.CashAmount,
                                                ' is-valid focus':
                                                    !$v.CashAmount.$invalid,
                                            }"
                                            v-model="CashAmount"
                                            id="CashAmount"
                                            @input="CashPay()"
                                            name="CashAmount"
                                            placeholder="Cash Amount"
                                            @blur="$v.CashAmount.$touch()"
                                            ref="cashAmount"
                                            :disabled="cashdisabled"                                           
                                        />
                                      
                                    </div>
                                </div>
                            </td>
                            <td class="font_20" >
                                <div class="col-md-12">
                                                        <div
                                                            class="form-group div"
                                                            :class="{
                                                                'is-invalid':
                                                                    $v.CardAmount.$error ||
                                                                    errors.CardAmount,
                                                                ' is-valid focus':
                                                                    !$v.CardAmount.$invalid,
                                                            }"
                                                        >
                                                        <label for="CardAmount">Card</label>
                                                            <input
                                                                type="number"
                                                                min="0"
                                                                pattern="[0-9]*"
                                                                class="form-control"
                                                                :class="{
                                                                    'is-invalid':
                                                                        $v.CardAmount.$error ||
                                                                        errors.CardAmount,
                                                                    ' is-valid focus':
                                                                        !$v.CardAmount.$invalid,
                                                                }"
                                                                v-model="CardAmount"
                                                                id="CardAmount"
                                                                @input="CashPay()"
                                                                name="CardAmount"
                                                                placeholder="Card Amount"
                                                                @blur="$v.CardAmount.$touch()"
                                                                ref="cardAmount"                                                                
                                                                :disabled="carddisabled"   
                                                            />
                                                        </div>
                                </div>
                            </td>
                            <td class=" font_25 text-danger  align-middle ">Balance : {{ CashBalanace }}</td>
                            <td  class=" align-middle">
                                <button
                                    type="submit"
                                    :class="btnClass"
                                    id="issueBtn"
                                    name="issueBtn"
                                    :disabled="disableFields"
                                    v-on:keyup.enter="Issue()"
                                    @click.prevent="">
                                    {{ issueStatus }}
                                </button>
                            </td>
                        </tr>
                    </tfoot>   
                    </table>
                </form>
        </div>
    </div>
</template>

<style scoped>
form{
    width:auto;
}
thead{
color:green;
font-size: 20px;
}
.table-bordered, .table-bordered th, .table-bordered td ,.table-bordered tr {
    border: none;
}
.table > tbody > tr > td {
     vertical-align: middle;
     color: black;
     font-weight: bold;
     font-size: 20px;
}
td:first-child,
th:first-child {
  border-radius: 10px 0 0 10px;
}

td:last-child,
th:last-child {
  border-radius: 0 10px 10px 0;
}
.information_section{
    font-weight: bold;
}
.font_20{
    font-size:20px;
}
.font_25{
    font-size: 25px;
}
.quantity-input {
    display: flex; 
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 2px;
}
.quantity-input button {
    background-color: #f2f2f2;
    color: #333;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.quantity-input button:hover {
    background-color: #ddd;
}
.quantity-input input {
    border: none;
    font-size:20px;
    width: 90px;
    height: 40px;
    font-weight: bold;
    text-align: center;;
    outline: none;
  }

input:focus {
  border: 5px solid green;
}

.btn-invoice-background-err {
    background-color: #cb4335;
}
.btn-invoice-background-refund-start {
    background-color: #239b56;
}
</style>

<script>
import {
    required,
    minLength,
    maxLength,
    numeric,
    decimal,
    helpers,
} from "vuelidate/lib/validators";
const config = {
    headers: {       
        Accept: "application/json",       
    },
};
let formData = new FormData();

export default {
    props: ['last_invoice'],

    created() {        
        this.AuthCenter = AuthWorkingCenter;   
        this.LastInvoice = this.last_invoice;
        this.ticketItemList = [];
        this.getCenterTicket();         
    },

    mounted(){
      
        window.addEventListener("keydown", (e) => {       
              
              if(this.disableFields == true){                 
          if(e.key == "Escape"){   
            this.$nextTick(() => {    
              e.preventDefault();                 
              window.location.reload();
            });
          }
      }else{               
          if (e.key == "ArrowUp" && this.AddingTicket ) {             
              if(this.rowindex==0 ){
                  this.rowindex = this.$refs.Ticketfields.length -1;                    
              this.$refs.Ticketfields[this.rowindex].focus();
              }else{
                  this.rowindex --;                   ;
                  this.$refs.Ticketfields[this.rowindex].focus();          
              }            
          }else if(e.key == "ArrowDown" && this.AddingTicket){  
              
              if(this.$refs.Ticketfields.length-1 > this.rowindex ){
                  if(this.rowindex == 0){
                      this.rowindex ++;                        
                      this.$refs.Ticketfields[this.rowindex].focus();
              
                  }else{     
                      
                      this.rowindex ++;                                    
                      this.$refs.Ticketfields[this.rowindex].focus();                                      
                  }
              }else{                 
                  this.rowindex=0;                   
                  this.$refs.Ticketfields[this.rowindex].focus();
              }                                           
          }else if(e.key == "Escape"){  
            this.$nextTick(() => {    
              e.preventDefault();                 
              window.location.reload();
            });
          }else  if(e.key == "ArrowRight" && this.AddingTicket){   
              this.increment(this.rowindex,this.LoadAllTicekts[this.rowindex])             
          }else  if(e.key == "ArrowLeft" && this.AddingTicket){                   
              this.decrement(this.rowindex,this.LoadAllTicekts[this.rowindex])             
          }else if (e.key == "a" || e.key == "A") {
              this.AddingTicket=false;
              this.DirectPayment("Cash");
          }else if (e.key == "s" || e.key == "S") {  
              this.AddingTicket=false;          
              this.DirectPayment("Card");
          }else if (e.key == "d" || e.key == "D") {
              this.AddingTicket=false;
              this.ManualPayment('Cash')
          }else if (e.key == "f" || e.key == "F") {
              this.AddingTicket=false;
              this.ManualPayment('Card')
          }else if (e.key == "z" || e.key == "Z") {
              this.clearPaymentFeild();
          }else if (e.key == "x" || e.key == "X") {
              this.AddingTicket=true;
              this.clearPaymentFeild();
          }else if (e.key == "Enter") {
              if(this.disableFields ==true){                     
              }else{                 
                  this.Issue();
              }
          }else{
          }
      }  
  });            
    
    },

    data() {
        return {
            rowindex:0,
            AddingTicket:true,
            Ticketfields: [],
            ticketItemList: [
                {
                    type: "",
                    ticketprice: "",
                    price: "",
                    quantity: "",
                    sn: "",
                    tm: "",
                    rquantity: 0,
                    rprice: 0,
                    sku: "",
                    isrefunded: "null",
                },
            ],          
            AuthCenter: "",
            LastInvoice:"",
            CashAmount: 0,
            CardAmount: 0,
            InvoiceNote: "Cash Payment",
            CashBalanace: (0).toFixed(2),
            cardcash: "",
            CardNumber: "",
            CardBank: "",
            CardType: "",
            cashdisabled: false,
            disabled: 1,
            carddisabled: false,
            issueStatus: "Issue Ticket",
            btnClass: "btn-small-width btn-invoice",
            disabledIsuueStatus: false,
            LoadAllTicekts: [],
            InvoiceAmount: 0,
            AllTicket: 0,
            errors: {},     
            disableFields:false,// enable  
            ErrorReset:false// enable 
        };
    },

    validations: {
      
        CashBalanace: { required, decimal },
        CashAmount: { required, decimal },
        CardAmount: { required, decimal },
        CardNumber: {
            required,
            numeric,
            maxLength: maxLength(4),
            minLength: minLength(4),
        },
        CardBank: { required },
        CardType: { required },
        ticketItemList: {
            type: { required },
        },
    },

    methods: {    
        getfield(index){          
            this.rowindex=index;
        },

        blockNonNumericInput(event) {  
            if (
                (event.keyCode < 96 || event.keyCode > 105) &&
                event.keyCode !== 8 /* Backspace */ &&
                event.keyCode !== 46 /* Backspace */ &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight"
            ) {
                event.preventDefault();
            }
        },

        increment(index,ticket){
            if(this.Ticketfields[index] == undefined || this.Ticketfields[index] == 0){
                this.$set(this.Ticketfields, index, 1);
            }else{ 
                let currentValue = this.Ticketfields[index];    
                currentValue = parseInt(currentValue) + 1;    
                // Update the Ticketfields array with the new value
                this.$set(this.Ticketfields, index, currentValue);
            }
            this.addTicket(index,ticket)
        },

        decrement(index,ticket){
            if(this.Ticketfields[index] > 1){
                let currentValue = this.Ticketfields[index];    
                currentValue = parseInt(currentValue) -1;    
                // Update the Ticketfields array with the new value
                this.$set(this.Ticketfields, index, currentValue);
            }else if(this.Ticketfields[index] == undefined ){               
            }else{
                this.$set(this.Ticketfields, index,null);
            }
            this.addTicket(index,ticket)
        },

        calculateAmount(ticket, index) {
            const quantity = parseInt(this.Ticketfields[index]);
            if (!isNaN(quantity) && quantity >= 0) {
            return (ticket.price * quantity).toFixed(2);
            } else {
            return "0.00"; // Display 0 as the default amount if the input is not a valid number.
            }
        },

        payment(type) {
            if (type === "Cash") {
                window.addEventListener("keydown", (e) => {
                    if (
                        e.code == "Numpad1" ||
                        e.code == "Numpad2" ||
                        e.code == "Numpad3" ||
                        e.code == "Numpad4" ||
                        e.code == "Numpad5" ||
                        e.code == "Numpad6" ||
                        e.code == "Numpad7" ||
                        e.code == "Numpad8" ||
                        e.code == "Numpad9"
                    ) {
                    }if (e.code == "Backspace") {
                        this.$nextTick(() => {  
                            this.CashAmount = 0;
                        });
                    }
                });
            }else {
                window.addEventListener("keydown", (e) => {
                    if (
                        e.code == "Numpad1" ||
                        e.code == "Numpad2" ||
                        e.code == "Numpad3" ||
                        e.code == "Numpad4" ||
                        e.code == "Numpad5" ||
                        e.code == "Numpad6" ||
                        e.code == "Numpad7" ||
                        e.code == "Numpad8" ||
                        e.code == "Numpad9"
                    ) {
                    }if (e.code == "Backspace") {
                        this.$nextTick(() => {  
                            this.CardAmount = 0;
                        });
                    }
                });
            }
        },

        CashPay() { 
            this.$nextTick(() => {              
                if(Number(this.CardAmount) >= Number(this.InvoiceAmount) ){
                    this.InvoiceNote = "Card Payment";
                    this.CardAmount=this.InvoiceAmount
                    this.CashAmount=0
                    this.CashBalanace=(0).toFixed(2);
                    this.cashdisabled=true;
                }else{
                    if(Number(this.CashAmount) >= Number(this.InvoiceAmount)){
                        this.carddisabled=true;  
                        this.CardAmount=0
                        this.InvoiceNote = "Cash Payment";
                    }else{
                        if (this.CardAmount > 0 && this.CashAmount> 0 ){
                        this.InvoiceNote = "Cash And Card  Payment";
                    }else if(this.CashAmount> 0){
                        this.InvoiceNote = "Cash Payment";
                    }else{
                        this.InvoiceNote = "Payment Not Complete";
                    }
                    }             
                }
                var CashWithCardBalance =Number(this.InvoiceAmount - this.CardAmount);
                this.CashBalanace = (Number(this.CashAmount) -Number(CashWithCardBalance)).toFixed(2);
            });
        },        

        DirectPayment($type){
            this.$nextTick(() => {
                if($type == "Cash"){
                    this.payment($type)
                    this.CardAmount=(0).toFixed(2);
                    this.CashAmount=this.InvoiceAmount;
                    this.$refs.cashAmount.focus();
                    this.InvoiceNote ="Cash Payment"
                    this.CashBalanace =(0).toFixed(2);
                    this.cashdisabled=true
                    this.carddisabled=true             
                }else{
                    this.payment($type)
                    this.CashAmount=(0).toFixed(2);
                    this.CardAmount=this.InvoiceAmount;
                    this.cashdisabled=true
                    this.carddisabled=true
                    this.$refs.cardAmount.focus();
                    this.InvoiceNote ="Card Payment"
                    this.CashBalanace =(0).toFixed(2);
                }
            });
        },

        ManualPayment(type){       
            this.$nextTick(() => {           
                this.carddisabled=false
                this.cashdisabled=false            

                if(type == "Cash"){
                    this.payment(type)
                    if(Number(this.CardAmount) == Number(this.InvoiceAmount) ){
                        this.cashdisabled=true
                    }else{
                        this.$refs.cashAmount.focus();
                    }               
                }else{
                    this.payment(type)
                    this.$refs.cardAmount.focus();
                }
            });
        },       

        getCenterTicket() {
            axios
                .get("/cashier/get-center-ticket/" + this.AuthCenter)
                .then((response) => {
                    try {
                    this.LoadAllTicekts = response.data;
                   
                    } catch (error) {
                        console.log(error);
                    }
                  
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong! ",
                    });
                });
        },

        addTicket(index,ticket) {       
            this.$nextTick(() => {            
                    this.rowindex=index;
                    if ( this.ticketItemList.some((o) =>o.sku === ticket.sku) == true) {
                        const my = this.ticketItemList.find((o) =>o.sku === ticket.sku);                
                        if(this.Ticketfields[index] == 0 || this.Ticketfields[index] == undefined ||this.Ticketfields[index] == null ){
                            this.ticketItemList = this.ticketItemList.filter((item) => item.sku !== ticket.sku);
                        }else{
                            my.quantity =this.Ticketfields[index]; //updating existing ticket quantity
                            my.price =this.Ticketfields[index] *my.ticketprice;//updating existing ticket price
                        }            
                    }else{            
                        if(this.Ticketfields[index] > 0){
                        this.ticketItemList.push({
                                        quantity: this.Ticketfields[index],
                                        type: ticket.ticket_display_name,
                                        ticketprice: ticket.price,
                                        price:
                                            this.Ticketfields[index] *
                                            ticket.price,
                                        rquantity: 0,
                                        rprice: 0,
                                        sku: ticket.sku,
                                        isrefunded:"null",
                                    });
                    }else{
                        Toast.fire({ icon: "error", title: "ticket quantity must be grater than 1" });
                    }
                    }
                    this.TicketData(this.ticketItemList);
            });
        },

        Issue() {
            if(confirm("Do you really want to proceed this ?")){
                if( this.ticketItemList.length>0) {
                    if(!this.ErrorReset){
                        this.$nextTick(() => {
                            this.disableFields = true;
                            this.cashdisabled=true;
                            this.carddisabled=true;
                            var spinner =
                                '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div>' +
                                " " +
                                this.issueStatus +
                                "";
                            $("#issueBtn").html(spinner);

                            formData.append("ticket",this.ticketItemList.length == 0 ? null : JSON.stringify(this.ticketItemList));
                            formData.append("amount", this.InvoiceAmount);
                            formData.append("tickets", this.AllTicket);
                            formData.append("paymenttype", this.InvoiceNote);
                            formData.append("CashAmount", this.CashAmount);
                            formData.append("CardAmount", this.CardAmount);
                            formData.append("CashBalanace", this.CashBalanace);
                            formData.append("CardNumber", this.CardNumber);
                            formData.append("CardBank", this.CardBank);
                            formData.append("CardType", this.CardType);

                            console.log(formData);

                            axios
                                .post("/cashier/create-invoice", formData, config)
                                .then((response) => {                                   
                                    this.$nextTick(() => {
                                        try {     

                                            if (response.status === 200) {
                                                if (response.data) {
                                                    console.log(response.data.data);           
                                                    Toast.fire({ icon: "success", title: "Invoice Added" });                         
                                                    
                                                    const printObject = {
                                                        created_at: response.data.data.created_at,
                                                        invoiceId: response.data.data.invoice_id,
                                                        cratedby: response.data.data.cratedby,
                                                        Center: response.data.data.Center,
                                                        ticket: response.data.data.ticket,
                                                    };

                                                    this.$eventBus.$emit('data-sent', printObject.invoiceId);                            
                                                    window.location.href ="pos:" + JSON.stringify(printObject); 
                                                   
                                                    this.btnClass ="btn-small-width btn-invoice btn-invoice-background-refund-start";
                                                    //this.ResetError();

                                                    $("#issueBtn").text('This Invoice Print');                        

                                                }else{
                                                    this.btnClass ="btn-small-width btn-invoice btn-invoice-background-err";
                                                    $("#issueBtn").text('Error Reset one');
                                                   this.disableFields = false;     
                                                   this.ErrorReset =true;
                                                   this.ResetError();
                                                }
                                            }                                                                                               
                                            
                                        }catch (error) {                           
                                                                                  
                                            this.btnClass ="btn-small-width btn-invoice btn-invoice-background-err";
                                            $("#issueBtn").text('Something wrong, but invoice created'.error);                         
                                        }  
                                    });                                                 
                                })
                                .catch((error) => {
                                    this.$nextTick(() => {
                                        this.btnClass ="btn-small-width btn-invoice btn-invoice-background-err";
                                        try {
                                            if(error.response){                                                
                                                this.disableFields = false;
                                                this.cashdisabled=false;
                                                this.carddisabled=false;                           
                                                $("#issueBtn").text(error);
                                                Toast.fire({
                                                icon: "error",
                                                title: error.response.status,
                                                text: error.response.statusText
                                                });

                                                if(error.response.status == 422){                        
                                                     this.errors = error.response.data.errors; 
                                                }         
                                            }else{
                                                $("#issueBtn").text("Reset Error two");
                                               this.ErrorReset =true;
                                               this.ResetError();
                                            }      
                                        
                                        }catch (error) {                           
                                            Toast.fire({ icon: "error", title: error});  
                                            $("#issueBtn").text(error);
                                        }
                                    });                                                  
                                });
                        });
                    }else{
                        $("#issueBtn").text("Reset Error three");
                        this.ResetError();
                    }                    
            }else {
                Toast.fire({
                    icon: "error",
                    title: "Please Check Tickets Felids Or Payment Felids",
                });
            }
            }           
        },       

        TicketData(ticketItemList) {
            this.$nextTick(() => {
                this.InvoiceAmount = ticketItemList
                    .reduce((acc, item) => {
                        return acc + item.price;
                    }, 0)
                    .toFixed(2);

                this.CashAmount=this.InvoiceAmount;

                this.AllTicket = ticketItemList.reduce((add, itemm) => {
                    return add + Number(itemm.quantity);
                }, 0);
                this.CashBalanace =((Number(this.CashAmount) +  Number(this.CardAmount)) - this.InvoiceAmount).toFixed(2);

            });
        },

        clearPaymentFeild() {      
            this.CashAmount = 0.00;
            this.CardAmount = 0.00;
            this.CashBalanace =((Number(this.CashAmount) +  Number(this.CardAmount)) - this.InvoiceAmount).toFixed(2);
            this.InvoiceNote = "Cash Payment";
            this.cashdisabled = false;
            this.disabled = 1;
            this.carddisabled = false;
        },     
        
        ResetError(){
            axios
                .get("/cashier/error-set-no-response/" + this.LastInvoice)
                .then((response) => {
                    try {
                        console.log(response.data.data);  
                        
                        if(!response.data.data){
                            Toast.fire({ icon: "success", title: "No Issue , Ticket Not Added" });  
                            $("#issueBtn").text('Reload and add new ticket');                      
                        }else{
                            const printObject = {
                                created_at: response.data.data.created_at,
                                invoiceId: response.data.data.invoice_id,
                                cratedby: response.data.data.cratedby,
                                Center: response.data.data.Center,
                                ticket: response.data.data.ticket,
                            };

                            this.$eventBus.$emit('data-sent', printObject.invoiceId);                            
                            window.location.href ="pos:" + JSON.stringify(printObject);                                                     

                            Toast.fire({ icon: "success", title: "Error reset and print" });                         
                            this.btnClass ="btn-small-width btn-invoice btn-invoice-background-refund-start";
                            $("#issueBtn").text('This Invoice Print');
                        }                      
                                        
                    }catch (error) {
                        console.log(error);
                        $("#issueBtn").text("Reset Error four");
                        this.ResetError();
                    }                  
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong! ",
                    });
                });
        }

    },
};
</script>
