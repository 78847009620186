<template>
 
<div>      
        <div class="form-group row original" v-if="originaldata == null">
            <div class="col-md-2">
                <label>Original Amount</label>
                <h2>RS: {{OriginalInvoiceAmount}}</h2>
            </div>

            <div class="col-md-3">
                <label>Original Cash </label>
                <h2 >RS: {{invoicedata.CashAmount}}</h2>
            </div>

            <div class="col-md-2">
                <label>Original Card </label>
                <h2 >RS: {{invoicedata.CardAmount}}</h2>
            </div>

            <div class="col-md-2">
                <label>Original Tickets</label>
                <h2> {{AllTicket}}</h2>
            </div>

            <div class="col-md-2">
                <label>Cash Balance</label>
                <h2 >RS: {{CashBalanace}}</h2>
            </div>                     
        </div>

        <div class="form-group row original" v-else>
            <div class="col-md-3">
                <label>Original Amount</label>
                <h2>RS: {{OriginalInvoiceAmount}}</h2>
            </div>

             <div class="col-md-3">
                <label>Original Tickets</label>
                <h2> {{AllTicket}}</h2>
            </div> 

            <div class="col-md-3">
                <label>Refund Amount </label>
                <h2 >RS: {{AllRefundAmount}}</h2>
            </div>   

                  

            <div class="col-md-3">
                <label>Refund Tickets</label>
                <h2> {{RefundTicket}}</h2>
            </div>




           

                              
        </div>

        <div class="form-group row">        
            
            <div class="col-md-2">
                <label>R Ticket</label>
                <h2  class="text-danger" style="font-weight:bold"> {{RefundAllTicket}}</h2>
            </div>          

            <div class="col-md-3">
                <label>R Balance</label>
                <h2 class="text-danger" style="font-weight:bold">RS: {{Refundalance}}</h2>
            </div> 

            <div class=" col-md-6 login-content" v-if="!enabaleRefund"   style="display:block">   
                <div class="form-group row"  style="margin-bottom:0px">
                <!-- Ticket quntyty -->
                        <div class="input-div" :class="{ 'is-invalid input-div-err focus': $v.Ticketfields.quty.$error || errors.quty  ,' is-valid focus': !$v.Ticketfields.quty.$invalid }" >
                            <div class="i">
                             <i class="fas fa-ticket-alt"></i>
                            </div>
                            <div class="div">
                                <label for="quty" class="col-md-12 col-form-label text-md-left">Ticket Quantity</label>   
                                <input type="number" min="0"  pattern="[0-9]*" class=" input form-control" :class="{ 'is-invalid focus':$v.Ticketfields.quty.$error || errors.quty ,' is-valid focus': !$v.Ticketfields.quty.$invalid }" v-model="Ticketfields.quty" name="quty" id="quty"  v-on:keyup.enter='addTicket' @input="$v.Ticketfields.quty.$touch()" @blur="$v.Ticketfields.quty.$touch()"  ref="quty">
                            </div>
                        </div>
                    
                        <span  class="invalid-feedback"  v-if="$v.Ticketfields.quty.$error" >
                            <p v-if=" !$v.Ticketfields.quty.required">The Ticket Quantity field is required.</p> 
                            <p v-if=" !$v.Ticketfields.quty.numeric">The Ticket Quantity format is only numbers.</p>
                            <p v-if=" !$v.Ticketfields.quty.quty">The Ticket Quantity not be negative and grather than 0</p>                      
                        </span>

                        <span  v-if="errors && errors.quty"  class="invalid-feedback">
                            {{ errors.quty[0] }}
                        </span> 
                <!-- End Ticket quntyty -->          
                </div>
            </div>           
        </div>
         
    <div >

        <!-- Ticket Item Table -->
            <table class="table table-hover" id="ticket-list" >
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Type</th>
                        <th scope="col">Ticket price</th> 
                        <th scope="col">Ticket quantity</th> 
                        <th scope="col">Price</th>
                        <th scope="col">R Ticket </th>                         
                        <th scope="col">R Price</th>
                        <th scope="col">Remove</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in  ticketItemList"   ref="tabale">
                        <th scope="row">{{index}}</th>
                        <td>{{item.name}}</td>  
                        <td>{{item.price}}</td>
                        <td>{{item.quantity}}</td> 
                        <td>{{item.total}}</td>
                        <td>{{item.rquntity == null ? 0: item.rquntity }}</td>                      
                        <td>{{item.rprice == null ? 0 : item.rprice.toFixed(2) }}</td>

                        <td v-if="!enabaleRefund" ><a  @click="RemoveTicekt(index)"><!-- 0 block  -->
                        <i class="fas fa-minus-circle text-danger"></i></a>
                        </td>      
                    </tr>  
                </tbody>
            </table>
        <!-- Ticket Item Table -->

        <!-- Payment Section -->
           <div class="container ">             
            <div class="row" v-if="enabaleRefund">
                <button type="btn" :class="btnClass" id="issueBtn" :disabled="disabaleRefundBtn"  v-if="enabaleRefund" @click='Issue()'>Refund This Ticket</button>

                <div class="row text-center">                              
                            <span   v-for="(error,sec) in errors" >
                                <p  class="text-danger">{{error}}</p>
                            </span>                                                             
                        </div>
            </div>

            <div v-if="RefundAllTicket > 0">
               <div class="row cards" v-if="!enabaleRefund " style="margin-top:20px">
                <div class="col-md-12">
                    <form  @submit.prevent="getPermission()">
                        
                        <!-- Authorization -->
                            <div  class="row login-content">
                                <div class="col-md-6">                                    
                                    <!-- Ticket quntyty -->
                                        <div class="input-div" :class="{ 'is-invalid input-div-err focus': $v.Authorization.empID.$error || errors.empID  ,' is-valid focus': !$v.Authorization.empID.$invalid }" >
                                            <div class="i">
                                             <i class="fas fa-ticket-alt"></i>
                                            </div>
                                            <div class="div">
                                                <label for="empID" class="col-md-12 col-form-label text-md-left">Officer Employee ID</label>   
                                                <input type="text" class=" input form-control" :class="{ 'is-invalid focus':$v.Authorization.empID.$error || errors.empID ,' is-valid focus': !$v.Authorization.empID.$invalid }" v-model="Authorization.empID" name="empID" id="empID"  @input="$v.Authorization.empID.$touch()" @blur="$v.Authorization.empID.$touch()"  ref="empID">
                                            </div>
                                        </div>
                                    
                                        <span  class="invalid-feedback"  v-if="$v.Authorization.empID.$error" >
                                            <p v-if=" !$v.Authorization.empID.required">The Officer Employee ID field is required.</p>                                             
                                        </span>

                                     
                                   <!-- End Ticket quntyty -->
                                </div> 


                                 <div class="col-md-6">                    
                                    <!-- Ticket quntyty -->
                                         <div class="input-div" :class="{ 'is-invalid input-div-err focus': $v.Authorization.empPassword.$error || errors.empPassword  ,' is-valid focus': !$v.Authorization.empPassword.$invalid }" >
                                            <div class="i">
                                             <i class="fas fa-lock"></i>
                                            </div>
                                            <div class="div">
                                                <label for="empPassword" class="col-md-12 col-form-label text-md-left">Officer Employee Password</label>   
                                                <input type="password"  class=" input form-control" :class="{ 'is-invalid focus':$v.Authorization.empPassword.$error || errors.empPassword ,' is-valid focus': !$v.Authorization.empPassword.$invalid }" v-model="Authorization.empPassword" name="empPassword" id="empPassword"  @input="$v.Authorization.empPassword.$touch()" @blur="$v.Authorization.empPassword.$touch()"  ref="empPassword">
                                            </div>
                                        </div>
                                    
                                        <span  class="invalid-feedback"  v-if="$v.Authorization.empPassword.$error" >
                                            <p v-if=" !$v.Authorization.empPassword.required">The Officer Employee Password field is required.</p>                                                      
                                        </span>

                                        
                                   <!-- End Ticket quntyty -->
                                </div> 
                            </div>
                        <!-- Authorization -->

                        <div class="row text-center">                                
                            <span   v-for="(error,sec) in errors" >
                                <p  class="text-danger">{{error}}</p>
                            </span>                                                             
                        </div>

                        <button type="submit" class="btn-small-width btn-invoice btn-danger" id="permissionBtn"  :disabled="disabledStatus"  v-on:keyup.enter='getPermission()'>Get Permission</button>

                    </form>
                </div>
              </div>
            </div>

           
           </div>
        <!-- End Payment Section -->  

    </div>

   

</div>
  
</template>

<style scoped> 

form{
    width: 100%;
}

p{
    font-size: 15px;
    margin: 0px;
}

.card-single{
    padding: 1rem;
    background-color: #E8DAEF;
}



th, td {
   border: 1px solid black;
}

.original h2 ,label{
    color: #8E44AD;
    font-weight:bold;
}

  .btn-invoice-background-err{
    background-color: #CB4335;
  }

  .btn-invoice-background-refund-start{
    background-color: #239B56;
  }

</style>

<script>
import { required, minLength, between,maxLength,email,numeric,decimal,helpers} from 'vuelidate/lib/validators';
const quty = helpers.regex("nameVal", /^[1-9]\d*$/);
const q = helpers.regex("nameVal", /^(0|[1-9]\d*)$/);
const config = { headers: { 'Content-Type': 'multipart/form-data',  "Accept": "application/json" }}
let formData = new FormData();

    export default {
        mounted() {           
        },

        created() {  

          
            this.ticketItemList = this.invoiceticket;

        console.log(this.ticketItemList);

              

              if(this.originaldata == null){
               

                   this.InvoiceBalance=this.invoicedata.paymenttype;
                this.CashBalanace=this.invoicedata.CashBalanace;
                
               this.InvoiceAmount=this.invoicedata.amount;
               this.OriginalInvoiceAmount=this.invoicedata.amount;

               this.AllTicket=this.invoicedata.tickets;
                this.CardNumber=this.invoicedata.CardNumber;
                this.CardBank=this.invoicedata.CardBank;
                this.CardType=this.invoicedata.CardType;

              }else{              
              
                this.OriginalInvoiceAmount=this.originaldata.amount;
                this.AllTicket=this.originaldata.tickets;
                this.AllRefundAmount = this.ramount;
                this.RefundTicket=this.rticket;             
            

              }

           
             
this.Center=this.invoicedata.Center

            this.AuthID=Auth;
            this.ticketData.AuthCenter = AuthWorkingCenter;

            window.addEventListener('keydown', (e) => {
                if (e.key == 'q') {
                    this.$refs.catagory.focus();
                }else if(e.key == 'w'){
                    this.$refs.quty.focus();
                    this.$refs.catagory.blur();                             
                }else if(e.key == 'a'){ 
                    this.$refs.cashAmount.focus();   
                    this.payment('cash');
                }else if(e.key =='s'){
                    this.$refs.cardAmount.focus();                      
                    this.payment('card');
                }else if(e.key == 'z'){
                    //this.clearfeild();
                }else if(e.key == 'x'){
                    this.resetfeild();
                }else if(e.key == 'c'){
                    this.$refs.tabale.focus();
                }else if(e.key == 'v'){
                    this.$refs.quty.blur();
                    this.TabaleRowEdit();
                }else if(e.key == 'Escape'){
                   // this.clearfeild();
                    this.ticketItemList=[];
                    this.InvoiceAmount='';                
                    this.AllTicket='';
                }else if(e.key == 'n'){
                    this.paymentFeild.cash = '';
                    this.paymentFeild.balance = '';
                }else if(e.key == 'o'){
                    this.$refs.cashAmount.focus();
                    this.cardcash="CashWithCard"
                   
                }else if(e.key == 'p'){
                    this.cardcash=" "
                 
                }
                else{
                }
            });

       
           // this.getCenterTicket();
        },
        props: ['invoiceticket','invoicedata','originaldata','ramount','rticket'],

       data(){
         return{
               
                ticketData:{ 
                    AuthCenter:'',            
                },

                Ticketfields:{
                    quty:'',
                                  
                },

                Authorization:{
                    empID:'',
                    empPassword:'',
                },

               

                 ticketItemList: [
                  {         
                
                     type:'',
                     ticketprice:'',
                     price:'',
                     quntity:'',  
                     rprice:0,
                     rquntity:0,  

                     
                  }
              ],


              rticketItemList: [
                  {         
                
                     
                     rprice:0,
                     rquntity:0,  

                     
                  }
              ],

                AuthID:'',
                Center:'',
                CashAmount:'',
                CardAmount:'',
                InvoiceBalance:0,
                CashBalanace:0,
                RefundCashBalanace:0,
                Refundalance:0,
                OriginalInvoiceAmount:0,
                cardcash:'',
                CardNumber:'',
                CardBank:'',
                CardType:'',
                cashdisabled:0,
                disabled: 1,
                carddisabled:0,
                enabaleRefund:false,
                disabledStatus:false,
                disabaleRefundBtn:true,
                AllRefundAmount:0,
                btnClass:'btn-small-width btn-invoice',


                RefundRemove:[],
               

                LoadAllTicekts:[],
                InvoiceAmount:0,                
                AllTicket:0,
                RefundTicket:0,
                RefundAllTicket:0,
                errors:{},
                EditQytIndex:'',
              
             

            }

        },


        computed:{
           

        },

          validations: {
          Ticketfields:{
            quty:{required,numeric,quty},
           
                              
          },  

            Authorization:{
                    empID:{required},
                    empPassword:{required},
                },

           CashBalanace:{required,decimal},  
           CashAmount:{required,decimal},
           CardAmount:{required,decimal},
           CardNumber:{required,numeric,maxLength:maxLength(4),minLength: minLength(4)},
           CardBank:{required},
           CardType:{required},
         

            ticketItemList:  {            
            type:{required}, 
         
          

                    
          },       

          
        },

         methods:{

                    

            TabaleRowEdit(){

                var rows = document.getElementById("ticket-list").children[1].children;
                var selectedRow = 0;

                window.addEventListener('keydown', (e) => {               
                //Clear out old row's color
                    rows[selectedRow].style.backgroundColor = "#FFFFFF";
                //Calculate new row
                    if(e.keyCode == 38){
                        selectedRow--;               
                    } else if(e.keyCode == 40){
                        selectedRow++;               
                    }else if(e.key == 'k'){               
                        this.RemoveTicekt(selectedRow);
                    }
                    else if(e.key == 'l'){              
                        this.EditTicketQuty(selectedRow);
                    }

                    if(selectedRow >= rows.length){
                        selectedRow = 0;
                    } else if(selectedRow < 0){
                        selectedRow = rows.length-1;
                    }
                //Set new row's color
                    rows[selectedRow].style.backgroundColor = "#D5F5E3";
                });            

                    rows[selectedRow].style.backgroundColor = "#D5F5E3";
            },

          
            getCenterTicket(){
               
                axios.get('/cashier/get-center-ticket/'+ this.ticketData.AuthCenter)
                .then(response => {

                    this.LoadAllTicekts = response.data;               
                })
                .catch(error => {           
                    Toast.fire({icon: 'error',title: 'Something went wrong! '});   
                });
            },

            addTicket() {            

                if(Number(this.Ticketfields.quty) > 0){

                    if(this.Ticketfields.quty.match(/^(0|[1-9]\d*)$/) == null){                      
                    }else{

                        if(this.originaldata == null){

                            if(this.ticketItemList[this.EditQytIndex].quantity >= Number(this.Ticketfields.quty)){  


                             const data = ((Number(this.ticketItemList[this.EditQytIndex].rquntity)) + (Number(this.Ticketfields.quty)))

                                        if(data >Number(this.ticketItemList[this.EditQytIndex].quantity) ){
                                            Toast.fire({icon: 'error',title: 'Refun Exceehhd please check  '+data}); 
                                        }    
                                    else if(this.ticketItemList[this.EditQytIndex].quantity ==Number(this.Ticketfields.quty) ){                                                                            

                                    this.rticketItemList.push({  rquntity: this.Ticketfields.quty ,                                           
                                                                 rprice: this.Ticketfields.quty * this.ticketItemList[this.EditQytIndex].price}); 
                     
                                    this.ticketItemList[this.EditQytIndex].rquntity=this.Ticketfields.quty;
                                    this.ticketItemList[this.EditQytIndex].rprice=this.Ticketfields.quty * this.ticketItemList[this.EditQytIndex].price;
                                  
                                    this.ticketItemList.splice(this.EditQytIndex,1);
                                                this.TicketData();                           
                                                this.$refs.quty.blur();
                                                this.clearfeild(); 
                                    }

                                    else if(this.ticketItemList[this.EditQytIndex].rquntity >0){      



                                    const data = ((Number(this.ticketItemList[this.EditQytIndex].rquntity)) + (Number(this.Ticketfields.quty)))

                                        if(data >Number(this.ticketItemList[this.EditQytIndex].quantity) ){
                                            Toast.fire({icon: 'error',title: 'Refun Exceed  if refund please check  '+data}); 
                                        }

                                        else if(data == Number(this.ticketItemList[this.EditQytIndex].quantity)){

                                                this.rticketItemList.push({  rquntity: this.Ticketfields.quty ,                                          
                                                                             rprice: this.Ticketfields.quty * this.ticketItemList[this.EditQytIndex].price});  
                                 
                                                this.ticketItemList[this.EditQytIndex].rquntity=Number(this.Ticketfields.quty)+Number(this.ticketItemList[this.EditQytIndex].rquntity);
                                                this.ticketItemList[this.EditQytIndex].rprice=Number(this.ticketItemList[this.EditQytIndex].rquntity) *(Number(this.ticketItemList[this.EditQytIndex].price));                                

                                                this.ticketItemList.splice(this.EditQytIndex,1);
                                                this.TicketData();                           
                                                this.$refs.quty.blur();
                                                this.clearfeild();

                                        }



                                        else{
                                            

                                            this.rticketItemList.push({  rquntity: this.Ticketfields.quty ,                                           
                                                                     rprice: this.Ticketfields.quty * this.ticketItemList[this.EditQytIndex].price});  
                         
                                        this.ticketItemList[this.EditQytIndex].rquntity=Number(this.Ticketfields.quty)+Number(this.ticketItemList[this.EditQytIndex].rquntity);
                                        this.ticketItemList[this.EditQytIndex].rprice=Number(this.ticketItemList[this.EditQytIndex].rquntity) *(Number(this.ticketItemList[this.EditQytIndex].price));                             

                                        this.TicketData();                           
                                        this.$refs.quty.blur();
                                        this.clearfeild(); 

                                        if(this.ticketItemList[this.EditQytIndex].rquntity == this.ticketItemList[this.EditQytIndex].quantity){
                             
                                                 

                                                this.rticketItemList.push({  rquntity: this.Ticketfields.quty ,                                          
                                                                             rprice: this.Ticketfields.quty * this.ticketItemList[this.EditQytIndex].price});  
                                 
                                                this.ticketItemList[this.EditQytIndex].rquntity=Number(this.Ticketfields.quty)+Number(this.ticketItemList[this.EditQytIndex].rquntity);
                                                this.ticketItemList[this.EditQytIndex].rprice=Number(this.ticketItemList[this.EditQytIndex].rquntity) *(Number(this.ticketItemList[this.EditQytIndex].price));                                

                                                this.ticketItemList.splice(this.EditQytIndex,1);
                                                this.TicketData();                           
                                                this.$refs.quty.blur();
                                                this.clearfeild();
                                         }


                                        }                           

                                        
                            }else{

                                  

                                this.rticketItemList.push({  rquntity: this.Ticketfields.quty ,                                           
                                                             rprice: this.Ticketfields.quty * this.ticketItemList[this.EditQytIndex].price}); 

                                this.ticketItemList[this.EditQytIndex].rquntity=this.Ticketfields.quty;
                                this.ticketItemList[this.EditQytIndex].rprice=this.Ticketfields.quty * this.ticketItemList[this.EditQytIndex].price;                             

                               this.TicketData(this.ticketItemList);                           

                                    this.$refs.quty.blur();
                                    this.clearfeild(); 
                                  
                            }
                                    
                            }                          

                            else{
                                Toast.fire({icon: 'error',title: 'Refund Amountff less not be original to  '});  
                            }
                        }
                        //not null
                        else{

                           /* var RefundDED =this.ticketItemList[this.EditQytIndex].rquntity == null ? 0 : this.ticketItemList[this.EditQytIndex].rquntity;                  
                             
                             if(this.ticketItemList[this.EditQytIndex].quntity  - this.ticketItemList[this.EditQytIndex].rquntity == 0){
                                 Toast.fire({icon: 'error',title: 'Cannot refund alarady refund  '}); 
                             }else if(this.ticketItemList[this.EditQytIndex].quntity - Number(RefundDED) >= Number(this.Ticketfields.quty) == false){                                
                                 Toast.fire({icon: 'error',title: 'Refund Amount less thant tog '}); 
                             }
                             else{     
                                
                                this.rticketItemList.push({  rquntity: this.Ticketfields.quty ,                                             
                                                             rprice: this.Ticketfields.quty * this.ticketItemList[this.EditQytIndex].ticketprice}); 

                                this.ticketItemList[this.EditQytIndex].rquntity=Number(this.Ticketfields.quty) + Number(RefundDED);
                                this.ticketItemList[this.EditQytIndex].rprice=Number(this.ticketItemList[this.EditQytIndex].rquntity) * this.ticketItemList[this.EditQytIndex].ticketprice;  
                                this.ticketItemList[this.EditQytIndex].quntity=this.ticketItemList[this.EditQytIndex].quntity;
                                this.ticketItemList[this.EditQytIndex].type=this.ticketItemList[this.EditQytIndex].type;
                                this.ticketItemList[this.EditQytIndex].ticketprice=this.ticketItemList[this.EditQytIndex].ticketprice;

                                this.Refundalance = this.rticketItemList.reduce( (acc, item) => {
                                    return acc +  Number(item.rprice);
                                },0).toFixed(2);

                                this.RefundAllTicket = this.rticketItemList.reduce( (add, itemm) => { 
                                    return add +  Number(itemm.rquntity) ;           
                                },0);   
                          
                      
                                this.$refs.quty.blur();
                                this.clearfeild();  
                             }  */              
                        }
              
                    } 
                }else{
                    console.log("not 0");
                }          
                                             
            },


            
            getPermission(){

                this.disabledStatus=true; 
                var spinner = '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div> Getting Permission...' ;
                $("#permissionBtn").html(spinner);

                this.errors=[];                
                formData.append('empID', this.Authorization.empID);
                formData.append('empPassword', this.Authorization.empPassword);
                formData.append('center', this.ticketData.AuthCenter);             
                     
              axios.post('/cashier/request-refund', formData,config).then(response => {                           

                 if(response.data.data == true){
                     this.enabaleRefund = response.data.data;
                     Toast.fire({ icon: 'success',title: 'Enabale To refund'});  
                     this.disabaleRefundBtn=false;          

                 }else if(response.data.data == false){
                     this.disabledStatus=false; 

                    var spinner = '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div> Getting Permission Faild Try Again ...' ;
                    $("#permissionBtn").html(spinner);
                     Toast.fire({icon: 'error',title:"Password Not Match"}); 
                 }else{
                     Toast.fire({icon: 'error',title:"This Officer cannot process this"}); 
                 }
                                                                  
                }).catch(error => {  
                    this.disabledStatus=false; 
                     var spinner = '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div> Getting Permission Faild Try Again ...' ;
                    $("#permissionBtn").html(spinner);
                    if (error.response.status == 422) {                                      
                       this.errors = error.response.data.errors; 
                       Toast.fire({icon: 'error',title:error.response.data.errors.message}); 

                    }else if(error.response.status == 500){
                        Toast.fire({icon: 'error',title:error.response.data.errors.message}); 
 
                    }else{
                    }             
                   
                });

            },

            Issue(){     
                    this.disabaleRefundBtn=true;   
                    var spinner = '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div> Refunding ...' ;
                    $("#issueBtn").html(spinner);       

                if(this.RefundAllTicket == 0){
                    this.btnClass="btn-small-width btn-invoice btn-invoice-background-err";
                    Toast.fire({icon: 'error',title:'Cannot Refund, No Any Tickets'}); 
                     $("#issueBtn").text("Cannot Refund, No Any Tickets");  
                }else{

                    formData.append('ticket', this.ticketItemList.length == 0 ? null :JSON.stringify(this.ticketItemList) );
                    formData.append('invoice_id', this.invoicedata.invoice_id);                
                    formData.append('ramount', this.Refundalance);
                    formData.append('rtickets', this.RefundAllTicket);                       
                    formData.append('refunddby', this.AuthID);  
                    formData.append('center', this.Center); 
                    formData.append('invoiceType', 'Online Booking');               
                            
               
                  axios.post('/cashier/create-refund', formData,config).then(response => {              
                       //  window.location.href = '/cashier/cashier-summary';                                              
                    }).catch(error => {   
                                
                    this.disabaleRefundBtn=false;  
                    this.btnClass="btn-small-width btn-invoice btn-invoice-background-err";
                      $("#issueBtn").text("Error"); 

                        if (error.response.status == 422) {     
                           this.errors = error.response.data.errors;                            
                        }else if(error.response.status == 500){
                           Toast.fire({icon: 'error',title:error.response.data.errors.message}); 
                        }else{
                        }              
                       
                    });
                }                               
            
            },

          EditTicketQuty(index){          
             this.EditQytIndex= index;      
             this.$refs.quty.focus();

          },         

    RemoveTicekt(index){  
         

            if(this.ticketItemList[index].rquntity >0){

                    this.rticketItemList.push({  rquntity: this.ticketItemList[index].quantity -this.ticketItemList[index].rquntity ,                                             
                                                 rprice:  ((this.ticketItemList[index].quantity-this.ticketItemList[index].rquntity ) *(Number(this.ticketItemList[index].price))) }); 

                    this.ticketItemList[index].rquntity= this.ticketItemList[index].quantity;
                    this.ticketItemList[index].rprice=(this.ticketItemList[index].quantity *(Number(this.ticketItemList[index].price)) );   

                 
               }
               else{

                     this.ticketItemList[index].rquntity= this.ticketItemList[index].quantity - 0;
                     this.ticketItemList[index].rprice=(this.ticketItemList[index].quantity - 0) *(Number(this.ticketItemList[index].price));                                

                     this.rticketItemList.push({  rquntity: this.ticketItemList[index].quantity - 0,                                             
                                                 rprice:  (this.ticketItemList[index].quantity -0 )*   Number( this.ticketItemList[index].price)}); 

               } 


                 this.ticketItemList.splice(index,1);
                 this.TicketData(this.ticketItemList);   
          },


    TicketData(ticketItemList){    
        this.Refundalance = this.rticketItemList.reduce( (acc, item) => {
                            return acc +  Number(item.rprice);
                        },0).toFixed(2);

        this.RefundAllTicket = this.rticketItemList.reduce( (add, itemm) => { 
            return add +  Number(itemm.rquntity) ;           
        },0); 
    },
   

    clearfeild(){
    this.Ticketfields.catagory='';
    this.Ticketfields.quty='';
    this.$v.$reset();
    },

    clearPaymentFeild(){
        this.CashAmount='';
        this.CardAmount='';
        this.RefundCashBalanace='';
        this.InvoiceBalance='';
        this.CardNumber='';
        this.CardBank='';
        this.CardType='';
        this.cashdisabled=0;
        this.disabled= 1;
        this.carddisabled=0;
    },

    resetfeild(){
        this.ticketItemList=[];
        this.InvoiceAmount = '';
        this.AllTicket= '';
    },

  
   

    payment(type){

        if(type === "cash"){        

            window.addEventListener('keydown', (e) => {

                console.log(e.log)
                if(e.code == 'Numpad1' || e.code == 'Numpad2' || e.code == 'Numpad3' || e.code == 'Numpad4' || e.code == 'Numpad5' || e.code == 'Numpad6' || e.code == 'Numpad7' || e.code == 'Numpad8' ||e.code == 'Numpad9'){               
                    
            
                }else if(e.code == 'Backspace'){
                    this.CashAmount='';
                }else if(e.code == 'Delete'){
                    this.clearPaymentFeild();
                }
            });
               
        }else{           

             window.addEventListener('keydown', (e) => {
                if(e.code == 'Numpad1' || e.code == 'Numpad2' || e.code == 'Numpad3' || e.code == 'Numpad4' || e.code == 'Numpad5' || e.code == 'Numpad6' || e.code == 'Numpad7' || e.code == 'Numpad8' ||e.code == 'Numpad9'){                             
                   

                }else if(e.code == 'Backspace'){
                    this.CardAmount='';
                }else if(e.code == 'Delete'){
                    this.clearPaymentFeild();
                }
            });
        }
    }




         }

       


    }
</script>
