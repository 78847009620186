<template>
    <div>
        <!-- Creat New center -->
        <div
            class="modal fade"
            id="NewCenterModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="NewCenterModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div :class="headerClass">
                        <h5
                            class="modal-title"
                            v-show="!editmode"
                            id="NewCenterModalLabel"
                        >
                            {{ createUserh5 }}
                        </h5>
                        <h5
                            class="modal-title"
                            v-show="editmode"
                            id="EditCenterModalLabel"
                        >
                            {{ createUserh5 }}
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            @click="CloseModel"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body login-content">
                        <form
                            class="full-with-form"
                            @submit.prevent="
                                editmode ? updateCenter() : CreateCenter()
                            "
                        >
                            <div class="form-group row" style="padding:25px">
                                <div class=" col-md-12">
                                    <!-- Center Name -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.fields.name.$error ||
                                                errors.name,
                                            ' is-valid focus': !$v.fields.name
                                                .$invalid
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-tree"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="name"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Center Name</label
                                            >
                                            <input
                                                type="text"
                                                class=" input form-control"
                                                :class="{
                                                    'is-invalid focus':
                                                        $v.fields.name.$error ||
                                                        errors.name,
                                                    ' is-valid focus': !$v
                                                        .fields.name.$invalid
                                                }"
                                                v-model="fields.name"
                                                name="name"
                                                id="name"
                                                autocomplete="center-name"
                                                @input="$v.fields.name.$touch()"
                                                @blur="$v.fields.name.$touch()"
                                                @click="clear('name')"
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.fields.name.$error"
                                    >
                                        <p v-if="!$v.fields.name.required">
                                            The Center Name field is required.
                                        </p>
                                        <p v-if="!$v.fields.name.alphawithspa">
                                            The Center Name format is only
                                            contain letters and spaces.
                                        </p>
                                        <p v-if="!$v.fields.name.minLength">
                                            The Center Name must be at least 3
                                            characters.
                                        </p>
                                        <p v-if="!$v.fields.name.maxLength">
                                            The Center Name must not be greater
                                            than 50 characters.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.name"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.name[0] }}
                                    </span>
                                    <!-- End Center Name -->

                                    <!-- Center Location -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.fields.location.$error ||
                                                errors.location,
                                            ' is-valid focus': !$v.fields
                                                .location.$invalid
                                        }"
                                    >
                                        <div class="i">
                                            <i
                                                class="fas fa-location-arrow"
                                            ></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="location"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Location</label
                                            >
                                            <input
                                                type="text"
                                                class=" input form-control"
                                                :class="{
                                                    'is-invalid':
                                                        $v.fields.location
                                                            .$error ||
                                                        errors.location,
                                                    ' is-valid': !$v.fields
                                                        .location.$invalid
                                                }"
                                                v-model="fields.location"
                                                name="location"
                                                id="location"
                                                autocomplete="center-location"
                                                @input="
                                                    $v.fields.location.$touch()
                                                "
                                                @blur="
                                                    $v.fields.location.$touch()
                                                "
                                                @click="clear('location')"
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.fields.location.$error"
                                    >
                                        <p v-if="!$v.fields.location.required">
                                            The Location field is required.
                                        </p>
                                        <p v-if="!$v.fields.location.minLength">
                                            The Location must be at least 3
                                            characters.
                                        </p>
                                        <p v-if="!$v.fields.location.maxLength">
                                            The Location must not be greater
                                            than 50 characters.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.location"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.location[0] }}
                                    </span>
                                    <!-- End Center Location -->

                                    <!-- Email -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.fields.email.$error ||
                                                errors.email,
                                            ' is-valid focus': !$v.fields.email
                                                .$invalid
                                        }"
                                    >
                                        <div class="i">
                                            <i class="far fa-envelope-open"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="email"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Email</label
                                            >
                                            <input
                                                type="email"
                                                class=" input form-control"
                                                autocomplete="center-email"
                                                :class="{
                                                    'is-invalid':
                                                        $v.fields.email
                                                            .$error ||
                                                        errors.email,
                                                    ' is-valid': !$v.fields
                                                        .email.$invalid
                                                }"
                                                v-model="fields.email"
                                                name="email"
                                                id="email"
                                                @input="
                                                    $v.fields.email.$touch()
                                                "
                                                @blur="$v.fields.email.$touch()"
                                                @click="clear('email')"
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.fields.email.$error"
                                    >
                                        <p v-if="!$v.fields.email.required">
                                            The Email field is required.
                                        </p>
                                        <p v-if="!$v.fields.email.email">
                                            Please enter a valid email address.
                                        </p>
                                        <p v-if="!$v.fields.email.maxLength">
                                            The Email must not be greater than
                                            25 characters.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.email"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.email[0] }}
                                    </span>
                                    <!-- End Email -->

                                    <!-- Phone Number -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.fields.contactno.$error ||
                                                errors.contactno,
                                            ' is-valid focus': !$v.fields
                                                .contactno.$invalid
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-phone"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="contactno"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Center Contact Number</label
                                            >
                                            <input
                                                type="text"
                                                class=" input form-control"
                                                :class="{
                                                    'is-invalid':
                                                        $v.fields.contactno
                                                            .$error ||
                                                        errors.contactno,
                                                    ' is-valid': !$v.fields
                                                        .contactno.$invalid
                                                }"
                                                v-model="fields.contactno"
                                                name="contactno"
                                                id="contactno"
                                                autocomplete="center-contact-number"
                                                @input="
                                                    $v.fields.contactno.$touch()
                                                "
                                                @blur="
                                                    $v.fields.contactno.$touch()
                                                "
                                                @click="clear('contactno')"
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.fields.contactno.$error"
                                    >
                                        <p v-if="!$v.fields.contactno.required">
                                            The Center Contact Number field is
                                            required.
                                        </p>
                                        <p v-if="!$v.fields.contactno.numeric">
                                            The Center Contact Number must be a
                                            number.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.fields.contactno.minLength
                                            "
                                        >
                                            The Center Contact Number must be 10
                                            Numbers.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.fields.contactno.maxLength
                                            "
                                        >
                                            The Center Contact Number must be 10
                                            Numbers.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.contactno"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.contactno[0] }}
                                    </span>
                                    <!-- End Phone Number -->

                                    <!-- Center Address -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.fields.address.$error ||
                                                errors.address,
                                            ' is-valid focus': !$v.fields
                                                .address.$invalid
                                        }"
                                    >
                                        <div class="i">
                                            <i
                                                class="fas fa-map-marked-alt"
                                            ></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="address"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Address</label
                                            >
                                            <input
                                                type="text"
                                                class=" input form-control"
                                                :class="{
                                                    'is-invalid':
                                                        $v.fields.address
                                                            .$error ||
                                                        errors.address,
                                                    ' is-valid': !$v.fields
                                                        .address.$invalid
                                                }"
                                                v-model="fields.address"
                                                name="address"
                                                id="address"
                                                autocomplete="center-address"
                                                @input="
                                                    $v.fields.address.$touch()
                                                "
                                                @blur="
                                                    $v.fields.address.$touch()
                                                "
                                                @click="clear('address')"
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.fields.address.$error"
                                    >
                                        <p v-if="!$v.fields.address.required">
                                            The Address field is required.
                                        </p>
                                        <p v-if="!$v.fields.address.minLength">
                                            The Address must be at least 10
                                            characters.
                                        </p>
                                        <p v-if="!$v.fields.address.maxLength">
                                            The Address must not be greater than
                                            200 characters.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.address"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.address[0] }}
                                    </span>
                                    <!-- End Center Address -->
                                </div>
                            </div>

                            <div class="row modal-footer border-0">
                                <div class="col-md-6">
                                    <button
                                        v-show="!editmode"
                                        type="button"
                                        class="btn-small-width btn-warning"
                                        @click="clearFields"
                                    >
                                        Clear All Fields
                                    </button>
                                    <button
                                        v-show="editmode"
                                        type="button"
                                        class="btn-small-width btn-danger"
                                        @click="CloseModel"
                                    >
                                        Close
                                    </button>
                                </div>
                                <div class="col-md-3">
                                    <button
                                        type="submit"
                                        class="btn-small-width btn-success"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Creat New User -->

        <div v-show="loading" class="text-center">
            <div
                class="spinner-border spinner-border-lg text-success"
                role="status"
            >
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- User Data Table -->
        <div class="UserData" v-show="!loading">
            <div class="login-content" style="display:block">
                <div class="form-group row  align-items-center">
                    <div class=" col-md-6">
                        <div
                            class="input-div"
                            :class="{
                                'is-invalid input-div-err focus':
                                    $v.tableData.search.$error || errors.search,
                                ' is-valid focus': !$v.tableData.search.$invalid
                            }"
                        >
                            <div class="i">
                                <i class="fas fa-search"></i>
                            </div>
                            <div class="div">
                                <label
                                    for="search"
                                    class="col-md-12 col-form-label text-md-left"
                                    >Search</label
                                >
                                <input
                                    type="text"
                                    class=" input form-control"
                                    :class="{
                                        'is-invalid focus':
                                            $v.tableData.search.$error ||
                                            errors.search,
                                        ' is-valid focus': !$v.tableData.search
                                            .$invalid
                                    }"
                                    v-model="tableData.search"
                                    name="search"
                                    id="search"
                                    @input="getCenters()"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="control text-left">
                            <div class="select">
                                Show Rows
                                <select
                                    id="rows"
                                    name="rows"
                                    v-model="tableData.length"
                                    @change="getCenters()"
                                    style="margin:10px"
                                >
                                    <option
                                        v-for="(records, index) in perPage"
                                        :key="index"
                                        :value="records"
                                        >{{ records }}</option
                                    >
                                </select>
                            </div>
                        </div>
                    </div>

                    <div
                        v-can="'Create Center'"
                        class="col-md-3 align-items-right"
                    >
                        <button
                            type="button"
                            class="btn-small-width btn-success-new "
                            data-toggle="modal"
                            data-target="#NewCenterModal"
                            @click="OpenCenter"
                        >
                            <i class="fas fa-user-plus"> </i> Create new center
                        </button>
                    </div>
                </div>
            </div>

            <datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
            >
                <tbody v-for="project in UserData">
                    <tr
                        :key="project.id"
                        v-if="project.status == 0"
                        style="background-color:#fba5a54a"
                    >
                        <td>{{ project.centercode }}</td>
                        <td>{{ project.name }}</td>
                        <td>{{ project.location }}</td>
                        <td>{{ project.email }}</td>
                        <td>{{ project.contactno }}</td>
                        <td v-can="'Edit Center'">
                            <i class="fas fa-ban text-danger"></i>
                        </td>

                        <td v-canelase="'Edit Center'">
                            -
                        </td>

                        <td v-can="'UnBlock Center'">
                            <a
                                @click="
                                    EnabalOrDisabale(project.centercode, true)
                                "
                                ><!-- 0 block -->
                                <i class="far fa-eye-slash text-danger"></i
                            ></a>
                        </td>
                    </tr>
                    <tr :key="'block_center' + project.id" v-else>
                        <td>{{ project.centercode }}</td>
                        <td>{{ project.name }}</td>
                        <td>{{ project.location }}</td>

                        <td>{{ project.email }}</td>
                        <td>{{ project.contactno }}</td>
                        <td v-can="'Edit Center'">
                            <a @click="OpenEditCenter(project)">
                                <i class="far fa-edit text-warning"></i
                            ></a>
                        </td>
                        <td v-canelase="'Edit Center'">
                            -
                        </td>
                        <td v-can="'Block Center'">
                            <a
                                @click="
                                    EnabalOrDisabale(project.centercode, false)
                                "
                                ><!-- 1 not block   -->
                                <i class="far fa-eye text-success"></i
                            ></a>
                        </td>
                    </tr>
                </tbody>
            </datatable>
            <pagination
                :pagination="pagination"
                @prev="getCenters(pagination.prevPageUrl)"
                @next="getCenters(pagination.nextPageUrl)"
            >
            </pagination>
        </div>
        <!-- End User tabale -->
    </div>
</template>

<style scoped>
a:hover {
    cursor: pointer;
}
</style>

<script>
import {
    required,
    minLength,
    between,
    maxLength,
    email,
    numeric
} from "vuelidate/lib/validators";
import { alphawithspa } from "vuelidate/lib/validators/alphawithspace";

const config = {
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json"
    }
};
let formData = new FormData();

export default {
    created() {
        this.AuthRole = Role;
        this.getCenters();
    },
    data() {
        let sortOrders = {};

        let columns = [
            { width: "10%", label: "Code", name: "centercode" },
            { width: "20%", label: "Name", name: "name" },
            { width: "10%", label: "Location", name: "location" },
            { width: "20%", label: "Email", name: "email" },
            { width: "10%", label: "Contact Number", name: "contactno" },
            { width: "8%", label: "Edit", name: "edit" },
            { width: "10%", label: "Status", name: "block" }
        ];

        columns.forEach(column => {
            sortOrders[column.name] = -1;
        });
        return {
            fields: {
                index: "",
                name: "",
                email: "",
                centercode: "",
                contactno: "",
                location: "",
                address: ""
            },

            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "desc",
                role: ""
            },

            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: ""
            },

            headerClass: "modal-header",
            createUserh5: "Create New Center",
            avatar: "/img/avatar.svg",
            picval: "",
            editmode: "",
            RoleEditmode: false,
            permissioneditmode: false,
            status: "",
            success: false,
            loading: true,
            statusText: "",
            errors: {},
            AllRoleData: [],
            AuthRole: [],
            UserData: [],
            columns: columns,
            sortKey: "name",
            sortOrders: sortOrders,
            perPage: ["10", "20", "30"]
        };
    },

    validations: {
        fields: {
            name: {
                required,
                minLength: minLength(3),
                alphawithspa,
                maxLength: maxLength(50)
            },
            email: { required, email, maxLength: maxLength(25) },
            centercode: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(25)
            },
            contactno: {
                required,
                numeric,
                maxLength: maxLength(10),
                minLength: minLength(10)
            },
            location: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50)
            },
            address: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(200)
            }
        },

        tableData: {
            search: { required }
        }
    },

    methods: {
        //Center Section
        CreateCenter() {
            formData.append("_method", "POST");
            formData.append("name", this.fields.name);
            formData.append("email", this.fields.email);
            formData.append("contactno", this.fields.contactno);
            formData.append("location", this.fields.location);
            formData.append("address", this.fields.address);

            axios
                .post("/center-setting/create-center", formData, config)
                .then(response => {
                    this.CloseModel();
                    Toast.fire({ icon: "success", title: "New Center Added" });
                    this.getCenters();
                })
                .catch(error => {
                    console.log(error);
                    console.log(error.response);

                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.createUserh5 =
                            "Create New Center - Validation Erros";
                        this.errors = error.response.data.errors;
                    } else {
                        this.createUserh5 =
                            "Create New Center - Something went wrong /" +
                            error.response.status;
                    }
                });
        },

        updateCenter() {
            formData.append("name", this.fields.name);
            formData.append("email", this.fields.email);
            formData.append("contactno", this.fields.contactno);
            formData.append("location", this.fields.location);
            formData.append("address", this.fields.address);

            axios
                .post(
                    "/center-setting/edit-center/" + this.fields.index,
                    formData,
                    config
                )
                .then(response => {
                    this.CloseModel();
                    Toast.fire({ icon: "success", title: "Center Updated" });
                    this.getCenters();
                })
                .catch(error => {
                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.createUserh5 = "Edit Center - Validation Erros";
                        this.errors = error.response.data.errors;
                    } else {
                        this.createUserh5 =
                            "Edit Center - Something went wrong /" +
                            error.response.data.errors.message;
                    }
                });
        },

        getCenters(url = "/center-setting/centers") {
            this.tableData.draw++;
            this.tableData.role = this.AuthRole;

            axios
                .get(url, { params: this.tableData })
                .then(response => {
                    let data = response.data;

                    if (this.tableData.draw == data.draw) {
                        this.UserData = data.data.data;
                        this.configPagination(data.data);
                    }

                    this.loading = false;
                })
                .catch(errors => {
                    this.loading = false;

                    console.log(errors.response);
                    if (errors.response.status == 403) {
                        Toast.fire({
                            icon: "error",
                            title:
                                "Forbidden , User does not have the right roles "
                        });
                    }
                });
        },

        OpenCenter() {
            $("#NewCenterModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true
            });
            this.createUserh5 = "Create New Center";
            this.editmode = false;
        },

        OpenEditCenter($userData) {
            this.editmode = true;

            $("#NewCenterModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true
            });
            //   this.LoadAllRole();
            this.createUserh5 = "Edit Center";
            this.fields.index = $userData.id;
            this.fields.name = $userData.name;
            this.fields.email = $userData.email;
            this.fields.centercode = $userData.centercode;
            this.fields.contactno = $userData.contactno;
            this.fields.location = $userData.location;
            this.fields.address = $userData.address;
            //   $userData.avatar === null ? this.avatar='/img/avatar.svg' :  this.avatar='/storage/UserProfile/'+ $userData.avatar;
        },

        CloseModel() {
            console.log("close");
            $("#NewCenterModal").modal("hide");
            this.clearFields();
        },

        //End User Profile Section

        //User Status Section
        EnabalOrDisabale($centercode, $status) {
            if ($status == true) {
                this.statusText = "Activate";
            } else {
                this.statusText = "Deactivate";
            }

            Swal.fire({
                title: "Are you sure?",
                text: "Do you want to " + this.statusText + " this Center",
                showCancelButton: true,
                confirmButtonColor: "#239B56",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, " + this.statusText + " this !"
            }).then(result => {
                if (result.isConfirmed) {
                    formData.append("centercode", $centercode);
                    formData.append("status", $status);

                    axios
                        .post("/center-setting/status-center", formData, config)
                        .then(responce => {
                            Toast.fire({
                                icon: "success",
                                title: "Center Status Updated"
                            });
                            this.getCenters();
                        })
                        .catch(error => {
                            Toast.fire({
                                icon: "error",
                                title:
                                    "Something went wrong!" +
                                    error.response.data.errors.message
                            });
                        });
                }
            });
        },
        //End satatus Section

        //Data tabale Section
        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

        sortBy(key) {
            this.sortKey = key;
            this.sortOrders[key] = this.sortOrders[key] * -1;
            this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
            this.getCenters();
        },

        getIndex(array, key, value) {
            return array.findIndex(i => i[key] == value);
        },
        //End Data Tabale section

        //Clear Section
        clear(feild) {
            this.$delete(this.errors, feild);
        },

        clearFields() {
            console.log("clear");
            this.fields.name = "";
            this.fields.email = "";
            this.fields.centercode = "";
            this.fields.contactno = "";
            this.fields.location = "";
            this.fields.address = "";
            this.createUserh5 = "";
            this.headerClass = "";
            this.clearError();
            this.editmode = "";
            this.headerClass = "modal-header";
            this.createUserh5 = "Create New Center";
        },

        clearError() {
            this.$v.$reset();
            this.errors = [];
        }
        //End Clear Section
    }
};
</script>
