<template>
    <div>
        <!-- Input section -->
        <div>
            <div class="login-content" style="display: block">
                <div class="form-group row align-items-center">
                    <div class="col-md-4">
                        <label
                            for="center"
                            class="col-md-12 col-form-label text-md-left"
                            >Select Center</label
                        >

                        <div
                            class="input-div"
                            :class="{
                                'is-invalid input-div-err focus':
                                    $v.center.$error,
                                ' is-valid focus': !$v.center.$invalid,
                            }"
                        >
                            <div class="i">
                                <i class="fas fa-house-user"></i>
                            </div>
                            <div class="div">
                                <select
                                    class="input form-select"
                                    aria-label="Default select example"
                                    :class="{
                                        'is-invalid': $v.center.$error,
                                        ' is-valid': !$v.center.$invalid,
                                    }"
                                    v-model="center"
                                    name="center"
                                    id="center"
                                    required
                                    @blur="$v.center.$touch()"
                                    ref="center"
                                >
                                    <option selected disabled value="">
                                        Select Center
                                    </option>
                                    <option
                                        v-for="centers in AllCenterData"
                                        v-bind:value="centers.centercode"
                                    >
                                        {{ centers.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <span
                            v-if="errors && errors.center"
                            class="invalid-feedback"
                        >
                            {{ errors.center[0] }}
                        </span>
                    </div>

                    <div class="col-md-4">
                        <div class=" ">
                            <label
                                for="DateFrom"
                                class="col-md-12 col-form-label text-md-left"
                                >From</label
                            >
                            <div class="input-div">
                                <div class="i">
                                    <i class="fas fa-calendar-alt"></i>
                                </div>
                                <div class="div">
                                    <input
                                        type="date"
                                        class="input form-control"
                                        v-model="DateFrom"
                                        name="DateFrom"
                                        id="DateFrom"
                                        v-on:keyup.enter="addTicket"
                                        @input="$v.DateFrom.$touch()"
                                        @blur="$v.DateFrom.$touch()"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class=" ">
                            <label
                                for="DateTo"
                                class="col-md-12 col-form-label text-md-left"
                                >To</label
                            >
                            <div
                                class="input-div"
                                :class="{
                                    'is-invalid input-div-err focus':
                                        $v.DateTo.$error,
                                    ' is-valid focus': !$v.DateTo.$invalid,
                                }"
                            >
                                <div class="i">
                                    <i class="fas fa-calendar-alt"></i>
                                </div>
                                <div class="div">
                                    <input
                                        type="date"
                                        class="input form-control"
                                        :class="{
                                            'is-invalid focus':
                                                $v.DateTo.$error ||
                                                errors.DateTo,
                                            ' is-valid focus':
                                                !$v.DateTo.$invalid,
                                        }"
                                        v-model="DateTo"
                                        name="DateTo"
                                        id="DateTo"
                                        v-on:keyup.enter="addTicket"
                                        @input="$v.DateTo.$touch()"
                                        @blur="$v.DateTo.$touch()"
                                    />
                                </div>
                            </div>
                        </div>

                        <span
                            v-if="errors && errors.to"
                            class="invalid-feedback"
                        >
                            {{ errors.to[0] }}
                        </span>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-md-2">
                        <div>
                            <button
                                type="button"
                                class="btn-small-width btn-purple"
                                v-on:click="GetData()"
                            >
                                Get Data
                            </button>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div>
                            <button
                                type="button"
                                class="btn-small-width btn-purple"
                                v-on:click="GetList()"
                            >
                                Get List
                            </button>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div>
                            <button
                                type="button"
                                class="btn-small-width btn-purple"
                                v-on:click="Clear()"
                            >
                                Clear Value
                            </button>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <button
                            type="button"
                            class="btn-small-width btn-yello"
                            v-on:click="PrintOnlyData()"
                        >
                            <i class="fas fa-print"></i> Print Only Data
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End input section -->

        <!-- Data view section -->
            <div class="container">
                <div class="row" v-if="CenterTicketSummary.length > 0">
                    <table class="table">
                        <thead>
                            <tr>
                            <th scope="col">SKU</th>
                            <th class="text-center">Quantity</th>
                            <th class="text-right">Price</th>
                            <th class="text-right">Amount</th>
                            <th class="text-center">Refund Quantity</th>
                            <th class="text-right">Refund Amount</th>
                            <th class="text-right">Final Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="ticket in CenterTicketSummary">
                            <th scope="row" :class="[ticket.sku == 'G' ? 'G_class' : ticket.sku == 'SC'? 'SC_class' : ticket.sku == 'C' ? 'C_class' :ticket.sku == 'T' ? 'T_class' :  ticket.sku == 'TC'? 'TC_class' :'FS_class'  ] " >{{ ticket.sku }}</th>
                            <td  class="text-center">{{ ticket.quantity }}</td>
                            <td class="text-right">{{ ticket.price }}</td>
                            <td class="text-right">{{ ticket.amount }}</td>
                            <td  class="text-center">{{ ticket.rquantity }}</td>
                            <td class="text-right">{{ ticket.r_amount }}</td>
                            <td class="text-right">{{ ticket.final_amount }}</td>
                            </tr>        
                        </tbody>
                    </table>
                </div>

                <!-- Cash Section -->
                <div class="row">
                        <div class="col-md-4">
                            <label>Cash Amount</label>
                            <p>LKR : {{ CenterCash }}</p>
                        </div>
                        <div class="col-md-4">
                            <label>Cash Refund Amount</label>
                            <p>LKR : {{ CenterCashRefund }}</p>
                        </div>
                        <div class="col-md-4">
                            <label>Cash In Hand</label>
                            <p>LKR : {{ CashInHand }}</p>
                        </div>
                </div>
                <!-- End Cash Section -->

                <!-- Card Section -->
                    <div class="row">
                        <div class="col-md-4">
                            <label>Card Amount</label>
                            <p>LKR : {{ CenterCard }}</p>
                        </div>
                        <div class="col-md-4">
                            <label>Card Refund Amount</label>
                            <p>LKR : {{ CenterCardRefund }}</p>
                        </div>
                        <div class="col-md-4">
                            <label>Card Balance</label>
                            <p>LKR : {{ CardBalance }}</p>
                        </div>
                    </div>
                <!--End Cash Section -->

                <div class="row">
                    <div class="col-md-4">
                        <h6>Center Balance</h6>
                        <p>LKR : {{ Balance }}</p>
                    </div>
                    <div class="col-md-4">
                        <h6>Total Invoices</h6>
                        <p>{{ TotalInvoices }}</p>
                    </div>
                    <div class="col-md-4">
                        <h6>Total Issue Tickets</h6>
                        <p>{{ TotalIssueTickets }}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <h6>Total Refund Amount</h6>
                        <p>LKR {{ TotalrefundAmount }}</p>
                    </div>

                    <div class="col-md-4">
                        <h6>Total Refund Times</h6>
                        <p>{{ TotalRefundInvoice }}</p>
                    </div>

                    <div class="col-md-4">
                        <h6>Total Refund Tickets</h6>
                        <p>{{ TotalRefundIssueTickets }}</p>
                    </div>
                </div> 
            </div>     
        <!-- EndData view section -->

        <!-- User Data Table -->
        <div v-if="InvoiceData.length > 0">
            <div class="login-content" style="display: block">
                <div class="form-group row align-items-center">
                    <div class="col-md-12">
                        <div
                            class="input-div"
                            :class="{
                                'is-invalid input-div-err focus':
                                    $v.tableData.search.$error || errors.search,
                                ' is-valid focus':
                                    !$v.tableData.search.$invalid,
                            }"
                        >
                            <div class="i">
                                <i class="fas fa-search"></i>
                            </div>
                            <div class="div">
                                <label
                                    for="search"
                                    class="col-md-12 col-form-label text-md-left"
                                    >Search by using Invoice ID / Invoice Type
                                    and Payment Type</label
                                >
                                <input
                                    type="text"
                                    class="input form-control"
                                    :class="{
                                        'is-invalid focus':
                                            $v.tableData.search.$error ||
                                            errors.search,
                                        ' is-valid focus':
                                            !$v.tableData.search.$invalid,
                                    }"
                                    v-model="tableData.search"
                                    name="search"
                                    id="search"
                                    @input="GetList()"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-md-4">
                        <div class="control text-left">
                            <div class="select">
                                Rows
                                <select
                                    name="row"
                                    id="row"
                                    v-model="tableData.length"
                                    @change="GetList()"
                                    style="margin: 10px"
                                >
                                    <option
                                        v-for="(records, index) in perPage"
                                        :key="index"
                                        :value="records"
                                    >
                                        {{ records }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div
                        v-is="'Super Admin|Admin|Center Supervisor'"
                        class="col-md-4 align-items-left"
                    >
                        <button
                            type="button"
                            class="btn-small-width btn-yello"
                            v-on:click="PrintOnlyList()"
                        >
                            <i class="fas fa-print"></i> Print List
                        </button>
                    </div>

                    <div
                        v-is="'Super Admin|Admin|Center Supervisor'"
                        class="col-md-4 align-items-left"
                    >
                        <button
                            type="button"
                            class="btn-small-width btn-success-new"
                            v-on:click="PrintDataWithList()"
                        >
                            <i class="fas fa-print"></i> Print List With Data
                        </button>
                    </div>
                </div>
            </div>

            <datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
            >
                <tbody v-for="project in InvoiceData">
                    <tr :key="project.id">
                        <td>{{ project.invoice_id }}</td>
                        <td>{{ project.created_at }}</td>
                        <td>{{ project.invoice_type }}</td>
                        <td>{{ project.tickets }}</td>
                        <td>{{ project.amount }}</td>
                        <td>
                            <a
                                :href="
                                    '/reports/view-invoice/' +
                                    project.invoice_id
                                "
                                ><!-- 0 block  -->
                                <i class="fas fa-eye"></i
                            ></a>
                        </td>
                    </tr>
                </tbody>
            </datatable>
            <pagination
                :pagination="pagination"
                @prev="GetList(pagination.prevPageUrl)"
                @next="GetList(pagination.nextPageUrl)"
            >
            </pagination>
        </div>
        <!-- End User table -->
    </div>
</template>

<style scoped>
p {
    color: black;
    font-weight: bold;
    font-size: 20px;
}

a:hover {
    cursor: pointer;
}

</style>

<script>
import {
    required,
    minLength,
    maxLength,
    email,
    numeric,
} from "vuelidate/lib/validators";
import { alphawithspa } from "vuelidate/lib/validators/alphawithspace";

const config = {
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
    },
};
let formData = new FormData();

export default {
    created() {
        this.AuthRole = Role;
        this.GetAllCenters();
    },
    data() {
        let sortOrders = {};

        let columns = [
            { width: "20%", label: "InvoiceID", name: "invoiceid" },
            { width: "20%", label: "Date & Time", name: "date_time" },
            { width: "10%", label: "Invoice Type", name: "invoice_type" },
            { width: "5%", label: "Tickets", name: "tickets" },
            { width: "10%", label: "Amount", name: "amount" },
            { width: "5%", label: "View", name: "view" },
        ];

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });
        return {
            fields: {
                index: "",
                name: "",
                email: "",
                centercode: "",
                contactno: "",
                location: "",
                address: "",
            },

            para: {
                center: "",
                from: "",
                to: "",
            },

            center: "",
            AllCenterData: [],
            DateFrom: "",
            DateTo: "",

            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "desc",
                CenterName: "",
                From: "",
                To: "",
            },

            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
            },

            
            CenterCash:"",
            CenterCard:"",
            CenterCashRefund:"",
            CenterCardRefund:"",
            CashInHand:"",
            CardBalance:"",
            Balance: "",
            TotalInvoices: "",
            TotalIssueTickets: "",
            TotalRefundInvoice: "",
            TotalrefundAmount: "",
            TotalRefundIssueTickets: "",
            CenterTicketSummary:[],

            loading: true,
            statusText: "",
            errors: {},
            AllRoleData: [],
            AuthRole: [],
            InvoiceData: [],
            columns: columns,
            sortKey: "name",
            sortOrders: sortOrders,
            perPage: ["10", "20", "30"],
        };
    },

    validations: {
        fields: {
            name: {
                required,
                minLength: minLength(3),
                alphawithspa,
                maxLength: maxLength(50),
            },
            email: { required, email, maxLength: maxLength(25) },
            centercode: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(25),
            },
            contactno: {
                required,
                numeric,
                maxLength: maxLength(10),
                minLength: minLength(10),
            },
            location: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(50),
            },
            address: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(200),
            },
        },

        center: { required },
        DateFrom: { required },
        DateTo: { required },

        tableData: {
            search: { required },
        },
    },

    methods: {
        //Center Section

        GetAllCenters() {
            axios
                .get("/center-setting/get-centers")
                .then((response) => {
                    this.AllCenterData = response.data.data;
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong! ",
                    });
                });
        },

        GetData(url = "/reports/get-center-summary") {
            this.para.center = this.center;
            this.para.from = this.DateFrom;
            this.para.to = this.DateTo;

            axios
                .get(url, { params: this.para })
                .then((response) => {                    
                    this.CenterCash= response.data.CenterCash;
                    this.CenterCard= response.data.CenterCard;
                    this.CenterCashRefund= response.data.CenterCashRefund;
                    this.CenterCardRefund= response.data.CenterCardRefund;
                    this.CashInHand= response.data.CashInHand;
                    this.CardBalance= response.data.CardBalance;
                    this.Balance = response.data.balance;
                    this.TotalInvoices = response.data.totalInvoices;
                    this.TotalIssueTickets = response.data.totalIssueTickets;
                    this.TotalRefundInvoice = response.data.totalRefundInvoice;
                    this.TotalrefundAmount = response.data.totalRefundAmount;
                    this.TotalRefundIssueTickets =
                        response.data.totalRefundIssueTickets;
                    this.CenterTicketSummary=response.data.ticket_summary;
                })
                .catch((errors) => {
                    if (errors.response.status == 422) {
                        this.errors = errors.response.data.errors;
                        Toast.fire({ icon: "error", title: "required field" });
                    } else if (errors.response.status == 403) {
                        Toast.fire({
                            icon: "error",
                            title: "Forbidden , User does not have the right roles ",
                        });
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: "Somthing Went Wrong ",
                        });
                    }
                });
        },

        GetList(url = "/reports/get-center-list-summary") {
            this.tableData.draw++;
            this.tableData.CenterName = this.center;
            this.tableData.From = this.DateFrom;
            this.tableData.To = this.DateTo;

            axios
                .get(url, { params: this.tableData })
                .then((response) => {
                    let data = response.data;

                    if (this.tableData.draw == data.draw) {
                        this.InvoiceData = data.data.data;
                        this.configPagination(data.data);
                    }
                    this.loading = false;
                })
                .catch((errors) => {
                    this.loading = false;
                    if (errors.response.status == 403) {
                        Toast.fire({
                            icon: "error",
                            title: "Forbidden , User does not have the right roles ",
                        });
                    } else if (errors.response.status == 422) {
                        Toast.fire({ icon: "error", title: "required field" });
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: "Somthing Went Wrong ",
                        });
                    }
                });
        },

        PrintOnlyData() {
            this.tableData.CenterName = this.center;
            this.tableData.From = this.DateFrom;
            this.tableData.To = this.DateTo;
            window.open(
                "/reports/center-data/" +
                    "?center=" +
                    this.tableData.CenterName +
                    "&from=" +
                    this.tableData.From +
                    "&to=" +
                    this.tableData.To,
                "_blank"
            );
        },

        PrintOnlyList() {
            this.tableData.CenterName = this.center;
            this.tableData.From = this.DateFrom;
            this.tableData.To = this.DateTo;
            window.open(
                "/reports/center-data-list/" +
                    "?center=" +
                    this.tableData.CenterName +
                    "&from=" +
                    this.tableData.From +
                    "&to=" +
                    this.tableData.To,
                "_blank"
            );
        },

        PrintDataWithList() {
            this.tableData.CenterName = this.center;
            this.tableData.From = this.DateFrom;
            this.tableData.To = this.DateTo;
            window.open(
                "/reports/center-data-with-list/" +
                    "?center=" +
                    this.tableData.CenterName +
                    "&from=" +
                    this.tableData.From +
                    "&to=" +
                    this.tableData.To,
                "_blank"
            );
        },

        Clear() {
            this.center = "";
            this.DateFrom = "";
            this.DateTo = "";
            this.InvoiceData = [];
            this.Balance = "";
            this.TotalInvoices = "";
            this.TotalIssueTickets = "";
            this.TotalRefundInvoice = "";
            this.TotalrefundAmount = "";
            this.TotalRefundIssueTickets = "";
            this.CenterTicketSummary=[];
        },

        //Data tabale Section
        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

        sortBy(key) {
            this.sortKey = key;
            this.sortOrders[key] = this.sortOrders[key] * -1;
            this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
            this.getCenters();
        },

        getIndex(array, key, value) {
            return array.findIndex((i) => i[key] == value);
        },
        //End Data Tabale section
    },
};
</script>
