<template>
    <div>
       {{ handleData }}
    </div>
</template>

<script>
export default {
    created() {       
        this.$eventBus.$on('data-sent', (data)=>{
            this.handleData=data
        });     
    },
    data() {
        return {
            handleData:"",
        };
    }, 
    methods: {
    },
};
</script>
