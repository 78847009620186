<template>
    <table class="table is-bordered data-table">
        <thead>
            <tr>
                <th
                    v-for="column in columns"
                    :class="
                        sortKey === column.name
                            ? sortOrders[column.name] > 0
                                ? 'sorting_asc'
                                : 'sorting_desc'
                            : 'sorting'
                    "
                    :style="'width:' + column.width + ';' + 'cursor:pointer;'"
                >
                    {{ column.label }}
                    <i class="far fa-check-circle"></i>
                </th>
            </tr>
        </thead>
        <slot></slot>
    </table>
</template>

<style scoped>
.red {
    content: attr(data-icon);
    font-family: "FontAwesome";
    font-style: normal;
}
</style>

<script>
export default {
    props: ["columns", "sortKey", "sortOrders"],
};
</script>
