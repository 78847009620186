/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

window.Vue = require("vue").default;

import Vue from "vue";
const eventBus = new Vue();
Vue.prototype.$eventBus = eventBus;
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

Vue.directive("can", function (el, binding, vnode) {
    if (Permissions.indexOf(binding.value) !== -1) {
        //0 true item found
        return (vnode.elm.hidden = false);
    } else {
        //-1 false item not found
        return (vnode.elm.hidden = true);
    }
});

Vue.directive("canelase", function (el, binding, vnode) {
    if (Permissions.indexOf(binding.value) !== -1) {
        //0 true item found
        return (vnode.elm.hidden = true);
    } else {
        //-1 false item not found
        return (vnode.elm.hidden = false);
    }
});

Vue.directive("is", function (el, binding, vnode) {
    if (binding.value.includes("|")) {
        binding.value.split("|").every(function (item) {
            if (Role.includes(item.trim())) {
                return (vnode.elm.hidden = false);
            } else {
                return (vnode.elm.hidden = true);
            }
        });
    } else {
        if (Role.includes(binding.value)) {
            return (vnode.elm.hidden = false);
        } else {
            return (vnode.elm.hidden = true);
        }
    }
});

// ES6 Modules or TypeScript
import Swal from "sweetalert2";
window.Swal = Swal;

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

window.Toast = Toast;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component(
    "user-management",
    require("./components/usersetting/UserManagement.vue").default
);
Vue.component(
    "center-management",
    require("./components/centersetting/CenterManagement.vue").default
);
Vue.component(
    "ticket-setting",
    require("./components/ticketsetting/ticketsetting.vue").default
);
Vue.component(
    "cashier-invoice",
    require("./components/cashier/invoice.vue").default
);
Vue.component(
    "cashier-refund",
    require("./components/cashier/refund.vue").default
);
Vue.component(
    "cashier-online-refund",
    require("./components/cashier/onlineRefund.vue").default
);

Vue.component(
    "cashier-online-ticket",
    require("./components/cashier/onlineticket.vue").default
);
Vue.component(
    "cashier-action",
    require("./components/cashier/action.vue").default
);
Vue.component(
    "cashier-online-action",
    require("./components/cashier/onlineticketissue.vue").default
);
Vue.component(
    "audit-summary",
    require("./components/report/AuditSummary.vue").default
);
Vue.component(
    "cashier-summary",
    require("./components/report/CashierSummary.vue").default
);
Vue.component(
    "one-cashier-summary",
    require("./components/report/OneCashierSummary.vue").default
);
Vue.component(
    "cash-drawer",
    require("./components/cashdrawer/cashdrawer.vue").default
);

Vue.component(
    "datatable",
    require("./components/datatabale/Datatable.vue").default
);
Vue.component(
    "pagination",
    require("./components/datatabale/Pagination.vue").default
);


Vue.component(
    "security-setting",
    require("./components/security/security.vue").default
);

Vue.component(
    "cashier-hit",
    require("./components/cashier/cashierHits.vue").default
);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: "#app",
});
