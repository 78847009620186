<template>
    <div>
        <div class="modal-body">
            <div class="row onlineticket">
                <div class="col-xs-4 col-sm-4 col-md-4">
                    <div>
                        <h6>Customer Name</h6>
                        <p>
                            {{ invoiceonlineticket.billing.first_name }}
                            {{ invoiceonlineticket.billing.last_name }}
                        </p>
                    </div>

                    <div>
                        <h6>Customer Email</h6>
                        <p>{{ invoiceonlineticket.billing.email }}</p>
                    </div>

                    <div>
                        <h6>Customer Phone</h6>
                        <p>{{ invoiceonlineticket.billing.phone }}</p>
                    </div>
                </div>

                <div class="col-xs-4 col-sm-4 col-md-4">
                    <div>
                        <h6>Payment</h6>
                        <p>{{ ticketPayment }}</p>
                    </div>

                    <div>
                        <h6>Tickets</h6>
                        <p>{{ tickets }}</p>
                    </div>

                    <div>
                        <h6>Payment Type</h6>
                        <p>{{ paymentType }}</p>
                    </div>
                </div>

                <div class="col-xs-4 col-sm-4 col-md-4 text-right">
                    <div>
                        <h6 class="text-danger">Refund Quantity</h6>
                        <p class="text-danger">{{ Refundalance }}</p>
                    </div>

                    <div>
                        <h6 class="text-danger">Refund Amount</h6>
                        <p class="text-danger">{{ RefundalanceAmount }}</p>
                    </div>

                    <div>
                        <h6 class="text-danger">Status</h6>
                        <p class="text-danger" v-if="!checExpireDate">
                            {{ invoiceonlineticket.status }}
                        </p>
                        <p class="text-danger" v-else>
                            {{ invoiceonlineticket.date_created }}
                        </p>
                    </div>

                    <div
                        class="login-content"
                        v-show="isActiveQty && Isthiscenter"
                        style="display: block"
                    >
                        <div class="form-group row" style="margin-bottom: 0px">
                            <!-- Ticket quntyty -->
                            <div
                                class="input-div"
                                :class="{
                                    'is-invalid input-div-err focus':
                                        $v.Ticketfields.quty.$error ||
                                        errors.quty,
                                    ' is-valid focus':
                                        !$v.Ticketfields.quty.$invalid,
                                }"
                            >
                                <div class="i">
                                    <i class="fas fa-ticket-alt"></i>
                                </div>
                                <div class="div">
                                    <label
                                        for="quty"
                                        class="col-md-12 col-form-label text-md-left"
                                        >Ticket Quantity</label
                                    >
                                    <input
                                        type="number"
                                        min="0"
                                        pattern="[0-9]*"
                                        class="input form-control"
                                        :class="{
                                            'is-invalid focus':
                                                $v.Ticketfields.quty.$error ||
                                                errors.quty,
                                            ' is-valid focus':
                                                !$v.Ticketfields.quty.$invalid,
                                        }"
                                        v-model="Ticketfields.quty"
                                        name="quty"
                                        id="quty"
                                        v-on:keyup.enter="addTicket"
                                        @input="$v.Ticketfields.quty.$touch()"
                                        @blur="$v.Ticketfields.quty.$touch()"
                                        ref="quty"
                                    />
                                </div>
                            </div>

                            <span
                                class="invalid-feedback"
                                v-if="$v.Ticketfields.quty.$error"
                            >
                                <p v-if="!$v.Ticketfields.quty.required">
                                    The Ticket Quantity field is required.
                                </p>
                                <p v-if="!$v.Ticketfields.quty.numeric">
                                    The Ticket Quantity format is only numbers.
                                </p>
                                <p v-if="!$v.Ticketfields.quty.quty">
                                    The Ticket Quantity not be negative and
                                    grather than 0
                                </p>
                            </span>

                            <span
                                v-if="errors && errors.quty"
                                class="invalid-feedback"
                            >
                                {{ errors.quty[0] }}
                            </span>
                            <!-- End Ticket quntyty -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- Ticket Item Table -->
            <table class="table table-hover" id="ticket-list">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Type</th>
                        <th scope="col">SKU</th>
                        <th scope="col">Ticket price</th>
                        <th scope="col">Ticket quantity</th>
                        <th scope="col">Price</th>
                        <th scope="col">R_quantity</th>
                        <th scope="col">R_price</th>
                        <th scope="col">Remove</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in ticketItemList" ref="tabale">
                        <th scope="row">{{ index }}</th>
                        <td>{{ item.type }}</td>
                        <td class="font-weight-bold">{{ item.sku }}</td>
                        <td>{{ item.ticketprice }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.price }}</td>
                        <td
                            class="font-weight-bold"
                            v-show="
                                Isthiscenter && invoiceStatus && !checExpireDate
                            "
                        >
                            {{ item.rquantity }}
                        </td>
                        <td
                            class="font-weight-bold"
                            v-show="
                                Isthiscenter && invoiceStatus && !checExpireDate
                            "
                        >
                            {{ item.rprice }}
                        </td>
                        <td
                            v-show="
                                Isthiscenter && invoiceStatus && !checExpireDate
                            "
                        >
                            <a @click="RemoveTicekt(index)">
                                <i class="fas fa-minus-circle text-danger"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Ticket Item Table -->
        </div>

        <!-- issue /refund Section -->
        <div
            class="container"
            v-show="Isthiscenter && invoiceStatus && !checExpireDate"
        >
            <div class="row" v-if="!isCompleted && isuuebtnShow">
                <button
                    type="btn"
                    :class="btnClass"
                    id="issueBtn"
                    :disabled="disabledIsuueStatus"
                    @click="Issue()"
                >
                    {{ issueStatus }}
                </button>
            </div>

            <div class="row" v-if="isCompleted && enabaleRefund">
                <button
                    type="btn"
                    :class="btnClass"
                    id="issueBtn"
                    :disabled="disabledIsuueStatus"
                    @click="Refund()"
                >
                    {{ issueStatus }}
                </button>
            </div>

            <div v-if="Refundalance > 0">
                <div class="row cards" v-if="!enabaleRefund">
                    <div class="col-md-12">
                        <form @submit.prevent="getPermission()">
                            <!-- Authorization -->
                            <div class="row login-content">
                                <div class="col-md-6">
                                    <!-- Emp ID -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.Authorization.empID.$error ||
                                                errors.empID,
                                            ' is-valid focus':
                                                !$v.Authorization.empID
                                                    .$invalid,
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-ticket-alt"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="empID"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Officer Employee ID</label
                                            >
                                            <input
                                                type="text"
                                                class="input form-control"
                                                :class="{
                                                    'is-invalid focus':
                                                        $v.Authorization.empID
                                                            .$error ||
                                                        errors.empID,
                                                    ' is-valid focus':
                                                        !$v.Authorization.empID
                                                            .$invalid,
                                                }"
                                                v-model="Authorization.empID"
                                                name="empID"
                                                id="empID"
                                                @input="
                                                    $v.Authorization.empID.$touch()
                                                "
                                                @blur="
                                                    $v.Authorization.empID.$touch()
                                                "
                                                ref="empID"
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.Authorization.empID.$error"
                                    >
                                        <p
                                            v-if="
                                                !$v.Authorization.empID.required
                                            "
                                        >
                                            The Officer Employee ID field is
                                            required.
                                        </p>
                                    </span>
                                    <!-- End Emp ID -->
                                </div>

                                <div class="col-md-6">
                                    <!-- eMP password -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.Authorization.empPassword
                                                    .$error ||
                                                errors.empPassword,
                                            ' is-valid focus':
                                                !$v.Authorization.empPassword
                                                    .$invalid,
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-lock"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="empPassword"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Officer Employee
                                                Password</label
                                            >
                                            <input
                                                type="password"
                                                class="input form-control"
                                                :class="{
                                                    'is-invalid focus':
                                                        $v.Authorization
                                                            .empPassword
                                                            .$error ||
                                                        errors.empPassword,
                                                    ' is-valid focus':
                                                        !$v.Authorization
                                                            .empPassword
                                                            .$invalid,
                                                }"
                                                v-model="
                                                    Authorization.empPassword
                                                "
                                                name="empPassword"
                                                id="empPassword"
                                                @input="
                                                    $v.Authorization.empPassword.$touch()
                                                "
                                                @blur="
                                                    $v.Authorization.empPassword.$touch()
                                                "
                                                ref="empPassword"
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="
                                            $v.Authorization.empPassword.$error
                                        "
                                    >
                                        <p
                                            v-if="
                                                !$v.Authorization.empPassword
                                                    .required
                                            "
                                        >
                                            The Officer Employee Password field
                                            is required.
                                        </p>
                                    </span>
                                    <!-- eMP password -->
                                </div>
                            </div>
                            <!-- Authorization -->

                            <div class="row text-center">
                                <span v-for="(error, sec) in errors">
                                    <p class="text-danger">{{ error }}</p>
                                </span>
                            </div>
                            <button
                                type="submit"
                                class="btn-small-width btn-invoice btn-danger"
                                id="permissionBtn"
                                :disabled="disabledStatus"
                                v-on:keyup.enter="getPermission()"
                            >
                                Get Permission
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-md-8 text-center">
                <h2 v-if="checExpireDate">Oops! Invoice has expired.</h2>
                <h2 v-show="!Isthiscenter">
                    Cannot process this invoice,center does not match.
                </h2>
            </div>
            <div class="col-md-4">
                <button
                    type="submit"
                    class="btn-small-width btn-invoice btn-danger"
                    id="permissionBtn"
                    @click="BackToList()"
                >
                    Back To Online List
                </button>
            </div>
        </div>

        <!-- End issue /refund Section -->

        <!-- Refund info -->
        <div v-show="refundata">
            <h5 class="font-weight-bold">Refund History</h5>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Ticket Type</th>
                        <th scope="col">Refund Ticket Quantity</th>
                        <th scope="col">Refund Ticket Price</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in refundata">
                        <th scope="row">{{ index }}</th>
                        <td>{{ item.type }}</td>
                        <td>{{ item.rquantity }}</td>
                        <td>{{ item.rprice }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- End Refund info -->
    </div>
</template>

<style scoped>
form {
    width: 100%;
}

p {
    margin: 0px;
}

a:hover {
    cursor: pointer;
}

.btn-invoice-background-err {
    background-color: #cb4335;
}

.btn-invoice-background-refund-start {
    background-color: #239b56;
}
</style>

<script>
import { required, numeric, helpers } from "vuelidate/lib/validators";
const quty = helpers.regex("nameVal", /^[1-9]\d*$/);
import moment from "moment";
const config = {
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
    },
};
let formData = new FormData();

export default {
    mounted() {
        window.app = this;
    },

    created() {
        this.AuthCenter = AuthWorkingCenter;
        this.AuthID = Auth;
        this.OnlineTicketInvocieNumber = this.invoiceonlineticket.number;
        this.tickets = this.list.reduce((acc, item) => acc + item.quantity, 0); //ticket quauntity
        this.ticketPayment = this.invoiceonlineticket.total;
        this.paymentType = this.invoiceonlineticket.payment_method;
        this.ticketItem(this.invoiceonlineticket.line_items);

        window.addEventListener("keydown", (e) => {
            if (e.key == "w" || e.key == "W") {
                this.$refs.quty.focus();
            } else if (e.key == "v") {
                this.$refs.quty.blur();
                this.TabaleRowEdit();
            } else if (e.key == "Escape") {
                this.ticketItemList = [];
                this.InvoiceAmount = "";
                this.AllTicket = "";
            } else {
            }
        });
    },

    props: ["invoiceonlineticket", "list", "refundata"],

    data() {
        return {
            AuthCenter: "",
            AuthID: "",
            btnClass: "btn-small-width btn-invoice",
            ticketItemList: [],
            refundItemList: [],
            tickets: "",
            ticketPayment: "",
            paymentType: "",
            OnlineTicketInvocieNumber: "",
            errors: {},
            EditQytIndex: "",
            issueStatus: "Issue Ticket",
            enabaleRefund: false,
            disabledStatus: false,
            disabledIsuueStatus: false,
            locktabale: false,
            isuuebtnShow: true,
            isActiveTabale: false,
            isActiveQty: false,
            rticketItemList: [],
            Ticketfields: { quty: "" },
            Authorization: {
                empID: "",
                empPassword: "",
            },
            RefundalanceAmount: "",
            Refundalance: "",
        };
    },

    validations: {
        Ticketfields: {
            quty: { required, numeric, quty },
        },

        Authorization: {
            empID: { required },
            empPassword: { required },
        },
    },
    methods: {
        ticketItem(data) {
            data.forEach((element) => {
                this.ticketItemList.push({
                    quantity: element.quantity,
                    price: element.total,
                    ticketprice: element.price,
                    sn: this.ticketST(element.meta_data, "sin"),
                    tm: this.ticketST(element.meta_data, "tam"),
                    type: this.ticketName(element.meta_data),
                    rquantity: 0,
                    rprice: 0,
                    sku: element.sku,
                });
            });
        },

        ticketName(data) {
            return data[1].value + " " + data[0].value + " " + data[2].value;
        },

        ticketST(data, lan) {
            const ticketName =
                data[1].value + " " + data[0].value + " " + data[2].value;
            if (lan == "sin") {
                if (ticketName === "Citizen of Sri Lanka Gate Tickets Adult") {
                    return "දේශීය වැඩිහිටි";
                } else if (
                    ticketName == "Citizen of Sri Lanka Gate Tickets Child"
                ) {
                    return "ශ්‍රී ලංකාවේ පුරවැසි ගේට් ටිකට් වැඩිහිටි";
                }
            } else {
                if (ticketName === "Citizen of Sri Lanka Gate Tickets Adult") {
                    return "உள்ளூர் வயது வந்தோர்";
                } else if (
                    ticketName == "Citizen of Sri Lanka Gate Tickets Child"
                ) {
                    return "உள்ளூர் வயது வந்தோர்";
                }
            }
        },

        getPermission() {
            this.disabledStatus = true;
            var spinner =
                '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div> Getting Permission...';
            $("#permissionBtn").html(spinner);

            this.errors = [];
            formData.append("empID", this.Authorization.empID);
            formData.append("empPassword", this.Authorization.empPassword);
            formData.append("center", this.AuthCenter);

            axios
                .post("/cashier/request-refund", formData, config)
                .then((response) => {
                    if (response.data.data == true) {
                        this.enabaleRefund = response.data.data;
                        this.isuuebtnShow = true;
                        this.issueStatus = this.isCompleted
                            ? "Refund Ticket"
                            : "Refund And Issue ticket";
                        Toast.fire({
                            icon: "success",
                            title: "Enable To refund",
                        });
                        this.locktabale = true;
                    } else if (response.data.data == false) {
                        this.disabledStatus = false;
                        var spinner =
                            '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div> Getting Permission Faild Try Again ...';
                        $("#permissionBtn").html(spinner);
                        Toast.fire({
                            icon: "error",
                            title: "Password Not Match",
                        });
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: "This Officer cannot process this Try Again",
                        });
                    }
                })
                .catch((error) => {
                    this.disabledStatus = false;
                    var spinner =
                        '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div> Getting Permission Faild Try Again ...';
                    $("#permissionBtn").html(spinner);
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors;
                        Toast.fire({
                            icon: "error",
                            title: error.response.data.errors.message,
                        });
                    } else if (error.response.status == 500) {
                        Toast.fire({
                            icon: "error",
                            title: error.response.data.errors.message,
                        });
                    } else {
                    }
                });
        },

        Issue() {
            var spinner =
                '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div>' +
                " " +
                this.issueStatus +
                "";
            $("#issueBtn").html(spinner);
            this.disabledIsuueStatus = true;

            formData.append("invoiceID", this.OnlineTicketInvocieNumber);
            formData.append("amount", this.ticketPayment);
            formData.append("ramount", this.RefundalanceAmount);
            formData.append("ticket", JSON.stringify(this.ticketItemList));
            formData.append("tickets", this.tickets);
            formData.append("paymenttype", this.paymentType);
            formData.append("cratedby", this.AuthID);
            formData.append("center", this.AuthCenter);

            axios
                .post("/cashier/process-online-ticket", formData, config)
                .then((response) => {
                    if (response.data == "stored") {
                        Toast.fire({
                            icon: "error",
                            title: "This Booking Invoice Already Added",
                        });
                        this.btnClass =
                            " btn-small-width btn-invoice btn-invoice-background-err";
                        this.issueStatus =
                            "This Invoice Already Added , Process Destroyed";
                        var spinner =
                            '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div>' +
                            " " +
                            this.issueStatus +
                            "";
                        $("#issueBtn").html(spinner);
                    } else {
                        if (this.Refundalance > 0) {
                            Toast.fire({
                                icon: "success",
                                title: "Invoice Added And Refund Process Started",
                            });
                            this.btnClass =
                                " btn-small-width btn-invoice btn-invoice-background-refund-start";
                            this.issueStatus = "Refund Process Started";
                            var spinner =
                                '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div>' +
                                " " +
                                this.issueStatus +
                                "";
                            $("#issueBtn").html(spinner);
                            this.Refund();
                        } else {
                            Toast.fire({
                                icon: "success",
                                title: "Invoice Added and Printing tickets",
                            });

                            this.PrintTicket(response.data);
                        }
                    }
                })
                .catch((error) => {
                    this.disabledIsuueStatus = false;

                    if (error.response.data.errors.message == "drawer error") {
                        Toast.fire({
                            icon: "error",
                            title: "Cash Drawer Error",
                            text: "your cash drawer balance is not sufficient for process this refund",
                        });
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: error.response.status,
                            text: error.response.statusText,
                        });
                    }
                });
        },

        Refund() {
            this.disabledIsuueStatus = true;
            formData.append("invoiceID", this.OnlineTicketInvocieNumber);
            formData.append("ticket", JSON.stringify(this.refundItemList));
            formData.append("ramount", this.RefundalanceAmount);
            formData.append("rtickets", this.Refundalance);
            formData.append("refunddby", this.AuthID);
            formData.append("center", this.AuthCenter);

            axios
                .post("/cashier/process-refund-online-ticket", formData, config)
                .then((response) => {
                    Toast.fire({
                        icon: "success",
                        title: "Invoice Tickets Refunded",
                    });

                    this.PrintTicket(response.data);
                })
                .catch((error) => {
                    this.disabledIsuueStatus = false;

                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors;
                    } else if (
                        error.response.data.errors.message == "drawer error"
                    ) {
                        Toast.fire({
                            icon: "error",
                            title: "Cash Drawer Error",
                            text: "your cash drawer balance is not sufficient for process this refund",
                        });
                    } else if (error.response.status == 500) {
                        Toast.fire({
                            icon: "error",
                            title: error.response.data.errors.message,
                        });
                    } else if (error.response.status == 403) {
                        Toast.fire({
                            icon: "error",
                            title: error.response.data.errors.message,
                        });
                    }
                });
        },

        TabaleRowEdit() {
            if (this.isActiveTabale == false) {
                this.isActiveTabale = true;
                var rows =
                    document.getElementById("ticket-list").children[1].children;
                var selectedRow = 0;

                document.onkeydown = function (e) {
                    //Clear out old row's color
                    rows[selectedRow].style.backgroundColor = "#FFFFFF";
                    //Calculate new row
                    if (e.key == "ArrowUp") {
                        window.app.isActiveQty = false;
                        selectedRow--;
                    } else if (e.key == "ArrowDown") {
                        window.app.isActiveQty = false;
                        selectedRow++;
                    } else if (e.key == "k" && !window.app.locktabale) {
                        window.app.RemoveTicekt(selectedRow);
                    } else if (e.key == "l" && !window.app.locktabale) {
                        window.app.EditTicketQuty(selectedRow);
                    }

                    if (selectedRow >= rows.length) {
                        selectedRow = 0;
                    } else if (selectedRow < 0) {
                        selectedRow = rows.length - 1;
                    }
                    //Set new row's color
                    rows[selectedRow].style.backgroundColor = "#8888FF";
                };

                rows[0].style.backgroundColor = "#81C0F7";
            } else {
                this.isActiveTabale = false;
                this.isActiveQty = true;
            }
        },

        EditTicketQuty(index) {
            if (this.invoiceStatus == true && this.Isthiscenter == true) {
                this.EditQytIndex = index;
                this.isActiveQty = true;
                this.$refs.quty.focus();
            } else {
                Toast.fire({
                    icon: "error",
                    title: "cannot process this ticket check ticket status and related center you logged  ",
                });
            }
        },

        addTicket() {
            if (Number(this.Ticketfields.quty) > 0) {
                if (this.Ticketfields.quty.match(/^(0|[1-9]\d*)$/) == null) {
                    Toast.fire({
                        icon: "error",
                        title: "Ticket Quantity is only numbers.",
                    });
                } else {
                    if (
                        this.ticketItemList[this.EditQytIndex].quantity >=
                        Number(this.Ticketfields.quty)
                    ) {
                        //original amout exceeded
                        const data =
                            Number(
                                this.ticketItemList[this.EditQytIndex].rquantity
                            ) + Number(this.Ticketfields.quty);
                        if (
                            this.ticketItemList[this.EditQytIndex].quantity -
                                this.ticketItemList[this.EditQytIndex]
                                    .rquantity ==
                            0
                        ) {
                            Toast.fire({
                                icon: "error",
                                title: "Cannot refund",
                            });
                        } else if (
                            data >
                            Number(
                                this.ticketItemList[this.EditQytIndex].quantity
                            )
                        ) {
                            Toast.fire({
                                icon: "error",
                                title: "Refund ticket quantity is higher than original quantity (r+q)",
                            });
                        } else if (
                            this.ticketItemList[this.EditQytIndex].quantity ==
                            data
                        ) {
                            this.refundItemList.push(
                                this.ticketItemList[this.EditQytIndex]
                            );

                            this.rticketItemList.push({
                                rquntity: this.Ticketfields.quty,
                                rprice: this.ticketItemList[this.EditQytIndex]
                                    .ticketprice,
                            });

                            this.ticketItemList[this.EditQytIndex].rquantity =
                                this.Ticketfields.quty;
                            this.ticketItemList[this.EditQytIndex].rprice =
                                this.Ticketfields.quty *
                                this.ticketItemList[this.EditQytIndex].price;

                            this.ticketItemList.splice(this.EditQytIndex, 1);
                            this.TicketData();
                            this.$refs.quty.blur();
                            this.clearfeild();
                        } else {
                            this.refundItemList.push(
                                this.ticketItemList[this.EditQytIndex]
                            );

                            this.rticketItemList.push({
                                rquntity: this.Ticketfields.quty,
                                rprice:
                                    this.Ticketfields.quty *
                                    this.ticketItemList[this.EditQytIndex]
                                        .ticketprice,
                            });

                            this.ticketItemList[this.EditQytIndex].rquantity =
                                Number(this.Ticketfields.quty) +
                                Number(
                                    this.ticketItemList[this.EditQytIndex]
                                        .rquantity
                                );
                            this.ticketItemList[this.EditQytIndex].rprice =
                                Number(
                                    this.ticketItemList[this.EditQytIndex]
                                        .rquantity
                                ) *
                                this.ticketItemList[this.EditQytIndex]
                                    .ticketprice;

                            this.TicketData();
                            this.$refs.quty.blur();
                            this.clearfeild();
                        }
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: "Refund Amount less original amaout to  ",
                        });
                    }
                }
            } else {
                Toast.fire({ icon: "error", title: "Refund Amount not 0   " });
            }
        },

        clearfeild() {
            this.Ticketfields.quty = "";
            this.$v.$reset();
        },

        TicketData() {
            this.isuuebtnShow = false;
            this.RefundalanceAmount = this.rticketItemList
                .reduce((acc, item) => {
                    return acc + Number(item.rprice);
                }, 0)
                .toFixed(2);

            this.Refundalance = this.rticketItemList.reduce((add, itemm) => {
                return add + Number(itemm.rquntity);
            }, 0);
        },

        RemoveTicekt(index) {
            if (this.invoiceStatus == true && this.Isthiscenter == true) {
                if (this.ticketItemList[index].rquantity > 0) {
                    this.rticketItemList.push({
                        rquntity:
                            this.ticketItemList[index].quantity -
                            this.ticketItemList[index].rquantity,
                        rprice:
                            (this.ticketItemList[index].quantity -
                                this.ticketItemList[index].rquantity) *
                            Number(this.ticketItemList[index].ticketprice),
                    });

                    this.ticketItemList[index].rquantity =
                        this.ticketItemList[index].quantity;
                    this.ticketItemList[index].rprice =
                        this.ticketItemList[index].quantity *
                        Number(this.ticketItemList[index].price);

                    this.refundItemList.push(this.ticketItemList[index]);
                } else {
                    this.refundItemList.push(this.ticketItemList[index]);
                    this.rticketItemList.push({
                        rquntity: this.ticketItemList[index].quantity,
                        rprice: Number(this.ticketItemList[index].price),
                    });
                    this.ticketItemList[index].rquantity =
                        this.ticketItemList[index].quantity;
                    this.ticketItemList[index].rprice = Number(
                        this.ticketItemList[index].price
                    );
                }

                this.ticketItemList.splice(index, 1);
                this.TicketData();
            } else {
                Toast.fire({
                    icon: "error",
                    title: "cannot process this ticket check ticket status and related center you logged  ",
                });
            }
        },

        BackToList() {
            window.location.href = "/cashier/online-ticket";
        },

        PrintTicket(data) {
            this.btnClass =
                " btn-small-width btn-invoice btn-invoice-background-refund-start";
            this.issueStatus = this.isCompleted
                ? "Process completed"
                : "Process completed after print ticket refresh page";
            var spinner =
                '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div>' +
                " " +
                this.issueStatus +
                "";
            $("#issueBtn").html(spinner);

            if (this.invoiceonlineticket.status == "processing") {
                window.location.href = "pos:" + JSON.stringify(data);
            }
        },
    },

    computed: {
        Isthiscenter() {
            var centerrdata = this.list
                .map((x) => x.sku)
                .filter((v, i, s) => s.indexOf(v) === i);

            if (centerrdata.length == 1 && centerrdata == this.AuthCenter) {
                return true;
            } else {
                return false;
            }
        },

        invoiceStatus() {
            if (
                this.invoiceonlineticket.status == "processing" ||
                this.invoiceonlineticket.status == "completed"
            ) {
                return true;
            } else {
                return false;
            }
        },

        checExpireDate() {
            const currentDate = moment();
            const expirationDate = moment(
                this.invoiceonlineticket.date_created
            ).add(6, "months");

            return currentDate.isAfter(expirationDate);
        },

        isCompleted() {
            if (this.invoiceonlineticket.status == "completed") {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>
