<template>
    <div>
        <div class="login-content" style="display:block">
            <div class="form-group row  align-items-center">
                <div class=" col-md-9">
                    <div class="input-div">
                        <div class="i">
                            <i class="fas fa-search"></i>
                        </div>
                        <div class="div">
                            <label
                                for="search"
                                class="col-md-12 col-form-label text-md-left"
                                >Search</label
                            >
                            <input
                                type="text"
                                class=" input form-control"
                                v-model="tableData.search"
                                name="search"
                                id="search"
                            />
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="control text-left">
                        <button
                            type="button"
                            class="btn-small-width btn-purple"
                            @click="getProjects()"
                        >
                            <i class="far fa-file-alt"></i> Get Ticket
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="loading" class="text-center">
            <div
                class="spinner-border spinner-border-lg text-success"
                role="status"
            >
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div v-show="!loading">
            <table class="table" v-if="issearch == 'false'">
                <thead>
                    <tr>
                        <th scope="col">Invoice ID</th>
                        <th scope="col">Status</th>
                        <th scope="col">Process</th>
                    </tr>
                </thead>
                <tbody v-for="oticket in OnlineTicket" :key="oticket.id">
                    <tr
                        :key="oticket.id"
                        v-if="oticket.status == 'refunded'"
                        style="background-color: #ec7063 "
                    >
                        <td>{{ oticket.id }}</td>
                        <td>{{ oticket.status }}</td>
                        <td>
                            <a @click="ProcessOnlineTicket(oticket.id)"
                                ><!-- 0 block  -->
                                <i class="fas fa-hand-pointer"></i
                            ></a>
                        </td>
                    </tr>

                    <tr
                        v-else-if="oticket.status == 'processing'"
                        style="background-color: #58d68d  "
                    >
                        <td>{{ oticket.id }}</td>
                        <td>{{ oticket.status }}</td>
                        <td>
                            <a @click="ProcessOnlineTicket(oticket.id)"
                                ><!-- 0 block  -->
                                <i class="fas fa-hand-pointer"></i
                            ></a>
                        </td>
                    </tr>

                    <tr
                        v-else-if="oticket.status == 'cancelled'"
                        style="background-color: #bb8fce   "
                    >
                        <td>{{ oticket.id }}</td>
                        <td>{{ oticket.status }}</td>
                        <td>
                            <a @click="ProcessOnlineTicket(oticket.id)"
                                ><!-- 0 block  -->
                                <i class="fas fa-hand-pointer"></i
                            ></a>
                        </td>
                    </tr>

                    <tr
                        v-else-if="oticket.status == 'failed'"
                        style="background-color:   #edbb99   "
                    >
                        <td>{{ oticket.id }}</td>
                        <td>{{ oticket.status }}</td>
                        <td>
                            <a @click="ProcessOnlineTicket(oticket.id)"
                                ><!-- 0 block  -->
                                <i class="fas fa-hand-pointer"></i
                            ></a>
                        </td>
                    </tr>

                    <tr
                        v-else-if="oticket.status == 'pending'"
                        style="background-color:   #edbb99   "
                    >
                        <td>{{ oticket.id }}</td>
                        <td>{{ oticket.status }}</td>
                        <td>-</td>
                    </tr>

                    <tr v-else>
                        <!-- completed  -->
                        <td>{{ oticket.id }}</td>
                        <td>{{ oticket.status }}</td>
                        <td>
                            <a @click="ProcessOnlineTicket(oticket.id)"
                                ><!-- 0 block  -->
                                <i class="fas fa-hand-pointer"></i
                            ></a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table class="table" v-else>
                <thead>
                    <tr>
                        <th scope="col">Invoice ID</th>
                        <th scope="col">Status</th>
                        <th scope="col">Process</th>
                    </tr>
                </thead>
                <!-- search data tabale -->
                <tbody>
                    <tr v-if="OnlineTicket.status == 'pending'">
                        <td>{{ OnlineTicket.id }}</td>
                        <td>{{ OnlineTicket.status }}</td>
                        <td>-</td>
                    </tr>
                    <tr v-else>
                        <td>{{ OnlineTicket.id }}</td>
                        <td>{{ OnlineTicket.status }}</td>
                        <td>
                            <a @click="ProcessOnlineTicket(OnlineTicket.id)"
                                ><!-- 0 block  -->
                                <i class="fas fa-hand-pointer"></i
                            ></a>
                        </td>
                    </tr>
                </tbody>
                <!--end search data tabale -->
            </table>

            <div class="text-right" v-show="issearch == 'false'">
                <a
                    class="button is-small pagination-next"
                    @click="LoadPage('previous')"
                >
                    Previous
                </a>
                <span> - </span>
                <a
                    class="button is-small pagination-next"
                    @click="LoadPage('next')"
                >
                    Next
                </a>
            </div>
        </div>
    </div>
</template>

<style scoped>
a:hover {
    cursor: pointer;
}
</style>

<script>
export default {
    created() {
        this.getProjects();
    },

    data() {
        return {
            OnlineTicket: [],
            AuthCenter: "",
            loading: false,
            issearch: "",
            tableData: {
                search: "",
                currentPage: 1
            }
        };
    },

    methods: {
        LoadPage($action) {
            if ($action == "previous") {
                this.tableData.currentPage--;
            } else {
                this.tableData.currentPage++;
            }
            this.getProjects();
        },

        getProjects(url = "/cashier/load-online-ticket") {
            this.loading = true;
            axios
                .get(url, { params: this.tableData })
                .then(response => {
                    this.loading = false;
                    this.OnlineTicket = response.data.data.data;
                    this.issearch = response.data.data.issearch;
                    console.log(this.OnlineTicket);
                })
                .catch(errors => {
                    this.loading = false;
                    Toast.fire({ icon: "error", title: errors });
                });
        },

        ProcessOnlineTicket(InvoiceNumber) {
            window.location.href =
                "/cashier/online-ticket-issue/" + InvoiceNumber;
        }
    }
};
</script>
