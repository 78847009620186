<template>
    <div>
        <!-- Create New center -->
        <div
            class="modal fade"
            id="NewCenterModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="NewCenterModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div :class="headerClass">
                        <h5
                            class="modal-title"
                            v-show="!editmode"
                            id="NewCenterModalLabel"
                        >
                            {{ createUserh5 }}
                        </h5>
                        <h5
                            class="modal-title"
                            v-show="editmode"
                            id="EditCenterModalLabel"
                        >
                            {{ createUserh5 }}
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            @click="CloseModel"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body login-content">
                        <form
                            class="full-with-form"
                            @submit.prevent="
                                editmode ? updateCenter() : CreateCenter()
                            "
                        >
                            <div class="form-group row" style="padding: 25px">
                                <div class="col-md-12">
                                    <!-- Cashier -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.fields.Cashier.$error,
                                            ' is-valid focus':
                                                !$v.fields.Cashier.$invalid,
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-house-user"></i>
                                        </div>
                                        <div class="div">
                                            <select
                                                class="input form-select"
                                                aria-label="Default select example"
                                                :class="{
                                                    'is-invalid':
                                                        $v.fields.Cashier
                                                            .$error,
                                                    ' is-valid':
                                                        !$v.fields.Cashier
                                                            .$invalid,
                                                }"
                                                v-model="fields.Cashier"
                                                name="Cashier"
                                                id="Cashier"
                                                @blur="
                                                    $v.fields.Cashier.$touch()
                                                "
                                                ref="Cashier"
                                                @click="GetCashierData()"
                                            >
                                                <option
                                                    selected
                                                    disabled
                                                    value=""
                                                >
                                                    Select Cashier
                                                </option>
                                                <option
                                                    v-for="Cashiers in AllCashierData"
                                                    v-bind:value="
                                                        Cashiers.empno
                                                    "
                                                >
                                                    {{ Cashiers.name }} |
                                                    {{ Cashiers.empno }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.fields.Cashier.$error"
                                    >
                                        <p v-if="!$v.fields.Cashier.required">
                                            The Cashier field is required.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.cashier"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.cashier[0] }}
                                    </span>
                                    <!-- End Cashier -->

                                    <!-- Amount -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.fields.amount.$error ||
                                                errors.name,
                                            ' is-valid focus':
                                                !$v.fields.amount.$invalid,
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-tree"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="name"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Amount</label
                                            >
                                            <input
                                                type="number"
                                                min="0"
                                                class="input form-control"
                                                :class="{
                                                    'is-invalid focus':
                                                        $v.fields.amount
                                                            .$error ||
                                                        errors.name,
                                                    ' is-valid focus':
                                                        !$v.fields.amount
                                                            .$invalid,
                                                }"
                                                v-model="fields.amount"
                                                name="name"
                                                id="name"
                                                autocomplete="center-name"
                                                @input="
                                                    $v.fields.amount.$touch()
                                                "
                                                @blur="
                                                    $v.fields.amount.$touch()
                                                "
                                                @click="clear('name')"
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.fields.amount.$error"
                                    >
                                        <p v-if="!$v.fields.amount.required">
                                            The Amount field is required.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.fields.amount.minValueValue
                                            "
                                        >
                                            The Amount field is most greater
                                            than 0.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.amount"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.amount[0] }}
                                    </span>
                                    <!-- End Amount -->

                                    <!-- Remark -->
                                    <div class="">
                                        <div class="div">
                                            <textarea
                                                class="input form-control"
                                                v-model="fields.remark"
                                                name="remark"
                                                id="remark"
                                                placeholder="Remark"
                                                autocomplete="center-name"
                                            />
                                        </div>

                                        <span
                                            v-if="errors && errors.remark"
                                            class="invalid-feedback"
                                        >
                                            {{ errors.remark[0] }}
                                        </span>
                                    </div>

                                    <!-- End remark -->
                                </div>
                            </div>

                            <div class="row modal-footer border-0">
                                <div class="col-md-6">
                                    <button
                                        v-show="!editmode"
                                        type="button"
                                        class="btn-small-width btn-warning"
                                        @click="clearFields"
                                    >
                                        Clear All Fields
                                    </button>
                                    <button
                                        v-show="editmode"
                                        type="button"
                                        class="btn-small-width btn-danger"
                                        @click="CloseModel"
                                    >
                                        Close
                                    </button>
                                </div>
                                <div class="col-md-3">
                                    <button
                                        type="submit"
                                        class="btn-small-width btn-success"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Create New User -->

        <div v-show="loading" class="text-center">
            <div
                class="spinner-border spinner-border-lg text-success"
                role="status"
            >
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- User Data Table -->
        <div class="UserData" v-show="!loading">
            <div class="login-content" style="display: block">
                <div class="form-group row align-items-center">
                    <div class="col-md-6">
                        <div
                            class="input-div"
                            :class="{
                                'is-invalid input-div-err focus':
                                    $v.tableData.search.$error || errors.search,
                                ' is-valid focus':
                                    !$v.tableData.search.$invalid,
                            }"
                        >
                            <div class="i">
                                <i class="fas fa-search"></i>
                            </div>
                            <div class="div">
                                <label
                                    for="search"
                                    class="col-md-12 col-form-label text-md-left"
                                    >Search By Cashier</label
                                >
                                <input
                                    type="text"
                                    class="input form-control"
                                    :class="{
                                        'is-invalid focus':
                                            $v.tableData.search.$error ||
                                            errors.search,
                                        ' is-valid focus':
                                            !$v.tableData.search.$invalid,
                                    }"
                                    v-model="tableData.search"
                                    name="search"
                                    id="search"
                                    @input="getCenters()"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="control text-left">
                            <div class="select">
                                Show Rows
                                <select
                                    id="rows"
                                    name="rows"
                                    v-model="tableData.length"
                                    @change="getCenters()"
                                    style="margin: 10px"
                                >
                                    <option
                                        v-for="(records, index) in perPage"
                                        :key="index"
                                        :value="records"
                                    >
                                        {{ records }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div
                        v-can="'Open Cash Drawer'"
                        class="col-md-3 align-items-right"
                    >
                        <button
                            type="button"
                            class="btn-small-width btn-success-new"
                            data-toggle="modal"
                            data-target="#NewCenterModal"
                            @click="OpenCenter"
                        >
                            <i class="fas fa-cash-register"> </i> Open New Cash
                            Drawer
                        </button>
                    </div>
                </div>
            </div>

            <datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
            >
                <tbody v-for="project in UserData">
                    <tr :key="project.id">
                        <td>
                            {{ project.cashier.name }} -
                            {{ project.to_cashier }}
                        </td>
                        <td>RS {{ project.amount }}</td>
                        <td>{{ project.remark }}</td>
                        <td>{{ project.created_at }}</td>

                        <td v-if="project.update_status == false">-</td>
                        <td v-else>{{ project.updated_at }}</td>

                        <td v-if="project.update_status == false">
                            <a
                                v-can="'Edit Cash Drawer'"
                                @click="OpenEditCenter(project)"
                                ><!-- 0 block -->
                                <i class="fas fa-edit text-warning"></i>
                            </a>
                        </td>
                        <td v-else></td>

                        <td v-if="project.delete_status == false">
                            <a
                                v-can="'Delete Cash Drawer'"
                                @click="EnabalOrDisabale(project)"
                                ><!-- 0 block -->
                                <i class="fas fa-trash-alt text-danger"></i>
                            </a>
                        </td>
                        <td v-else></td>
                    </tr>
                </tbody>
            </datatable>
            <pagination
                :pagination="pagination"
                @prev="getCenters(pagination.prevPageUrl)"
                @next="getCenters(pagination.nextPageUrl)"
            >
            </pagination>
        </div>
        <!-- End User tabale -->
    </div>
</template>

<style scoped>
a:hover {
    cursor: pointer;
}
</style>

<script>
import { required, decimal, minValue } from "vuelidate/lib/validators";

const config = {
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
    },
};
let formData = new FormData();

export default {
    created() {
        this.AuthWorkingCenter = AuthWorkingCenter;
        this.getCenters();
    },
    data() {
        let sortOrders = {};

        let columns = [
            { width: "10%", label: "Cashier", name: "cashier" },
            { width: "20%", label: "Amount", name: "amount" },
            { width: "20%", label: "Remark", name: "remark" },
            { width: "10%", label: "Date Time", name: "date" },
            { width: "10%", label: "Update Date Time", name: "update_date" },
            { width: "10%", label: "Edit", name: "edit" },
            { width: "8%", label: "Delete", name: "delete" },
        ];

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });
        return {
            fields: {
                Cashier: "",
                amount: "",
                remark: "",
                index: "",
            },

            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "desc",
            },

            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
            },

            headerClass: "modal-header",
            createUserh5: "",
            editmode: "",
            status: "",
            success: false,
            loading: true,
            statusText: "",
            errors: {},
            AuthWorkingCenter: [],
            AllCashierData: [],
            UserData: [],
            columns: columns,
            sortKey: "name",
            sortOrders: sortOrders,
            perPage: ["10", "20", "30"],
        };
    },

    validations: {
        fields: {
            Cashier: {
                required,
            },
            amount: { required, decimal, minValueValue: minValue(1) },
        },

        tableData: {
            search: { required },
        },
    },

    methods: {
        //Center Section

        GetCashierData() {
            axios
                .get("/reports/center-allowed-cahier/" + this.AuthWorkingCenter)
                .then((response) => {
                    console.log(response);
                    this.AllCashierData = response.data;
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong! ",
                    });
                });
        },

        CreateCenter() {
            formData.append("_method", "POST");
            formData.append("amount", this.fields.amount);
            formData.append("cashier", this.fields.Cashier);
            formData.append("remark", this.fields.remark);
            axios
                .post("/cash-drawer-setting", formData, config)
                .then((response) => {
                    this.CloseModel();
                    Toast.fire({
                        icon: "success",
                        title: "Open New Cash Drawer",
                    });
                    this.getCenters();
                })
                .catch((error) => {
                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.createUserh5 =
                            "Open New Cash Drawer - Validation Errors";
                        this.errors = error.response.data.errors;
                    } else {
                        this.createUserh5 =
                            "Open New Cash Drawer- Something went wrong /" +
                            error.response.status;
                    }
                });
        },

        updateCenter() {
            formData.append("_method", "PUT");
            formData.append("amount", this.fields.amount);
            formData.append("cashier", this.fields.Cashier);
            formData.append("remark", this.fields.remark);

            axios
                .post(
                    "/cash-drawer-setting/" + this.fields.index,
                    formData,
                    config
                )
                .then((response) => {
                    this.CloseModel();
                    Toast.fire({ icon: "success", title: "Drawer Updated" });
                    this.getCenters();
                })
                .catch((error) => {
                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.createUserh5 = "Edit Drawer - Validation Errors";
                        this.errors = error.response.data.errors;
                    } else {
                        this.createUserh5 =
                            "Edit Drawer - Something went wrong /" +
                            error.response.data.errors.message;
                    }
                });
        },

        getCenters(url = "/cash-drawer-setting/create") {
            this.tableData.draw++;
            axios
                .get(url, { params: this.tableData })
                .then((response) => {
                    let data = response.data;

                    if (this.tableData.draw == data.draw) {
                        this.UserData = data.data.data;
                        this.configPagination(data.data);
                    }

                    this.loading = false;
                })
                .catch((errors) => {
                    this.loading = false;
                    if (errors.response.status == 403) {
                        Toast.fire({
                            icon: "error",
                            title: "Forbidden , User does not have the right roles ",
                        });
                    }
                });
        },

        OpenCenter() {
            $("#NewCenterModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });
            this.createUserh5 = "Open New Cash Drawer";
            this.editmode = false;
        },

        OpenEditCenter($userData) {
            console.log($userData);
            this.editmode = true;

            $("#NewCenterModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });

            this.createUserh5 =
                "Edit Cash Drawer - " +
                $userData.cashier.name +
                " -" +
                $userData.cashier.empno;
            this.fields.index = $userData.id;
            this.fields.Cashier = $userData.to_cashier;
            this.fields.amount = $userData.amount;
            this.fields.remark = $userData.remark;
        },

        CloseModel() {
            $("#NewCenterModal").modal("hide");
            this.clearFields();
        },

        //End User Profile Section

        //User Status Section
        EnabalOrDisabale($data, $status) {
            Swal.fire({
                title: "Are you sure?",
                text: "Do you want to delete this",
                showCancelButton: true,
                confirmButtonColor: "#239B56",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, ",
            }).then((result) => {
                if (result.isConfirmed) {
                    formData.append("_method", "PUT");
                    formData.append("index", $data.id);
                    axios
                        .post("/cash-drawer-setting/hide", formData, config)
                        .then((response) => {
                            Toast.fire({
                                icon: "success",
                                title: "Cash Drawer information deleted",
                            });
                            this.getCenters();
                        })
                        .catch((error) => {
                            Toast.fire({
                                icon: "error",
                                title:
                                    "Something went wrong!" +
                                    error.response.data.errors.message,
                            });
                        });
                }
            });
        },
        //End satatus Section

        //Data tabale Section
        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

        sortBy(key) {
            this.sortKey = key;
            this.sortOrders[key] = this.sortOrders[key] * -1;
            this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
            this.getCenters();
        },

        getIndex(array, key, value) {
            return array.findIndex((i) => i[key] == value);
        },
        //End Data Tabale section

        //Clear Section
        clear(feild) {
            this.$delete(this.errors, feild);
        },

        clearFields() {
            this.fields.Cashier = "";
            this.fields.amount = "";
            this.fields.remark = "";
            this.createUserh5 = "";
            this.headerClass = "";
            this.clearError();
            this.editmode = "";
            this.headerClass = "modal-header";
            this.createUserh5 = "Open New Cash Drawer";
        },

        clearError() {
            this.$v.$reset();
            this.errors = [];
        },
        //End Clear Section
    },
};
</script>
