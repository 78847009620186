<template>
    <div>
        <!-- Data Section -->
        <div>
            <div class="login-content" style="display: block">              

                <div class="form-group row align-items-center">
                    <div class="col-md-6">
                        <div class=" ">
                            <label class="col-md-12 col-form-label text-md-left"
                                >From</label
                            >
                            <div class="input-div">
                                <div class="i">
                                    <i class="fas fa-calendar-alt"></i>
                                </div>
                                <div class="div">
                                    <input
                                        type="date"
                                        class="input form-control"
                                        v-model="DateFrom"
                                        name="DateFrom"
                                        id="DateFrom"
                                        v-on:keyup.enter="addTicket"
                                        @input="$v.DateFrom.$touch()"
                                        @blur="$v.DateFrom.$touch()"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class=" ">
                            <label class="col-md-12 col-form-label text-md-left"
                                >To</label
                            >
                            <div
                                class="input-div"
                                :class="{
                                    'is-invalid input-div-err focus':
                                        $v.DateTo.$error,
                                    ' is-valid focus': !$v.DateTo.$invalid,
                                }"
                            >
                                <div class="i">
                                    <i class="fas fa-calendar-alt"></i>
                                </div>
                                <div class="div">
                                    <input
                                        type="date"
                                        class="input form-control"
                                        :class="{
                                            'is-invalid focus':
                                                $v.DateTo.$error ||
                                                errors.DateTo,
                                            ' is-valid focus':
                                                !$v.DateTo.$invalid,
                                        }"
                                        v-model="DateTo"
                                        name="DateTo"
                                        id="DateTo"
                                        v-on:keyup.enter="addTicket"
                                        @input="$v.DateTo.$touch()"
                                        @blur="$v.DateTo.$touch()"
                                    />
                                </div>
                            </div>
                        </div>

                        <span
                            v-if="errors && errors.to"
                            class="invalid-feedback"
                        >
                            {{ errors.to[0] }}
                        </span>
                    </div>
                </div>

                <div class="form-group row align-items-center">
                    <div class="col-md-2">
                        <div>
                            <button
                                type="button"
                                class="btn-small-width btn-purple"
                                v-on:click="GetData()"
                            >
                                Get Data
                            </button>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div>
                            <button
                                type="button"
                                class="btn-small-width btn-purple"
                                v-on:click="GetList()"
                            >
                                Get List
                            </button>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div>
                            <button
                                type="button"
                                class="btn-small-width btn-purple"
                                v-on:click="Clear()"
                            >
                                Clear Value
                            </button>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <button
                            type="button"
                            class="btn-small-width btn-yello"
                            v-on:click="PrintOnlyData()"
                        >
                            <i class="fas fa-print"></i> Print Only Data
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Data Section -->

        <!-- Loading  Section -->
        <div v-show="loading" class="text-center">
            <div
                class="spinner-border spinner-border-lg text-success"
                role="status"
            >
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- End Loading Section -->

        <div class="container" v-show="!loading">

            <div class="row" v-if="CashierTicketSummary.length > 0">
                <table class="table">
                    <thead>
                        <tr>
                        <th scope="col">SKU</th>
                        <th class="text-center">Quantity</th>
                        <th class="text-right">Price</th>
                        <th class="text-right">Amount</th>
                        <th class="text-center">Refund Quantity</th>
                        <th class="text-right">Refund Amount</th>
                        <th class="text-right">Final Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="ticket in CashierTicketSummary" >
                        <th scope="row" :class="[ticket.sku == 'G' ? 'G_class' : ticket.sku == 'SC'? 'SC_class' : ticket.sku == 'C' ? 'C_class' :ticket.sku == 'T' ? 'T_class' :  ticket.sku == 'TC'? 'TC_class' :'FS_class'  ] " >{{ ticket.sku }}</th>
                        <td class="text-center">{{ ticket.quantity }}</td>
                        <td class="text-right">{{ ticket.price }}</td>
                        <td class="text-right">{{ ticket.amount }}</td>
                        <td class="text-center">{{ ticket.rquantity }}</td>
                        <td class="text-right">{{ ticket.r_amount }}</td>
                        <td class="text-right">{{ ticket.final_amount }}</td>
                        </tr>        
                    </tbody>
                </table>
            </div>
            
            <!-- Cash Section -->
                <div class="row">
                    <div class="col-md-4">
                        <label>Cash Amount</label>
                        <p>LKR : {{ CashierCash }}</p>
                    </div>
                    <div class="col-md-4">
                        <label>Cash Refund Amount</label>
                        <p>LKR : {{ CashierCashRefund }}</p>
                    </div>
                    <div class="col-md-4">
                        <label>Cash In Hand</label>
                        <p>LKR : {{ CashInHand }}</p>
                    </div>
                </div>
            <!-- End Cash Section -->

            <!-- Card Section -->
                <div class="row">
                    <div class="col-md-4">
                        <label>Card Amount</label>
                        <p>LKR : {{ CashierCard }}</p>
                    </div>
                    <div class="col-md-4">
                        <label>Card Refund Amount</label>
                        <p>LKR : {{ CashierCardRefund }}</p>
                    </div>
                    <div class="col-md-4">
                        <label>Card Balance</label>
                        <p>LKR : {{ CardBalance }}</p>
                    </div>
                </div>
            <!--End Cash Section -->

            <!-- Section01 -->
                <div class="row">
                    <div class="col-md-4">
                        <label>Cashier Balance</label>
                        <p>LKR : {{ Balance }}</p>
                    </div>
                    <div class="col-md-4">
                        <label>Total Invoices</label>
                        <p>{{ TotalInvoices }}</p>
                    </div>
                    <div class="col-md-4">
                        <label>Total Issue Tickets</label>
                        <p>{{ TotalIssueTickets }}</p>
                    </div>
                </div>
            <!--End  Section01 -->

            <!-- Section02 -->
                <div class="row">
                    <div class="col-md-4">
                        <label>Total Refund Amount</label>
                        <p>LKR {{ TotalrefundAmount }}</p>
                    </div>

                    <div class="col-md-4">
                        <label>Total Refund Times</label>
                        <p>{{ TotalRefundInvoice }}</p>
                    </div>

                    <div class="col-md-4">
                        <label>Total Issue Refunded Tickets</label>
                        <p>{{ TotalRefundIssueTickets }}</p>
                    </div>
                </div>
            <!-- Section02 -->
        </div>

        <div>
            <!-- Invoice Data Table -->
            <div v-if="InvoiceData.length > 0">
                <div class="login-content" style="display: block">
                    <h4>Invoice Summary</h4>
                    <div class="form-group row align-items-center">
                        <div class="col-md-10">
                            <div
                                class="input-div"
                                :class="{
                                    'is-invalid input-div-err focus':
                                        $v.tableData.search.$error ||
                                        errors.search,
                                    ' is-valid focus':
                                        !$v.tableData.search.$invalid,
                                }"
                            >
                                <div class="i">
                                    <i class="fas fa-search"></i>
                                </div>
                                <div class="div">
                                    <label
                                        for="search"
                                        class="col-md-12 col-form-label text-md-left"
                                        >Search by using invoice ID and Invoice
                                        Type</label
                                    >
                                    <input
                                        type="text"
                                        class="input form-control"
                                        :class="{
                                            'is-invalid focus':
                                                $v.tableData.search.$error ||
                                                errors.search,
                                            ' is-valid focus':
                                                !$v.tableData.search.$invalid,
                                        }"
                                        v-model="tableData.search"
                                        name="search"
                                        id="search"
                                        @input="GetList()"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="control text-left">
                                <div class="select">
                                    Rows
                                    <select
                                        v-model="tableData.length"
                                        @change="GetList()"
                                        style="margin: 10px"
                                    >
                                        <option
                                            v-for="(records, index) in perPage"
                                            :key="index"
                                            :value="records"
                                        >
                                            {{ records }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>

                <div v-show="invoiceloading" class="text-center">
                    <div
                        class="spinner-border spinner-border-lg text-success"
                        role="status"
                    >
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

                <datatable
                    :columns="columns"
                    :sortKey="sortKey"
                    :sortOrders="sortOrders"
                    @sort="sortBy"
                >
                    <tbody v-for="project in InvoiceData">
                        <tr :key="project.id">
                            <td>{{ project.invoice_id }}</td>
                            <td>{{ project.created_at }}</td>
                            <td>{{ project.invoice_type }}</td>
                            <td>{{ project.tickets }}</td>
                            <td>{{ project.amount }}</td>
                            <td>
                                <a
                                    :href="
                                        '/reports/view-invoice/' +
                                        project.invoice_id
                                    "
                                    ><!-- 0 block  -->
                                    <i class="fas fa-eye"></i
                                ></a>
                            </td>
                        </tr>
                    </tbody>
                </datatable>
                <pagination
                    :pagination="pagination"
                    @prev="GetList(pagination.prevPageUrl)"
                    @next="GetList(pagination.nextPageUrl)"
                >
                </pagination>
            </div>
            <!-- End Invoice table -->

            <!-- Refund Data Table -->
            <div v-if="RefundData.length > 0">
                <div class="login-content" style="display: block">
                    <h3>Refund Summary</h3>
                    <div class="form-group row align-items-center">
                        <div class="col-md-10">
                            <div
                                class="input-div"
                                :class="{
                                    'is-invalid input-div-err focus':
                                        $v.tableData.refundsearch.$error ||
                                        errors.refundsearch,
                                    ' is-valid focus':
                                        !$v.tableData.refundsearch.$invalid,
                                }"
                            >
                                <div class="i">
                                    <i class="fas fa-refundsearch"></i>
                                </div>
                                <div class="div">
                                    <label
                                        for="refundsearch"
                                        class="col-md-12 col-form-label text-md-left"
                                        >Search</label
                                    >
                                    <input
                                        type="text"
                                        class="input form-control"
                                        :class="{
                                            'is-invalid focus':
                                                $v.tableData.refundsearch
                                                    .$error ||
                                                errors.refundsearch,
                                            ' is-valid focus':
                                                !$v.tableData.refundsearch
                                                    .$invalid,
                                        }"
                                        v-model="tableData.refundsearch"
                                        name="refundsearch"
                                        id="refundsearch"
                                        @input="GetList2()"
                                    />
                                </div>
                            </div>
                            <small id="hint" class="form-text text-muted"
                                >Search only Invoice ID
                            </small>
                        </div>

                        <div class="col-md-2">
                            <div class="control text-left">
                                <div class="select">
                                    Rows
                                    <select
                                        v-model="tableData.length"
                                        @change="GetList2()"
                                        style="margin: 10px"
                                    >
                                        <option
                                            v-for="(records, index) in perPage"
                                            :key="index"
                                            :value="records"
                                        >
                                            {{ records }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="refundloading" class="text-center">
                    <div
                        class="spinner-border spinner-border-lg text-success"
                        role="status"
                    >
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

                <datatable>
                    <thead>
                        <tr>
                            <th scope="col">Invoice ID</th>
                            <th scope="col">Date & Time</th>
                            <th scope="col">Refund Amount</th>
                            <th scope="col">Refund Tickets</th>
                            <th scope="col">View</th>
                        </tr>
                    </thead>
                    <tbody v-for="project in RefundData">
                        <tr :key="project.id">
                            <td>{{ project.invoice_id }}</td>
                            <td>{{ project.created_at }}</td>
                            <td>{{ project.ramount }}</td>
                            <td>{{ project.rtickets }}</td>
                            <td>
                                <a
                                    :href="
                                        '/reports/view-invoice/' +
                                        project.invoice_id
                                    "
                                >
                                    <i class="fas fa-eye"></i
                                ></a>
                            </td>
                        </tr>
                    </tbody>
                </datatable>
                <pagination
                    :pagination="pagination2"
                    @prev="GetList2(pagination2.prevPageUrl)"
                    @next="GetList2(pagination2.nextPageUrl)"
                >
                </pagination>
            </div>
            <!-- End Refund table -->
        </div>
    </div>
</template>

<style scoped>
p {
    color: black;
    font-weight: bold;
    font-size: 20px;
}

a:hover {
    cursor: pointer;
}
</style>

<script>
import {
    required,
} from "vuelidate/lib/validators";

export default {
    created() {
        this.AuthRole = Role;

    },
    data() {
        let sortOrders = {};

        let columns = [
            { width: "20%", label: "InvoiceID", name: "invoiceid" },
            { width: "20%", label: "Date & Time", name: "date_time" },
            { width: "20%", label: "Invoice Type", name: "invoice_type" },
            { width: "10%", label: "Tickets", name: "tickets" },
            { width: "10%", label: "Amount", name: "amount" },
            { width: "50%", label: "View", name: "view" },
        ];

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });
        return {           
            para: {
                from: "",
                to: "",
            },

            center: "",
            Cashier: "",
            AllCenterData: [],
            CashierTicketSummary:[],
            DateFrom: "",
            DateTo: "",

            tableData: {
                draw: 0,
                length: 10,
                search: "",
                refundsearch: "",
                isone:true,
                column: 0,
                dir: "desc",    
                From: "",
                to: "",
            },

            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
            },

            pagination2: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
            },

            CashierCash:"",
            CashierCard:"",
            CashierCashRefund:"",
            CashierCardRefund:"",
            CashInHand:"",
            CardBalance:"",
            Balance: "",
            TotalInvoices: "",
            TotalIssueTickets: "",
            TotalRefundInvoice: "",
            TotalrefundAmount: "",
            TotalRefundIssueTickets: "",
            withRefund: "",

            loading: false,
            invoiceloading: "",
            refundloading: "",
            errors: {},
            RefundData: [],
            InvoiceData: [],
            columns: columns,
            sortKey: "name",
            sortOrders: sortOrders,
            perPage: ["10", "20", "30"],
        };
    },

    validations: {     
        center: { required },
        DateFrom: { required },
        DateTo: { required },
        Cashier: { required },

        tableData: {
            search: { required },
            refundsearch: { required },
        },
    },

    methods: {
        //Center Section     
        GetData(url = "/reports/get-cashier-one-summary") {
            this.resetError();
            this.loading = true;
            this.para.from = this.DateFrom;
            this.para.to = this.DateTo;

            axios
                .get(url, { params: this.para })
                .then((response) => {
                    this.CashierCash= response.data.CashierCash;
                    this.CashierCard= response.data.CashierCard;
                    this.CashierCashRefund= response.data.CashierCashRefund;
                    this.CashierCardRefund= response.data.CashierCardRefund;
                    this.CashInHand= response.data.CashInHand;
                    this.CardBalance= response.data.CardBalance;
                    this.Balance = response.data.balance;
                    this.TotalInvoices = response.data.totalInvoices;
                    this.TotalIssueTickets = response.data.totalIssueTickets;
                    this.TotalRefundInvoice = response.data.totalRefundInvoice;
                    this.TotalrefundAmount = response.data.totalRefundAmount;
                    this.TotalRefundIssueTickets =
                        response.data.totalRefundIssueTickets;
                    this.CashierTicketSummary=response.data.ticket_summary;
                    this.loading = false;
                })
                .catch((errors) => {
                    this.loading = false;
                    if (errors.response.status == 422) {
                        this.errors = errors.response.data.errors;
                        Toast.fire({ icon: "error", title: "required field " });
                    } else if (errors.response.status == 403) {
                        Toast.fire({
                            icon: "error",
                            title: "Forbidden , User does not have the right roles ",
                        });
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: "Something Went Wrong ",
                        });
                    }
                });
        },

        GetList(url = "/reports/get-cashier-list-one-summary") {
            this.resetError();
            this.loading = true;
            this.invoiceloading = true;
            this.tableData.draw++;         
            this.tableData.From = this.DateFrom;
            this.tableData.to = this.DateTo;

            axios
                .get(url, { params: this.tableData })
                .then((response) => {
                    let data = response.data;
                    if (this.tableData.draw == data.draw) {
                        this.InvoiceData = data.data.data;
                        this.configPagination(data.data);
                    }
                    this.invoiceloading = false;
                    this.GetList2(); //load refund data
                })
                .catch((errors) => {
                    this.invoiceloading = false;
                    this.loading = false;

                    if (errors.response.status == 403) {
                        Toast.fire({
                            icon: "error",
                            title: "Forbidden , User does not have the right roles ",
                        });
                    } else if (errors.response.status == 422) {
                        this.errors = errors.response.data.errors;
                        Toast.fire({ icon: "error", title: "required field " });
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: "Somthing Went Wrong ",
                        });
                    }
                });
        },

        GetList2(url = "/reports/get-cashier-list-summary-one-refund") {
            this.refundloading = true;
            this.tableData.draw++;
            this.tableData.From = this.DateFrom;
            this.tableData.To = this.DateTo;

            axios
                .get(url, { params: this.tableData })
                .then((response) => {
                    this.loading = false;
                    let data = response.data;

                    if (this.tableData.draw == data.draw) {
                        this.RefundData = data.data.data;
                        this.configPagination2(data.data);
                    }
                    this.refundloading = false;
                })
                .catch((errors) => {
                    this.refundloading = false;
                    this.loading = false;
                    if (errors.response.status == 403) {
                        Toast.fire({
                            icon: "error",
                            title: "Forbidden , User does not have the right roles ",
                        });
                    } else if (errors.response.status == 422) {
                        Toast.fire({ icon: "error", title: "required field " });
                    } else {
                        Toast.fire({
                            icon: "error",
                            title: "Somthing Went Wrong ",
                        });
                    }
                });
        },

        PrintOnlyData() {
            this.tableData.From = this.DateFrom;
            this.tableData.To = this.DateTo;
            window.open(
                "/reports/cashier-data/" +
                    "?from=" +
                    this.tableData.From +
                    "&to=" +
                    this.tableData.To +
                    "&isone=" +
                    this.tableData.isone,
                "_blank"
            );
        },       

        Clear() {
            this.center = "";
            this.Cashier = "";
            this.DateFrom = "";
            this.DateTo = "";
            this.InvoiceData = [];
            this.RefundData = [];
            this.TotalAmount = "";
            this.TotalInvoices = "";
            this.TotalIssueTickets = "";
            this.TotalRefundInvoice = "";
            this.TotalrefundAmount = "";
            this.TotalRefundIssueTickets = "";
            this.CashierTicketSummary=[];
        },

        //Data tabale Section
        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

        configPagination2(data) {
            this.pagination2.lastPage = data.last_page;
            this.pagination2.currentPage = data.current_page;
            this.pagination2.total = data.total;
            this.pagination2.lastPageUrl = data.last_page_url;
            this.pagination2.nextPageUrl = data.next_page_url;
            this.pagination2.prevPageUrl = data.prev_page_url;
            this.pagination2.from = data.from;
            this.pagination2.to = data.to;
        },

        sortBy(key) {
            this.sortKey = key;
            this.sortOrders[key] = this.sortOrders[key] * -1;
            this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
        },

        getIndex(array, key, value) {
            return array.findIndex((i) => i[key] == value);
        },
        //End Data Tabale section

        resetError() {
            this.errors = [];
        },
    },
};
</script>
