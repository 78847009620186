<template>
    <div>
        <div class="row">
            <div v-can="'Create Role'" class="col-md-3">
                <button
                    type="button"
                    class="btn-small-width btn-purple"
                    data-toggle="modal"
                    data-target="#NewRoleModal"
                    @click="OpenNewRoleModel"
                >
                    <i class="fas fa-user-cog"></i> Create Role
                </button>
            </div>

            <div v-can="'Update Role'" class="col-md-3">
                <button
                    type="button"
                    class="btn-small-width btn-purple"
                    data-toggle="modal"
                    data-target="#NewRoleModal"
                    @click="OpenEditRoleModel"
                >
                    <i class="fas fa-user-cog"></i> Edit Role
                </button>
            </div>

            <div v-can="'Create Permission'" class="col-md-3">
                <button
                    type="button"
                    class="btn-small-width btn-yello"
                    data-toggle="modal"
                    data-target="#NewPermissions"
                    @click="OpenNewPermissions"
                >
                    <i class="fas fa-shield-alt"></i> Create Permissions
                </button>
            </div>

            <div v-can="'Edit Permission'" class="col-md-3">
                <button
                    type="button"
                    class="btn-small-width btn-yello"
                    data-toggle="modal"
                    data-target="#EditPermissions"
                    @click="OpenEditPermissions"
                >
                    <i class="fas fa-shield-alt"></i> Edit Permissions
                </button>
            </div>
        </div>

        <!-- Create New User -->
        <div
            class="modal fade"
            id="NewUserModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="NewUserModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div :class="headerClass">
                        <h5
                            class="modal-title"
                            v-show="!editmode"
                            id="NewUserModalLabel"
                        >
                            {{ createUserh5 }}
                        </h5>
                        <h5
                            class="modal-title"
                            v-show="editmode"
                            id="EditUserModalLabel"
                        >
                            {{ createUserh5 }}
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            @click="CloseModel"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body login-content">
                        <form
                            class="full-with-form"
                            @submit.prevent="
                                editmode
                                    ? updateUserProfile()
                                    : CreateUserProfile()
                            "
                        >
                            <div class="form-group row" style="padding: 25px">
                                <div class="col-md-6">
                                    <!-- Full Name -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.fields.name.$error ||
                                                errors.name,
                                            ' is-valid focus':
                                                !$v.fields.name.$invalid,
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-user"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="name"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Full Name</label
                                            >
                                            <input
                                                type="text"
                                                class="input form-control"
                                                :class="{
                                                    'is-invalid focus':
                                                        $v.fields.name.$error ||
                                                        errors.name,
                                                    ' is-valid focus':
                                                        !$v.fields.name
                                                            .$invalid,
                                                }"
                                                v-model="fields.name"
                                                name="name"
                                                id="name"
                                                @input="$v.fields.name.$touch()"
                                                @blur="$v.fields.name.$touch()"
                                                @click="clear('name')"
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.fields.name.$error"
                                    >
                                        <p v-if="!$v.fields.name.required">
                                            The Full Name field is required.
                                        </p>
                                        <p v-if="!$v.fields.name.alphawithspa">
                                            The Full Name format is only contain
                                            letters , spaces and dots( . ).
                                        </p>
                                        <p v-if="!$v.fields.name.minLength">
                                            The Full Name must be at least 3
                                            characters.
                                        </p>
                                        <p v-if="!$v.fields.name.maxLength">
                                            The Full Name must not be greater
                                            than 50 characters.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.name"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.name[0] }}
                                    </span>
                                    <!-- End Full Name -->

                                    <!-- Email -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.fields.email.$error ||
                                                errors.email,
                                            ' is-valid focus':
                                                !$v.fields.email.$invalid,
                                        }"
                                    >
                                        <div class="i">
                                            <i class="far fa-envelope-open"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="email"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Email</label
                                            >
                                            <input
                                                type="email"
                                                class="input form-control"
                                                :class="{
                                                    'is-invalid':
                                                        $v.fields.email
                                                            .$error ||
                                                        errors.email,
                                                    ' is-valid':
                                                        !$v.fields.email
                                                            .$invalid,
                                                }"
                                                v-model="fields.email"
                                                name="email"
                                                id="email"
                                                @input="
                                                    $v.fields.email.$touch()
                                                "
                                                @blur="$v.fields.email.$touch()"
                                                @click="clear('email')"
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.fields.email.$error"
                                    >
                                        <p v-if="!$v.fields.email.required">
                                            The Email field is required.
                                        </p>
                                        <p v-if="!$v.fields.email.email">
                                            Please enter a valid email address.
                                        </p>
                                        <p v-if="!$v.fields.email.maxLength">
                                            The Email must not be greater than
                                            50 characters.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.email"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.email[0] }}
                                    </span>
                                    <!-- End Email -->

                                    <!-- Employee Number -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.fields.empno.$error ||
                                                errors.empno,
                                            ' is-valid focus':
                                                !$v.fields.empno.$invalid,
                                        }"
                                    >
                                        <div class="i">
                                            <i class="far fa-id-badge"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="empno"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Employee Number</label
                                            >
                                            <input
                                                type="text"
                                                class="input form-control"
                                                :class="{
                                                    'is-invalid':
                                                        $v.fields.empno
                                                            .$error ||
                                                        errors.empno,
                                                    ' is-valid':
                                                        !$v.fields.empno
                                                            .$invalid,
                                                }"
                                                v-model="fields.empno"
                                                name="empno"
                                                id="empno"
                                                @input="
                                                    $v.fields.empno.$touch()
                                                "
                                                @blur="$v.fields.empno.$touch()"
                                                @click="clear('empno')"
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.fields.empno.$error"
                                    >
                                        <p v-if="!$v.fields.empno.required">
                                            The Employee Number field is
                                            required.
                                        </p>
                                        <p v-if="!$v.fields.empno.numeric">
                                            The Employee Number must be a
                                            number.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.empno"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.empno[0] }}
                                    </span>
                                    <!-- End Employee Number -->

                                    <!-- Phone Number -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.fields.contactno.$error ||
                                                errors.contactno,
                                            ' is-valid focus':
                                                !$v.fields.contactno.$invalid,
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-phone"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="contactno"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Employee Contact Number</label
                                            >
                                            <input
                                                type="text"
                                                class="input form-control"
                                                :class="{
                                                    'is-invalid':
                                                        $v.fields.contactno
                                                            .$error ||
                                                        errors.contactno,
                                                    ' is-valid':
                                                        !$v.fields.contactno
                                                            .$invalid,
                                                }"
                                                v-model="fields.contactno"
                                                name="contactno"
                                                id="contactno"
                                                @input="
                                                    $v.fields.contactno.$touch()
                                                "
                                                @blur="
                                                    $v.fields.contactno.$touch()
                                                "
                                                @click="clear('contactno')"
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.fields.contactno.$error"
                                    >
                                        <p v-if="!$v.fields.contactno.required">
                                            The Employee Contact Number field is
                                            required.
                                        </p>
                                        <p v-if="!$v.fields.contactno.numeric">
                                            The Employee Contact Number must be
                                            a number.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.fields.contactno.minLength
                                            "
                                        >
                                            The Employee Contact Number must be
                                            10 Numbers.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.fields.contactno.maxLength
                                            "
                                        >
                                            The Employee Contact Number must be
                                            10 Numbers.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.contactno"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.contactno[0] }}
                                    </span>
                                    <!-- End Phone Number -->

                                    <!-- Destination -->

                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.fields.designation.$error ||
                                                errors.designation,
                                            ' is-valid focus':
                                                !$v.fields.designation.$invalid,
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-user-tie"></i>
                                        </div>
                                        <div class="div">
                                            <select
                                                class="input form-select"
                                                aria-label="Default select example"
                                                :class="{
                                                    'is-invalid':
                                                        $v.fields.designation
                                                            .$error ||
                                                        errors.designation,
                                                    ' is-valid':
                                                        !$v.fields.designation
                                                            .$invalid,
                                                }"
                                                v-model="fields.designation"
                                                name="designation"
                                                id="designation"
                                                required
                                                @blur="
                                                    $v.fields.designation.$touch()
                                                "
                                                @click="clear('designation')"
                                            >
                                                <option
                                                    selected
                                                    disabled
                                                    value=""
                                                >
                                                    Select Designation
                                                </option>
                                                <option
                                                    v-for="roles in AllRoleData"
                                                    v-bind:value="roles.name"
                                                >
                                                    {{ roles.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.fields.designation.$error"
                                    >
                                        <p
                                            v-if="
                                                !$v.fields.designation.required
                                            "
                                        >
                                            The Designation field is required.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.designation"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.designation[0] }}
                                    </span>
                                    <!-- End Designation -->

                                    <!-- Center -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.fields.working_center
                                                    .$error ||
                                                errors.working_center,
                                            ' is-valid focus':
                                                !$v.fields.working_center
                                                    .$invalid,
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-home"></i>
                                        </div>
                                        <div class="div">
                                            <select
                                                class="input form-select"
                                                aria-label="Default select example"
                                                :class="{
                                                    'is-invalid':
                                                        $v.fields.working_center
                                                            .$error ||
                                                        errors.working_center,
                                                    ' is-valid':
                                                        !$v.fields
                                                            .working_center
                                                            .$invalid,
                                                }"
                                                v-model="fields.working_center"
                                                name="working_center"
                                                id="working_center"
                                                required
                                                @blur="
                                                    $v.fields.working_center.$touch()
                                                "
                                                @click="clear('working_center')"
                                            >
                                                <option
                                                    selected
                                                    disabled
                                                    value=""
                                                >
                                                    Select Working Center
                                                </option>
                                                <option
                                                    v-for="center in AllCenterData"
                                                    v-bind:value="
                                                        center.centercode
                                                    "
                                                >
                                                    {{ center.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.fields.working_center.$error"
                                    >
                                        <p
                                            v-if="
                                                !$v.fields.working_center
                                                    .required
                                            "
                                        >
                                            The Working Center field is
                                            required.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.working_center"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.working_center[0] }}
                                    </span>
                                    <!-- End Center -->
                                </div>

                                <div class="col-md-6">
                                    <div class="user-upload-section">
                                        <p>Employee User Avatar</p>
                                        <div
                                            class="input-div"
                                            :class="{
                                                'is-invalid input-div-err':
                                                    picval,
                                                ' is-valid focus': !picval,
                                            }"
                                        >
                                            <div class="i">
                                                <i
                                                    class="far fa-user-circle"
                                                    style="font-size: 20px"
                                                ></i>
                                            </div>
                                            <div class="div">
                                                <input
                                                    type="file"
                                                    ref="inputFile"
                                                    @change="getImage"
                                                    accept="image/x-png,image/jpeg,image/jpg"
                                                    name="useravatar"
                                                    id="useravatar"
                                                    class="form-control-file input input-avatar"
                                                    :class="{
                                                        'is-invalid input-div-err':
                                                            picval,
                                                        ' is-valid focus':
                                                            !picval,
                                                    }"
                                                />
                                            </div>
                                        </div>
                                        <img
                                            :src="avatar"
                                            alt="..."
                                            class="upload-avatar"
                                        />
                                        <span class="invalid-feedback">
                                            {{ picval }}
                                        </span>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="control">
                                            <div class="select">
                                                Need Reset Password ?

                                                <input
                                                    type="checkbox"
                                                    id="checkbox"
                                                    v-model="
                                                        fields.ResetPassword
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-if="!editmode || fields.ResetPassword"
                                    >
                                        <!-- Password -->
                                        <div
                                            class="input-div"
                                            :class="{
                                                'is-invalid input-div-err focus':
                                                    $v.fields.password.$error ||
                                                    errors.password,
                                                ' is-valid focus':
                                                    !$v.fields.password
                                                        .$invalid,
                                            }"
                                        >
                                            <div class="i">
                                                <i class="fas fa-key"></i>
                                            </div>
                                            <div class="div">
                                                <label
                                                    for="password"
                                                    class="col-md-12 col-form-label text-md-left"
                                                    >Password</label
                                                >
                                                <input
                                                    type="password"
                                                    class="input form-control"
                                                    :class="{
                                                        'is-invalid':
                                                            $v.fields.password
                                                                .$error ||
                                                            errors.password,
                                                        ' is-valid':
                                                            !$v.fields.password
                                                                .$invalid,
                                                    }"
                                                    v-model="fields.password"
                                                    name="password"
                                                    id="password"
                                                    @input="
                                                        $v.fields.password.$touch()
                                                    "
                                                    @blur="
                                                        $v.fields.password.$touch()
                                                    "
                                                    @click="clear('password')"
                                                />
                                            </div>
                                        </div>

                                        <span
                                            class="invalid-feedback"
                                            v-if="$v.fields.password.$error"
                                        >
                                            <p
                                                v-if="
                                                    !$v.fields.password.required
                                                "
                                            >
                                                The Password field is required.
                                            </p>
                                            <p
                                                v-if="
                                                    !$v.fields.password
                                                        .minLength
                                                "
                                            >
                                                The Password Min length is 5.
                                            </p>
                                            <p
                                                v-if="
                                                    !$v.fields.password
                                                        .maxLength
                                                "
                                            >
                                                The Password must not be greater
                                                than 8 characters.
                                            </p>
                                        </span>

                                        <span
                                            v-if="errors && errors.password"
                                            class="invalid-feedback"
                                        >
                                            {{ errors.password[0] }}
                                        </span>
                                        <!-- End Password -->

                                        <!--Conform Password -->
                                        <div
                                            class="input-div"
                                            :class="{
                                                'is-invalid input-div-err focus':
                                                    $v.fields
                                                        .password_confirmation
                                                        .$error ||
                                                    errors.password_confirmation,
                                                ' is-valid focus':
                                                    !$v.fields
                                                        .password_confirmation
                                                        .$invalid,
                                            }"
                                        >
                                            <div class="i">
                                                <i class="fas fa-key"></i>
                                            </div>
                                            <div class="div">
                                                <label
                                                    for="password-confirm"
                                                    class="col-md-12 col-form-label text-md-left"
                                                    >Conform Password</label
                                                >
                                                <input
                                                    type="password"
                                                    class="input form-control"
                                                    :class="{
                                                        'is-invalid':
                                                            $v.fields
                                                                .password_confirmation
                                                                .$error ||
                                                            errors.password_confirmation,
                                                        ' is-valid':
                                                            !$v.fields
                                                                .password_confirmation
                                                                .$invalid,
                                                    }"
                                                    v-model="
                                                        fields.password_confirmation
                                                    "
                                                    name="password_confirmation"
                                                    id="password-confirm"
                                                    @input="
                                                        $v.fields.password_confirmation.$touch()
                                                    "
                                                    @blur="
                                                        $v.fields.password_confirmation.$touch()
                                                    "
                                                    @click="
                                                        clear(
                                                            'password_confirmation'
                                                        )
                                                    "
                                                    autocomplete="new-password"
                                                />
                                            </div>
                                        </div>

                                        <span
                                            class="invalid-feedback"
                                            v-if="
                                                $v.fields.password_confirmation
                                                    .$error
                                            "
                                        >
                                            <p
                                                v-if="
                                                    !$v.fields
                                                        .password_confirmation
                                                        .required
                                                "
                                            >
                                                The Password field is required.
                                            </p>
                                            <p
                                                v-if="
                                                    !$v.fields
                                                        .password_confirmation
                                                        .sameAsPassword
                                                "
                                            >
                                                The Password & Confirm Password
                                                do not match
                                            </p>
                                        </span>

                                        <span
                                            v-if="
                                                errors &&
                                                errors.password_confirmation
                                            "
                                            class="invalid-feedback"
                                        >
                                            {{
                                                errors.password_confirmation[0]
                                            }}
                                        </span>
                                        <!-- End Conform Password -->
                                    </div>
                                </div>
                            </div>

                            <div class="row modal-footer border-0">
                                <div class="col-md-3">
                                    <button
                                        v-show="!editmode"
                                        class="btn-small-width btn-warning"
                                        @click="clearFields"
                                    >
                                        Clear All Fields
                                    </button>
                                    <button
                                        v-show="editmode"
                                        class="btn-small-width btn-danger"
                                        @click="CloseModel"
                                    >
                                        Close
                                    </button>
                                </div>
                                <div class="col-md-3">
                                    <button
                                        type="submit"
                                        class="btn-small-width btn-success"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Create New User -->

        <!--Role -->
        <div
            class="modal fade"
            id="NewRoleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="NewroleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div :class="headerClass">
                        <h5
                            class="modal-title"
                            v-show="!RoleEditmode"
                            id="NewroleModalLabel"
                        >
                            {{ createUserh5 }}
                        </h5>
                        <h5
                            class="modal-title"
                            v-show="RoleEditmode"
                            id="NewroleModalLabel"
                        >
                            {{ createUserh5 }}
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            @click="
                                RoleEditmode
                                    ? closeEditRoleModel()
                                    : CloseRoleModel()
                            "
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body login-content">
                        <form
                            class="full-with-form"
                            @submit.prevent="
                                RoleEditmode ? updateRole() : createRole()
                            "
                        >
                            <div class="form-group row" style="padding: 25px">
                                <div class="col-md-12" v-if="RoleEditmode">
                                    <!-- Select Edit role -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.roles.selectRole.$error ||
                                                errors.selectRole,
                                            ' is-valid focus':
                                                !$v.roles.selectRole.$invalid,
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-user-tie"></i>
                                        </div>
                                        <div class="div">
                                            <select
                                                class="input form-select"
                                                aria-label="Default select example"
                                                :class="{
                                                    'is-invalid':
                                                        $v.roles.selectRole
                                                            .$error ||
                                                        errors.selectRole,
                                                    ' is-valid':
                                                        !$v.roles.selectRole
                                                            .$invalid,
                                                }"
                                                v-model="roles.selectRole"
                                                name="selectRole"
                                                id="selectRole"
                                                required
                                                @blur="
                                                    $v.roles.selectRole.$touch()
                                                "
                                                @change="getRoleEditabaleData()"
                                                @click="clear('selectRole')"
                                            >
                                                <option
                                                    selected
                                                    disabled
                                                    value=""
                                                >
                                                    Select Edit Role
                                                </option>
                                                <option
                                                    v-for="role in AllRoleData"
                                                    v-bind:value="role.id"
                                                >
                                                    {{ role.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.roles.selectRole.$error"
                                    >
                                        <p v-if="!$v.roles.selectRole.required">
                                            The Permission field is required.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.selectRole"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.selectRole[0] }}
                                    </span>
                                    <!-- End Select Edit role -->
                                </div>

                                <div class="col-md-12">
                                    <!-- Role Name -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.roles.name.$error ||
                                                errors.name,
                                            ' is-valid focus':
                                                !$v.roles.name.$invalid,
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-user-cog"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="name"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Role Name</label
                                            >
                                            <input
                                                type="text"
                                                class="input form-control"
                                                :class="{
                                                    'is-invalid focus':
                                                        $v.roles.name.$error ||
                                                        errors.name,
                                                    ' is-valid focus':
                                                        !$v.roles.name.$invalid,
                                                }"
                                                v-model="roles.name"
                                                name="rolename"
                                                id="rolename"
                                                @input="$v.roles.name.$touch()"
                                                @blur="$v.roles.name.$touch()"
                                                @click="clear('name')"
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.roles.name.$error"
                                    >
                                        <p v-if="!$v.roles.name.required">
                                            The Role Name field is required.
                                        </p>
                                        <p v-if="!$v.roles.name.alphawithspa">
                                            The Role Name format is only contain
                                            letters and spaces.
                                        </p>
                                        <p v-if="!$v.roles.name.minLength">
                                            The Role Name must be at least 3
                                            characters.
                                        </p>
                                        <p v-if="!$v.roles.name.maxLength">
                                            The Role Name must not be greater
                                            than 50 characters.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.name"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.name[0] }}
                                    </span>
                                    <!-- End Role Name -->
                                </div>
                            </div>

                            <!-- Create Role Permission -->
                            <div
                                class="form-group row"
                                style="padding: 25px"
                                v-show="!RoleEditmode"
                            >
                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong
                                            >Vertical Dashboard</strong
                                        ></label
                                    >
                                    <div
                                        v-for="role in permission.allPermissions"
                                    >
                                        <div class="">
                                            <div
                                                v-if="
                                                    role.slug ==
                                                    'Vertical Dashboard'
                                                "
                                            >
                                                <input
                                                    class="form-check-input"
                                                    :id="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    :name="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    type="checkbox"
                                                    :value="role.id"
                                                    v-model="
                                                        roles.userPermission
                                                    "
                                                    @click="
                                                        clear('permissions')
                                                    "
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    >{{ role.name }}</label
                                                >
                                            </div>
                                            <div v-else></div>
                                        </div>
                                    </div>

                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label><strong>User Setting</strong></label>
                                    <div
                                        v-for="role in permission.allPermissions"
                                    >
                                        <div class="">
                                            <div
                                                v-if="
                                                    role.slug == 'User Setting'
                                                "
                                            >
                                                <input
                                                    class="form-check-input"
                                                    :id="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    :name="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    type="checkbox"
                                                    :value="role.id"
                                                    v-model="
                                                        roles.userPermission
                                                    "
                                                    @click="
                                                        clear('permissions')
                                                    "
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    >{{ role.name }}</label
                                                >
                                            </div>
                                            <div v-else></div>
                                        </div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong>Center Setting</strong></label
                                    >
                                    <div
                                        v-for="role in permission.allPermissions"
                                    >
                                        <div class="">
                                            <div
                                                v-if="
                                                    role.slug ==
                                                    'Center Setting'
                                                "
                                            >
                                                <input
                                                    class="form-check-input"
                                                    :id="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    :name="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    type="checkbox"
                                                    :value="role.id"
                                                    v-model="
                                                        roles.userPermission
                                                    "
                                                    @click="
                                                        clear('permissions')
                                                    "
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    >{{ role.name }}</label
                                                >
                                            </div>
                                            <div v-else></div>
                                        </div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong>Ticket Setting</strong></label
                                    >
                                    <div
                                        v-for="role in permission.allPermissions"
                                    >
                                        <div class="">
                                            <div
                                                v-if="
                                                    role.slug ==
                                                    'Ticket Setting'
                                                "
                                            >
                                                <input
                                                    class="form-check-input"
                                                    :id="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    :name="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    type="checkbox"
                                                    :value="role.id"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    >{{ role.name }}</label
                                                >
                                            </div>
                                            <div v-else></div>
                                        </div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong>Cashier Setting</strong></label
                                    >
                                    <div
                                        v-for="role in permission.allPermissions"
                                    >
                                        <div class="">
                                            <div
                                                v-if="
                                                    role.slug ==
                                                    'Cashier Setting'
                                                "
                                            >
                                                <input
                                                    class="form-check-input"
                                                    :id="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    :name="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    type="checkbox"
                                                    :value="role.id"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    >{{ role.name }}</label
                                                >
                                            </div>
                                            <div v-else></div>
                                        </div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong>Report Setting</strong></label
                                    >
                                    <div
                                        v-for="role in permission.allPermissions"
                                    >
                                        <div class="">
                                            <div
                                                v-if="
                                                    role.slug ==
                                                    'Report Setting'
                                                "
                                            >
                                                <input
                                                    class="form-check-input"
                                                    :id="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    :name="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    type="checkbox"
                                                    :value="role.id"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    >{{ role.name }}</label
                                                >
                                            </div>
                                            <div v-else></div>
                                        </div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong>Application Setting</strong></label
                                    >
                                    <div
                                        v-for="role in permission.allPermissions"
                                    >
                                        <div class="">
                                            <div
                                                v-if="
                                                    role.slug ==
                                                    'Application Setting'
                                                "
                                            >
                                                <input
                                                    class="form-check-input"
                                                    :id="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    :name="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    type="checkbox"
                                                    :value="role.id"
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="
                                                        'role_permission' +
                                                        role.id
                                                    "
                                                    >{{ role.name }}</label
                                                >
                                            </div>
                                            <div v-else></div>
                                        </div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>
                            </div>
                            <!-- end Role Permission -->

                            <!-- Edit Role Permission -->
                            <div
                                class="form-group row"
                                style="padding: 25px"
                                v-show="RoleEditmode"
                            >
                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong
                                            >Vertical Dashboard</strong
                                        ></label
                                    >
                                    <div
                                        v-for="role in permission.allPermissions"
                                    >
                                        <div class="">
                                            <div
                                                v-if="
                                                    role.slug ==
                                                    'Vertical Dashboard'
                                                "
                                            >
                                                <input
                                                    class="form-check-input"
                                                    :id="role.id"
                                                    :name="role.id"
                                                    type="checkbox"
                                                    :value="role.name"
                                                    @input="
                                                        AddOrRemovePermission
                                                    "
                                                    :checked="
                                                        roles.userPermission.includes(
                                                            role.name
                                                        )
                                                    "
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="role.id"
                                                    >{{ role.name }}</label
                                                >
                                            </div>
                                            <div v-else></div>
                                        </div>
                                    </div>

                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label><strong>User Setting</strong></label>
                                    <div
                                        v-for="role in permission.allPermissions"
                                    >
                                        <div class="">
                                            <div
                                                v-if="
                                                    role.slug == 'User Setting'
                                                "
                                            >
                                                <input
                                                    class="form-check-input"
                                                    :id="role.id"
                                                    :name="role.id"
                                                    type="checkbox"
                                                    :value="role.name"
                                                    @input="
                                                        AddOrRemovePermission
                                                    "
                                                    :checked="
                                                        roles.userPermission.includes(
                                                            role.name
                                                        )
                                                    "
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="role.id"
                                                    >{{ role.name }}</label
                                                >
                                            </div>
                                            <div v-else></div>
                                        </div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong>Center Setting</strong></label
                                    >
                                    <div
                                        v-for="role in permission.allPermissions"
                                    >
                                        <div class="">
                                            <div
                                                v-if="
                                                    role.slug ==
                                                    'Center Setting'
                                                "
                                            >
                                                <input
                                                    class="form-check-input"
                                                    :id="role.id"
                                                    :name="role.id"
                                                    type="checkbox"
                                                    :value="role.name"
                                                    @input="
                                                        AddOrRemovePermission
                                                    "
                                                    :checked="
                                                        roles.userPermission.includes(
                                                            role.name
                                                        )
                                                    "
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="role.id"
                                                    >{{ role.name }}</label
                                                >
                                            </div>
                                            <div v-else></div>
                                        </div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong>Ticket Setting</strong></label
                                    >
                                    <div
                                        v-for="role in permission.allPermissions"
                                    >
                                        <div class="">
                                            <div
                                                v-if="
                                                    role.slug ==
                                                    'Ticket Setting'
                                                "
                                            >
                                                <input
                                                    class="form-check-input"
                                                    :id="role.id"
                                                    :name="role.id"
                                                    type="checkbox"
                                                    :value="role.name"
                                                    @input="
                                                        AddOrRemovePermission
                                                    "
                                                    :checked="
                                                        roles.userPermission.includes(
                                                            role.name
                                                        )
                                                    "
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="role.id"
                                                    >{{ role.name }}</label
                                                >
                                            </div>
                                            <div v-else></div>
                                        </div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong>Cashier Setting</strong></label
                                    >
                                    <div
                                        v-for="role in permission.allPermissions"
                                    >
                                        <div class="">
                                            <div
                                                v-if="
                                                    role.slug ==
                                                    'Cashier Setting'
                                                "
                                            >
                                                <input
                                                    class="form-check-input"
                                                    :id="role.id"
                                                    :name="role.id"
                                                    type="checkbox"
                                                    :value="role.name"
                                                    @input="
                                                        AddOrRemovePermission
                                                    "
                                                    :checked="
                                                        roles.userPermission.includes(
                                                            role.name
                                                        )
                                                    "
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="role.id"
                                                    >{{ role.name }}</label
                                                >
                                            </div>
                                            <div v-else></div>
                                        </div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong>Report Setting</strong></label
                                    >
                                    <div
                                        v-for="role in permission.allPermissions"
                                    >
                                        <div class="">
                                            <div
                                                v-if="
                                                    role.slug ==
                                                    'Report Setting'
                                                "
                                            >
                                                <input
                                                    class="form-check-input"
                                                    :id="role.id"
                                                    :name="role.id"
                                                    type="checkbox"
                                                    :value="role.name"
                                                    @input="
                                                        AddOrRemovePermission
                                                    "
                                                    :checked="
                                                        roles.userPermission.includes(
                                                            role.name
                                                        )
                                                    "
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="role.id"
                                                    >{{ role.name }}</label
                                                >
                                            </div>
                                            <div v-else></div>
                                        </div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong>Application Setting</strong></label
                                    >
                                    <div
                                        v-for="role in permission.allPermissions"
                                    >
                                        <div class="">
                                            <div
                                                v-if="
                                                    role.slug ==
                                                    'Application Setting'
                                                "
                                            >
                                                <input
                                                    class="form-check-input"
                                                    :id="role.id"
                                                    :name="role.id"
                                                    type="checkbox"
                                                    :value="role.name"
                                                    @input="
                                                        AddOrRemovePermission
                                                    "
                                                    :checked="
                                                        roles.userPermission.includes(
                                                            role.name
                                                        )
                                                    "
                                                />
                                                <label
                                                    class="form-check-label"
                                                    :for="role.id"
                                                    >{{ role.name }}</label
                                                >
                                            </div>
                                            <div v-else></div>
                                        </div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>
                            </div>
                            <!-- End Edit Role Permission-->

                            <div class="row">
                                <span
                                    v-if="errors && errors.permissions"
                                    class="invalid-feedback"
                                >
                                    {{ errors.permissions[0] }}
                                </span>
                            </div>

                            <div class="row modal-footer border-0">
                                <div class="col-md-3">
                                    <button
                                        type="button"
                                        class="btn-small-width btn-warning"
                                        @click="
                                            RoleEditmode
                                                ? clearRoleEditFeilds()
                                                : clearRoleFeilds()
                                        "
                                    >
                                        Clear All Fields
                                    </button>
                                </div>
                                <div class="col-md-3">
                                    <button
                                        type="submit"
                                        class="btn-small-width btn-success"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Role -->

        <!-- New Permission -->
        <div
            class="modal fade"
            id="NewPermissions"
            tabindex="-1"
            role="dialog"
            aria-labelledby="NewUserPermissionModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div :class="headerClass">
                        <h5
                            class="modal-title"
                            v-show="!permissioneditmode"
                            id="NewUserPermissionModalLabel"
                        >
                            {{ createUserh5 }}
                        </h5>
                        <h5
                            class="modal-title"
                            v-show="permissioneditmode"
                            id="NewUserPermissionModalLabel"
                        >
                            {{ createUserh5 }}
                        </h5>

                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            @click="
                                permissioneditmode
                                    ? closeEditPermissionModel()
                                    : CloseNewPermissionModel()
                            "
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body login-content">
                        <form
                            class="full-with-form"
                            @submit.prevent="
                                permissioneditmode
                                    ? EditPermission()
                                    : CreatePermission()
                            "
                        >
                            <div class="form-group row" style="padding: 25px">
                                <div class="col-md-12">
                                    <!-- Select Edit Permission -->
                                    <div v-if="permissioneditmode">
                                        <div
                                            class="input-div"
                                            :class="{
                                                'is-invalid input-div-err focus':
                                                    $v.permission
                                                        .selectPermission
                                                        .$error ||
                                                    errors.selectPermission,
                                                ' is-valid focus':
                                                    !$v.permission
                                                        .selectPermission
                                                        .$invalid,
                                            }"
                                        >
                                            <div class="i">
                                                <i class="fas fa-user-tie"></i>
                                            </div>
                                            <div class="div">
                                                <select
                                                    class="input form-select"
                                                    aria-label="Default select example"
                                                    :class="{
                                                        'is-invalid':
                                                            $v.permission
                                                                .selectPermission
                                                                .$error ||
                                                            errors.selectPermission,
                                                        ' is-valid':
                                                            !$v.permission
                                                                .selectPermission
                                                                .$invalid,
                                                    }"
                                                    v-model="
                                                        permission.selectPermission
                                                    "
                                                    name="selectPermission"
                                                    id="selectPermission"
                                                    required
                                                    @blur="
                                                        $v.permission.selectPermission.$touch()
                                                    "
                                                    @change="
                                                        getPermissionEditabaleData()
                                                    "
                                                    @click="
                                                        clear(
                                                            'selectPermission'
                                                        )
                                                    "
                                                >
                                                    <option
                                                        selected
                                                        disabled
                                                        value=""
                                                    >
                                                        Select Edit Permission
                                                    </option>
                                                    <option
                                                        v-for="permissions in permission.allPermissions"
                                                        v-bind:value="
                                                            permissions.id
                                                        "
                                                    >
                                                        {{ permissions.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <span
                                            class="invalid-feedback"
                                            v-if="
                                                $v.permission.selectPermission
                                                    .$error
                                            "
                                        >
                                            <p
                                                v-if="
                                                    !$v.permission
                                                        .selectPermission
                                                        .required
                                                "
                                            >
                                                The Permission field is
                                                required.
                                            </p>
                                        </span>

                                        <span
                                            v-if="
                                                errors &&
                                                errors.selectPermission
                                            "
                                            class="invalid-feedback"
                                        >
                                            {{ errors.selectPermission[0] }}
                                        </span>
                                    </div>
                                    <!-- End Select Edit Permission -->

                                    <div>
                                        <!-- Permission Name -->
                                        <div
                                            class="input-div"
                                            :class="{
                                                'is-invalid input-div-err focus':
                                                    $v.permission.permissionName
                                                        .$error ||
                                                    errors.permissionName,
                                                ' is-valid focus':
                                                    !$v.permission
                                                        .permissionName
                                                        .$invalid,
                                            }"
                                        >
                                            <div class="i">
                                                <i
                                                    class="fas fa-shield-alt"
                                                ></i>
                                            </div>
                                            <div class="div">
                                                <label
                                                    for="permissionName"
                                                    class="col-md-12 col-form-label text-md-left"
                                                    >Permission Name</label
                                                >
                                                <input
                                                    type="text"
                                                    class="input form-control"
                                                    :class="{
                                                        'is-invalid focus':
                                                            $v.permission
                                                                .permissionName
                                                                .$error ||
                                                            errors.permissionName,
                                                        ' is-valid focus':
                                                            !$v.permission
                                                                .permissionName
                                                                .$invalid,
                                                    }"
                                                    v-model="
                                                        permission.permissionName
                                                    "
                                                    name="permissionName"
                                                    id="permissionName"
                                                    @input="
                                                        $v.permission.permissionName.$touch()
                                                    "
                                                    @blur="
                                                        $v.permission.permissionName.$touch()
                                                    "
                                                    @click="
                                                        clear('permissionName')
                                                    "
                                                />
                                            </div>
                                        </div>

                                        <span
                                            class="invalid-feedback"
                                            v-if="
                                                $v.permission.permissionName
                                                    .$error
                                            "
                                        >
                                            <p
                                                v-if="
                                                    !$v.permission
                                                        .permissionName.required
                                                "
                                            >
                                                The Permission Name field is
                                                required.
                                            </p>
                                            <p
                                                v-if="
                                                    !$v.permission
                                                        .permissionName
                                                        .alphawithspa
                                                "
                                            >
                                                The Permission Name format is
                                                only contain letters and spaces.
                                            </p>
                                            <p
                                                v-if="
                                                    !$v.permission
                                                        .permissionName
                                                        .minLength
                                                "
                                            >
                                                The Permission Name must be at
                                                least 3 characters.
                                            </p>
                                            <p
                                                v-if="
                                                    !$v.permission
                                                        .permissionName
                                                        .maxLength
                                                "
                                            >
                                                The Permission Name must not be
                                                greater than 50 characters.
                                            </p>
                                        </span>

                                        <span
                                            v-if="
                                                errors && errors.permissionName
                                            "
                                            class="invalid-feedback"
                                        >
                                            {{ errors.permissionName[0] }}
                                        </span>
                                        <!-- End Permission Name -->

                                        <!-- Select Permission Category -->
                                        <div
                                            class="input-div"
                                            :class="{
                                                'is-invalid input-div-err focus':
                                                    $v.permission.slug.$error ||
                                                    errors.slug,
                                                ' is-valid focus':
                                                    !$v.permission.slug
                                                        .$invalid,
                                            }"
                                        >
                                            <div class="i">
                                                <i class="fas fa-user-tie"></i>
                                            </div>
                                            <div class="div">
                                                <select
                                                    class="input form-select"
                                                    aria-label="Default select example"
                                                    :class="{
                                                        'is-invalid':
                                                            $v.permission.slug
                                                                .$error ||
                                                            errors.slug,
                                                        ' is-valid':
                                                            !$v.permission.slug
                                                                .$invalid,
                                                    }"
                                                    v-model="permission.slug"
                                                    name="slug"
                                                    id="slug"
                                                    @blur="
                                                        $v.permission.slug.$touch()
                                                    "
                                                    @click="clear('slug')"
                                                    required
                                                >
                                                    <option
                                                        selected
                                                        disabled
                                                        value=""
                                                    >
                                                        Select Permission
                                                        Category
                                                    </option>
                                                    <option
                                                        value="Vertical Dashboard"
                                                    >
                                                        Vertical Dashboard
                                                    </option>
                                                    <option
                                                        value="User Setting"
                                                    >
                                                        User Setting
                                                    </option>
                                                    <option
                                                        value="Center Setting"
                                                    >
                                                        Center Setting
                                                    </option>
                                                    <option
                                                        value="Ticket Setting"
                                                    >
                                                        Ticket Setting
                                                    </option>
                                                    <option
                                                        value="Cashier Setting"
                                                    >
                                                        Cashier Setting
                                                    </option>
                                                    <option
                                                        value="Report Setting"
                                                    >
                                                        Report Setting
                                                    </option>

                                                    <option
                                                        value="Application Setting"
                                                    >
                                                        Application Setting
                                                    </option>

                                                    <option
                                                        value="Main Dashboard"
                                                    >
                                                        Main Dashboard
                                                    </option>
                                                    <option value="Top Header">
                                                        Top Header
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <span
                                            class="invalid-feedback"
                                            v-if="$v.permission.slug.$error"
                                        >
                                            <p
                                                v-if="
                                                    !$v.permission.slug.required
                                                "
                                            >
                                                The Permission Category field is
                                                required.
                                            </p>
                                        </span>

                                        <span
                                            v-if="errors && errors.slug"
                                            class="invalid-feedback"
                                        >
                                            {{ errors.slug[0] }}
                                        </span>
                                        <!-- End Select Permission Category -->
                                    </div>
                                </div>
                            </div>

                            <div class="modal-footer border-0">
                                <button
                                    v-show="!permissioneditmode"
                                    type="button"
                                    class="btn-small-width btn-warning"
                                    @click="clearPermissionsFields"
                                >
                                    Clear All Fields
                                </button>
                                <button
                                    v-show="permissioneditmode"
                                    type="button"
                                    class="btn-small-width btn-warning"
                                    @click="clearEditPermissionsFields"
                                >
                                    Clear All Fields
                                </button>
                                <button
                                    type="submit"
                                    class="btn-small-width btn-success"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- End of New Permission -->

        <!--User's Model Permission -->
        <div
            class="modal fade"
            id="UserModelPermission"
            tabindex="-1"
            role="dialog"
            aria-labelledby="UserModelPermissionLabale"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div :class="headerClass">
                        <h5 class="modal-title" id="UserModelPermissionLabale">
                            {{ createUserh5 }}
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            @click="CloseModelPermission()"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body login-content">
                        <form
                            class="full-with-form"
                            @submit.prevent="AddUserModelPermission()"
                        >
                            <input
                                id="permission.userID"
                                type="hidden"
                                :id="permission.userID"
                                v-model="permission.userID"
                            />

                            <div class="form-group row" style="padding: 25px">
                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong
                                            >Vertical Dashboard</strong
                                        ></label
                                    >
                                    <div
                                        v-for="(
                                            role, index
                                        ) in permission.allPermissions"
                                    >
                                        <div
                                            v-if="
                                                role.slug ==
                                                'Vertical Dashboard'
                                            "
                                        >
                                            <input
                                                class="form-check-input"
                                                :id="
                                                    'user_permission' + role.id
                                                "
                                                :name="
                                                    'user_permission' + role.id
                                                "
                                                type="checkbox"
                                                :value="role.id"
                                                v-model="
                                                    permission.ModelPermission
                                                "
                                                :key="role.id"
                                                :disabled="
                                                    roles.Assignepermission.includes(
                                                        role.id
                                                    )
                                                "
                                                :checked="
                                                    roles.Assignepermission.includes(
                                                        role.id
                                                    )
                                                "
                                            />
                                            <label
                                                class="form-check-label"
                                                :for="
                                                    'user_permission' + role.id
                                                "
                                                >{{ role.name }}</label
                                            >
                                        </div>
                                        <div v-else></div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label><strong>User Setting</strong></label>
                                    <div
                                        v-for="(
                                            role, index
                                        ) in permission.allPermissions"
                                    >
                                        <div v-if="role.slug == 'User Setting'">
                                            <input
                                                class="form-check-input"
                                                :id="
                                                    'user_permission' + role.id
                                                "
                                                :name="
                                                    'user_permission' + role.id
                                                "
                                                type="checkbox"
                                                :value="role.id"
                                                v-model="
                                                    permission.ModelPermission
                                                "
                                                :key="role.id"
                                                :disabled="
                                                    roles.Assignepermission.includes(
                                                        role.id
                                                    )
                                                "
                                                :checked="
                                                    roles.Assignepermission.includes(
                                                        role.id
                                                    )
                                                "
                                            />
                                            <label
                                                class="form-check-label"
                                                :for="
                                                    'user_permission' + role.id
                                                "
                                                >{{ role.name }}</label
                                            >
                                        </div>
                                        <div v-else></div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong>Center Setting</strong></label
                                    >
                                    <div
                                        v-for="(
                                            role, index
                                        ) in permission.allPermissions"
                                    >
                                        <div
                                            v-if="role.slug == 'Center Setting'"
                                        >
                                            <input
                                                class="form-check-input"
                                                :id="
                                                    'user_permission' + role.id
                                                "
                                                :name="
                                                    'user_permission' + role.id
                                                "
                                                type="checkbox"
                                                :value="role.id"
                                                v-model="
                                                    permission.ModelPermission
                                                "
                                                :key="role.id"
                                                :disabled="
                                                    roles.Assignepermission.includes(
                                                        role.id
                                                    )
                                                "
                                                :checked="
                                                    roles.Assignepermission.includes(
                                                        role.id
                                                    )
                                                "
                                            />
                                            <label
                                                class="form-check-label"
                                                :for="
                                                    'user_permission' + role.id
                                                "
                                                >{{ role.name }}</label
                                            >
                                        </div>
                                        <div v-else></div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong>Ticket Setting</strong></label
                                    >
                                    <div
                                        v-for="(
                                            role, index
                                        ) in permission.allPermissions"
                                    >
                                        <div
                                            v-if="role.slug == 'Ticket Setting'"
                                        >
                                            <input
                                                class="form-check-input"
                                                :id="
                                                    'user_permission' + role.id
                                                "
                                                :name="
                                                    'user_permission' + role.id
                                                "
                                                type="checkbox"
                                                :value="role.id"
                                                v-model="
                                                    permission.ModelPermission
                                                "
                                                :key="role.id"
                                                :disabled="
                                                    roles.Assignepermission.includes(
                                                        role.id
                                                    )
                                                "
                                                :checked="
                                                    roles.Assignepermission.includes(
                                                        role.id
                                                    )
                                                "
                                            />
                                            <label
                                                class="form-check-label"
                                                :for="
                                                    'user_permission' + role.id
                                                "
                                                >{{ role.name }}</label
                                            >
                                        </div>
                                        <div v-else></div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong>Cashier Setting</strong></label
                                    >
                                    <div
                                        v-for="(
                                            role, index
                                        ) in permission.allPermissions"
                                    >
                                        <div
                                            v-if="
                                                role.slug == 'Cashier Setting'
                                            "
                                        >
                                            <input
                                                class="form-check-input"
                                                :id="
                                                    'user_permission' + role.id
                                                "
                                                :name="
                                                    'user_permission' + role.id
                                                "
                                                type="checkbox"
                                                :value="role.id"
                                                v-model="
                                                    permission.ModelPermission
                                                "
                                                :key="role.id"
                                                :disabled="
                                                    roles.Assignepermission.includes(
                                                        role.id
                                                    )
                                                "
                                                :checked="
                                                    roles.Assignepermission.includes(
                                                        role.id
                                                    )
                                                "
                                            />
                                            <label
                                                class="form-check-label"
                                                :for="
                                                    'user_permission' + role.id
                                                "
                                                >{{ role.name }}</label
                                            >
                                        </div>
                                        <div v-else></div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>

                                <div class="col-md-3 text-left">
                                    <!--  Permissions -->
                                    <label
                                        ><strong>Report Setting</strong></label
                                    >
                                    <div
                                        v-for="(
                                            role, index
                                        ) in permission.allPermissions"
                                    >
                                        <div
                                            v-if="role.slug == 'Report Setting'"
                                        >
                                            <input
                                                class="form-check-input"
                                                :id="
                                                    'user_permission' + role.id
                                                "
                                                :name="
                                                    'user_permission' + role.id
                                                "
                                                type="checkbox"
                                                :value="role.id"
                                                v-model="
                                                    permission.ModelPermission
                                                "
                                                :key="role.id"
                                                :disabled="
                                                    roles.Assignepermission.includes(
                                                        role.id
                                                    )
                                                "
                                                :checked="
                                                    roles.Assignepermission.includes(
                                                        role.id
                                                    )
                                                "
                                            />
                                            <label
                                                class="form-check-label"
                                                :for="
                                                    'user_permission' + role.id
                                                "
                                                >{{ role.name }}</label
                                            >
                                        </div>
                                        <div v-else></div>
                                    </div>
                                    <!-- End Permissions -->
                                </div>
                            </div>

                            <div class="row modal-footer border-0">
                                <div class="col-md-3">
                                    <button
                                        type="button"
                                        class="btn-small-width btn-secondary"
                                        @click="RestModelPermission()"
                                    >
                                        Reset Permissions
                                    </button>
                                </div>
                                <div class="col-md-3">
                                    <button
                                        type="submit"
                                        class="btn-small-width btn-success"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!--User's End Model Permission -->

        <div v-show="loading" class="text-center">
            <div
                class="spinner-border spinner-border-lg text-success"
                role="status"
            >
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- User Data Table -->
        <div class="UserData" v-show="!loading">
            <div class="login-content" style="display: block">
                <div class="form-group row align-items-center">
                    <div class="col-md-6">
                        <div
                            class="input-div"
                            :class="{
                                'is-invalid input-div-err focus':
                                    $v.tableData.search.$error || errors.search,
                                ' is-valid focus':
                                    !$v.tableData.search.$invalid,
                            }"
                        >
                            <div class="i">
                                <i class="fas fa-search"></i>
                            </div>
                            <div class="div">
                                <label
                                    for="search"
                                    class="col-md-12 col-form-label text-md-left"
                                    >Search By Name and Employee number</label
                                >
                                <input
                                    type="text"
                                    class="input form-control"
                                    :class="{
                                        'is-invalid focus':
                                            $v.tableData.search.$error ||
                                            errors.search,
                                        ' is-valid focus':
                                            !$v.tableData.search.$invalid,
                                    }"
                                    v-model="tableData.search"
                                    name="search"
                                    id="search"
                                    @input="getUserProfile()"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="control text-left">
                            <div class="select">
                                Show Rows
                                <select
                                    v-model="tableData.length"
                                    @change="getUserProfile()"
                                    style="margin: 10px"
                                    name="rows"
                                    id="rows"
                                >
                                    <option
                                        v-for="(records, index) in perPage"
                                        :key="index"
                                        :value="records"
                                    >
                                        {{ records }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div
                        v-can="'Create New User Profile'"
                        class="col-md-3 align-items-right"
                    >
                        <button
                            type="button"
                            class="btn-small-width btn-success-new"
                            data-toggle="modal"
                            data-target="#NewUserModal"
                            @click="OpenProfile"
                        >
                            <i class="fas fa-user-plus"> </i> Create new user
                        </button>
                    </div>
                </div>
            </div>

            <datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
            >
                <tbody v-for="project in UserData">
                    <tr
                        :key="project.id"
                        v-if="project.status == 0"
                        style="background-color: #fba5a54a"
                    >
                        <td>{{ project.name }}</td>
                        <td>{{ project.email }}</td>
                        <td>{{ project.designation }}</td>
                        <td>{{ project.auth_center.name }}</td>
                        <td>{{ project.empno }}</td>
                        <td>{{ project.contactno }}</td>

                        <td v-if="AuthRole == 'Super Admin'">
                            <i class="fas fa-ban text-danger"></i>
                        </td>

                        <td v-else>
                            <i class="fas fa-ban text-danger"></i>
                        </td>

                        <td>
                            <i class="fas fa-ban text-danger"></i>
                        </td>

                        <td v-can="'User UnBlock'">
                            <a @click="EnabalOrDisabale(project.empno, true)"
                                ><!-- 0 block  -->
                                <i class="fas fa-user-lock text-danger"></i
                            ></a>
                        </td>
                    </tr>
                    <tr :key="project.id + 'a'" v-else>
                        <td>{{ project.name }}</td>
                        <td>{{ project.email }}</td>
                        <td>{{ project.designation }}</td>
                        <td>{{ project.auth_center.name }}</td>
                        <td>{{ project.empno }}</td>
                        <td>{{ project.contactno }}</td>

                        <td v-can="'Add User Permissions'">
                            <a @click="ModelPermission(project.empno)">
                                <i class="fas fa-universal-access text-info"></i
                            ></a>
                        </td>

                        <td v-can="'Edit User Profile'">
                            <a @click="OpenEditUserProfile(project)">
                                <i class="far fa-edit text-warning"></i
                            ></a>
                        </td>

                        <td v-can="'User Block'">
                            <a @click="EnabalOrDisabale(project.empno, false)"
                                ><!-- 1 not block  -->
                                <i class="fas fa-user-check text-success"></i
                            ></a>
                        </td>
                    </tr>
                </tbody>
            </datatable>
            <pagination
                :pagination="pagination"
                @prev="getUserProfile(pagination.prevPageUrl)"
                @next="getUserProfile(pagination.nextPageUrl)"
            >
            </pagination>
        </div>
        <!-- End User table -->
    </div>
</template>

<style scoped>
a:hover {
    cursor: pointer;
}
</style>
<script>
import {
    required,
    minLength,
    maxLength,
    email,
    numeric,
    sameAs,
} from "vuelidate/lib/validators";
import { alphawithspa } from "vuelidate/lib/validators/alphawithspace";

const config = {
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
    },
};
let formData = new FormData();

export default {
    created() {
        this.AuthRole = Role;
        this.AuthWorkingCenter = AuthWorkingCenter;
        this.getUserProfile();
    },
    data() {
        let sortOrders = {};

        let columns = [
            { width: "20%", label: "Name", name: "name" },
            { width: "20%", label: "Email", name: "email" },
            { width: "20%", label: "Role", name: "role" },
            { width: "20%", label: "Working Center", name: "center" },
            { width: "10%", label: "Employee Number", name: "empno" },
            { width: "10%", label: "Contact Number", name: "contactno" },
            { width: "10%", label: "Permission", name: "permission" },
            { width: "10%", label: "Edit", name: "edit" },
            { width: "10%", label: "Block/Un-Block", name: "block" },
        ];

        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });
        return {
            fields: {
                index: "",
                name: "",
                email: "",
                empno: "",
                contactno: "",
                designation: "",
                working_center: "",
                useravatar: [],
                password: "",
                password_confirmation: "",
                ResetPassword: false,
            },

            permission: {
                permissionName: "",
                slug: "",
                selectPermission: "",
                allPermissions: [],
                userID: "",
                ModelPermission: "",
            },

            roles: {
                name: "",
                selectRole: "",
                userPermission: [],
                Assignepermission: [],
            },

            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "desc",
                role: "",
                working_center: "",
            },

            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
            },

            headerClass: "modal-header",
            createUserh5: "Create New User",
            avatar: "/img/avatar.svg",
            picval: "",
            editmode: false,
            RoleEditmode: false,
            permissioneditmode: false,
            loading: false,
            status: "",
            success: false,
            statusText: "",
            errors: {},
            AllRoleData: [],
            AllCenterData: [],
            AuthRole: [],
            AuthWorkingCenter: [],
            UserData: [],
            columns: columns,
            sortKey: "name",
            sortOrders: sortOrders,
            perPage: ["10", "20", "30"],
        };
    },

    validations: {
        fields: {
            name: { required, minLength: minLength(3), alphawithspa },
            email: { required, email, maxLength: maxLength(50) },
            empno: { required, numeric },
            contactno: {
                required,
                numeric,
                maxLength: maxLength(10),
                minLength: minLength(10),
            },
            designation: { required },
            working_center: { required },
            password: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(8),
            },
            password_confirmation: {
                required,
                sameAsPassword: sameAs("password"),
            },
        },

        permission: {
            permissionName: { required, minLength: minLength(3), alphawithspa },
            selectPermission: { required },
            slug: { required },
        },

        roles: {
            name: { required, minLength: minLength(3), alphawithspa },
            selectRole: { required },
        },

        tableData: {
            search: { required },
        },
    },

    methods: {
        //User Profile Section
        CreateUserProfile() {
            formData.append("_method", "POST");
            formData.append("name", this.fields.name);
            formData.append("email", this.fields.email);
            formData.append("empno", this.fields.empno);
            formData.append("contactno", this.fields.contactno);
            formData.append("designation", this.fields.designation);
            formData.append("working_center", this.fields.working_center);
            formData.append("useravatar", this.fields.useravatar);
            formData.append("password", this.fields.password);
            formData.append(
                "password_confirmation",
                this.fields.password_confirmation
            );

            axios
                .post("/create-user-profiles", formData, config)
                .then((response) => {
                    this.CloseModel();
                    Toast.fire({ icon: "success", title: "New User Added" });
                    this.getUserProfile();
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong!",
                    });
                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.createUserh5 =
                            "Create New User - Validation Erros";
                        this.errors = error.response.data.errors;
                    } else {
                        this.createUserh5 =
                            "Create New User - Something went wrong /" +
                            error.response.data.errors.message;
                    }
                });
        },

        updateUserProfile() {
            formData.append("_method", "POST");
            formData.append("name", this.fields.name);
            formData.append("email", this.fields.email);
            formData.append("empno", this.fields.empno);
            formData.append("contactno", this.fields.contactno);
            formData.append("designation", this.fields.designation);
            formData.append("working_center", this.fields.working_center);
            formData.append("useravatar", this.fields.useravatar);
            formData.append("isReset", this.fields.ResetPassword);
            formData.append("password", this.fields.password);
            formData.append(
                "password_confirmation",
                this.fields.password_confirmation
            );

            axios
                .post(
                    "/edit-user-profile/" + this.fields.index,
                    formData,
                    config
                )
                .then((response) => {
                    this.CloseModel();
                    Toast.fire({
                        icon: "success",
                        title: "User Profile Updated",
                    });
                    this.getUserProfile();
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong!",
                    });
                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.createUserh5 = "Edit User - Validation Erros";
                        this.errors = error.response.data.errors;
                    } else {
                        this.createUserh5 =
                            "Edit User - Something went wrong /" +
                            error.response.data.errors.message;
                    }
                });
        },

        getUserProfile(url = "/user-setting/user-profiles") {
            this.tableData.draw++;
            axios
                .get(url, { params: this.tableData })
                .then((response) => {
                    let data = response.data;

                    if (this.tableData.draw == data.draw) {
                        this.UserData = data.data.data;
                        this.configPagination(data.data);
                    }
                })
                .catch((errors) => {
                    if (errors.response.status == 403) {
                        Toast.fire({
                            icon: "error",
                            title: "Forbidden , User does not have the right roles ",
                        });
                    }
                });
        },

        getImage(e) {
            this.fields.useravatar = e.target.files[0];
            let reader = new FileReader();

            if (this.fields.useravatar) {
                if (this.profilePicVal(this.fields.useravatar) == "error") {
                    this.avatar = "/img/imgerr.png";
                } else {
                    reader.readAsDataURL(this.fields.useravatar);
                    reader.onload = (e) => {
                        this.avatar = e.target.result;
                    };
                }
            }
        },

        profilePicVal(pic) {
            if (
                pic.type == "image/png" ||
                pic.type == "image/jpeg" ||
                pic.type == "image/jpg"
            ) {
                this.picval = "";
                if (pic.size < 2111775) {
                    this.picval = "";
                } else {
                    this.picval = "The image maximum size is 2MB.";
                    return "error";
                }
            } else {
                this.picval = "Only PNG,JPEG and JPG are allowed ";
                return "error";
            }
        },

        OpenProfile() {
            $("#NewUserModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });
            this.createUserh5 = "Create New User";
            this.LoadAllRole();
            this.LoadAllCenters();
        },

        OpenEditUserProfile($userData) {
            this.editmode = true;
            $("#NewUserModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });
            this.LoadAllRole();
            this.LoadAllCenters();
            this.createUserh5 = "Edit User Profile ";
            this.fields.index = $userData.id;
            this.fields.name = $userData.name;
            this.fields.email = $userData.email;
            this.fields.empno = $userData.empno;
            this.fields.contactno = $userData.contactno;
            this.fields.designation = $userData.designation;
            this.fields.working_center = $userData.working_center;
            $userData.avatar === null
                ? (this.avatar = "/img/avatar.svg")
                : (this.avatar = "/storage/app/public/UserProfile/" + $userData.avatar);
        },

        CloseModel() {
            $("#NewUserModal").modal("hide");
            this.clearFields();
        },

        //End User Profile Section

        //Role section

        AddOrRemovePermission(e) {
            if (e.target.checked == true) {
                this.roles.userPermission.push(e.target.value);
            } else {
                this.roles.userPermission = _.without(
                    this.roles.userPermission,
                    e.target.value
                );
            }
        },

        createRole() {
            axios
                .post("/create-user-role-permission", {
                    name: this.roles.name,
                    permissions: this.roles.userPermission,
                })
                .then((response) => {
                    this.CloseRoleModel();
                    Toast.fire({
                        icon: "success",
                        title: "New Role Added with Permissions",
                    });
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong! ",
                    });
                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.createUserh5 =
                            "Create New Role - Validation Erros";
                        this.errors = error.response.data.errors;
                    } else {
                        this.createUserh5 =
                            "Create New Role - Something went wrong , " +
                            error.response.data.errors.message;
                    }
                });
        },

        updateRole() {
            console.log(this.roles.userPermission);
            axios
                .post("/update-user-role", {
                    roleID: this.roles.selectRole,
                    name: this.roles.name,
                    permissions: this.roles.userPermission,
                })
                .then((response) => {
                    Toast.fire({ icon: "success", title: "Role Updated" });
                    this.closeEditRoleModel();
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong!",
                    });
                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.createUserh5 =
                            "Edit Permission - Validation Erros";
                        this.errors = error.response.data.errors;
                    } else {
                        this.createUserh5 =
                            "Update Role - Something went wrong , " +
                            error.response.data.errors.message;
                    }
                });
        },

        LoadAllRole() {
            axios
                .get("/user-setting/user-role-data/" + this.AuthRole)
                .then((response) => {
                    this.AllRoleData = response.data.RoleData;
                    console.log(this.AllRoleData);
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong! ",
                    });
                });
        },

        LoadAllCenters() {
            console.log(this.AuthWorkingCenter);
            axios
                .get(
                    "/user-setting/user-center-data/" +
                        this.AuthRole +
                        "/" +
                        this.AuthWorkingCenter
                )
                .then((response) => {
                    console.log(response);
                    this.AllCenterData = response.data.CenterData;
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong! ",
                    });
                });
        },

        getRoleEditabaleData() {
            console.log(this.roles.selectRole);
            axios
                .get("/user-setting/user-role-edit/" + this.roles.selectRole)
                .then((response) => {
                    this.roles.name = response.data.RoleName;
                    this.roles.userPermission =
                        response.data.RolePermissionData;
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong! ",
                    });
                });
        },

        OpenNewRoleModel() {
            $("#NewRoleModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });
            this.createUserh5 = "Add New Role";
            this.getAllPermissions();
        },

        CloseRoleModel() {
            $("#NewRoleModal").modal("hide");
            this.headerClass = "modal-header";
            this.clearRoleFeilds();
        },

        clearRoleFeilds() {
            this.roles.userPermission = [];
            this.roles.name = "";
            this.createUserh5 = "Add New Role";
            this.headerClass = "modal-header";
            this.clearError();
        },

        OpenEditRoleModel() {
            $("#NewRoleModal").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });
            this.RoleEditmode = true;
            this.createUserh5 = "Edit Role";
            this.headerClass = "modal-header";
            this.LoadAllRole();
            this.getAllPermissions();
        },

        closeEditRoleModel() {
            $("#NewRoleModal").modal("hide");
            this.RoleEditmode = false;
            this.clearRoleEditFeilds();
        },

        clearRoleEditFeilds() {
            this.roles.userPermission = [];
            this.roles.selectRole = "";
            this.roles.name = "";
            this.headerClass = "modal-header";
            this.createUserh5 = "Edit Role";
            this.clearError();
        },
        //End Role Section

        //Permission Section
        CreatePermission() {
            formData.append("_method", "POST");
            formData.append("permissionName", this.permission.permissionName);
            formData.append("slug", this.permission.slug);

            axios
                .post("/create-user-permission", formData, config)
                .then((response) => {
                    this.CloseNewPermissionModel();
                    Toast.fire({
                        icon: "success",
                        title: "New Permission Added",
                    });
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong!",
                    });
                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.createUserh5 =
                            "Create New Permission - Validation Erros";
                        this.errors = error.response.data.errors;
                    } else {
                        this.createUserh5 =
                            "Something went wrong ," +
                            error.response.data.errors.message;
                    }
                });
        },

        EditPermission() {
            formData.append("_method", "POST");
            formData.append("permissionName", this.permission.permissionName);
            formData.append(
                "selectPermission",
                this.permission.selectPermission
            );
            formData.append("slug", this.permission.slug);

            axios
                .post("/update-user-permissions", formData, config)
                .then((response) => {
                    Toast.fire({
                        icon: "success",
                        title: "Permission Updated",
                    });
                    this.closeEditPermissionModel();
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong!",
                    });
                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.createUserh5 =
                            "Edit Permission - Validation Erros";
                        this.errors = error.response.data.errors;
                    } else {
                        this.createUserh5 =
                            "Something went wrong ," +
                            error.response.data.errors.message;
                    }
                });
        },

        getAllPermissions() {
            axios
                .get("/user-setting/user-permissions")
                .then((response) => {
                    this.permission.allPermissions =
                        response.data.allPermissions;
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong! ",
                    });
                });
        },

        getUserPermissions(userID) {
            axios
                .get("/user-setting/user-get-permission/" + userID)
                .then((response) => {
                    this.permission.allPermissions =
                        response.data.AllPermissions.allPermissions;
                    this.roles.Assignepermission =
                        response.data.PermissionViaRole;
                    this.permission.ModelPermission =
                        response.data.getDirectPermissions;
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title:
                            "Something went wrong! " +
                            error.response.data.errors.message,
                    });
                });
        },

        getPermissionEditabaleData() {
            axios
                .get(
                    "/user-setting/user-permissions-edit/" +
                        this.permission.selectPermission
                )
                .then((response) => {
                    this.permission.permissionName =
                        response.data.EditabalePermissionData.name;
                    this.permission.slug =
                        response.data.EditabalePermissionData.slug;
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong! ",
                    });
                });
        },

        OpenNewPermissions() {
            $("#NewPermissions").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });
            this.createUserh5 = "Add New Permission";
        },

        OpenEditPermissions() {
            $("#NewPermissions").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });
            this.createUserh5 = "Edit Permission";
            this.getAllPermissions();
            this.permissioneditmode = true;
        },

        CloseNewPermissionModel() {
            $("#NewPermissions").modal("hide");
            this.clearPermissionsFields();
        },

        closeEditPermissionModel() {
            $("#NewPermissions").modal("hide");
            this.permissioneditmode = false;
            this.clearEditPermissionsFields();
        },
        //End Permission Section

        //User Status Section
        EnabalOrDisabale($empno, status) {
            if (status == true) {
                this.statusText = "Un-Block";
            } else {
                this.statusText = "Block";
            }

            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to " + this.statusText + " this User",
                showCancelButton: true,
                confirmButtonColor: "#239B56",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, " + this.statusText + " this !",
            }).then((result) => {
                if (result.isConfirmed) {
                    formData.append("empno", $empno);
                    formData.append("status", status);
                    axios
                        .post("/status-user-profile", formData, config)
                        .then((response) => {
                            Toast.fire({
                                icon: "success",
                                title: "User Status Updated",
                            });
                            this.getUserProfile();
                        })
                        .catch((error) => {
                            Toast.fire({
                                icon: "error",
                                title:
                                    "Something went wrong!" +
                                    error.response.data.errors.message,
                            });
                        });
                }
            });
        },
        //End satatus Section

        //Model Permisiion Section
        ModelPermission($userid) {
            $("#UserModelPermission").modal({
                backdrop: "static",
                keyboard: false,
                show: true,
            });
            this.createUserh5 = "User's Permissions";
            this.permission.userID = $userid;
            this.getUserPermissions($userid);
        },

        CloseModelPermission() {
            $("#UserModelPermission").modal("hide");
            this.headerClass = "modal-header";
            this.permission.allPermissions = [];
            this.clearModelPermission();
        },

        RestModelPermission() {
            this.permission.ModelPermission = [];
            this.getUserPermissions(this.permission.userID);
        },

        AddUserModelPermission() {
            axios
                .post("/update-user-model-permission", {
                    UserID: this.permission.userID,
                    permissions: this.permission.ModelPermission,
                })
                .then((response) => {
                    Toast.fire({
                        icon: "success",
                        title: "User's Permission Updated",
                    });
                    this.CloseModelPermission();
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong!",
                    });
                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.createUserh5 =
                            "Edit Permission - Validation Erros";
                        this.errors = error.response.data.errors;
                    } else {
                        this.createUserh5 =
                            "Edit Permission - Something went wrong /" +
                            error.response.data.errors.message;
                    }
                });
        },
        //End Model Permission Section

        //Data tabale Section
        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

        sortBy(key) {
            this.sortKey = key;
            this.sortOrders[key] = this.sortOrders[key] * -1;
            this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
            this.getUserProfile();
        },

        getIndex(array, key, value) {
            return array.findIndex((i) => i[key] == value);
        },
        //End Data Tabale section

        //Clear Section
        clear(feild) {
            this.$delete(this.errors, feild);
        },

        clearFields() {
            this.fields.name = "";
            this.fields.email = "";
            this.fields.empno = "";
            this.fields.contactno = "";
            this.fields.designation = "";
            this.fields.working_center = "";
            this.fields.useravatar = [];
            this.fields.password = "";
            this.fields.password_confirmation = "";
            this.fields.ResetPassword = false;
            this.createUserh5 = "";
            this.headerClass = "";
            this.editmode = "";
            this.clearError();
            this.avatar = "/img/avatar.svg";
            this.headerClass = "modal-header";
            this.createUserh5 = "Create New User";
            this.$refs.inputFile.value = null;
        },

        clearPermissionsFields() {
            this.permission.permissionName = "";
            this.permission.slug = "";
            this.clearError();
            this.headerClass = "modal-header";
            this.createUserh5 = "Add New Permission";
        },

        clearEditPermissionsFields() {
            this.permission.permissionName = "";
            this.permission.selectPermission = "";
            this.permission.slug = "";
            this.clearError();
            this.headerClass = "modal-header";
            this.createUserh5 = "Edit Permission";
        },

        clearModelPermission() {
            this.permission.userID = "";
            this.createUserh5 = "";
        },

        clearError() {
            this.$v.$reset();
            this.errors = [];
        },
        //End Clear Section
    },
};
</script>
