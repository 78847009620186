<template>
    <div>
        <div class="form-group row original" v-if="originaldata == null">
            <div class="col-md-2">
                <label>Amount</label>
                <h2>RS: {{ OriginalInvoiceAmount }}</h2>
            </div>

            <div class="col-md-3">
                <label>Cash </label>
                <h2>RS: {{ invoicedata.CashAmount }}</h2>
            </div>

            <div class="col-md-2">
                <label>Card </label>
                <h2>RS: {{ invoicedata.CardAmount }}</h2>
            </div>

            <div class="col-md-2">
                <label>Tickets</label>
                <h2>{{ AllTicket }}</h2>
            </div>

            <div class="col-md-2">
                <label>Cash Balance</label>
                <h2>RS: {{ CashBalanace }}</h2>
            </div>
        </div>

        <div class="form-group row original" v-else>
            <div class="col-md-3">
                <label>Amount</label>
                <h2>RS: {{ OriginalInvoiceAmount }}</h2>
            </div>

            <div class="col-md-3">
                <label>Tickets</label>
                <h2>{{ AllTicket }}</h2>
            </div>

            <div class="col-md-3">
                <label>Refund Amount </label>
                <h2>RS: {{ AllRefundAmount }}</h2>
            </div>

            <div class="col-md-3">
                <label>Refund Tickets</label>
                <h2>{{ RefundTicket }}</h2>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-md-2">
                <label>R Ticket</label>
                <h2 class="text-danger" style="font-weight: bold">
                    {{ RefundAllTicket }}
                </h2>
            </div>

            <div class="col-md-3">
                <label>R Balance</label>
                <h2 class="text-danger" style="font-weight: bold">
                    RS: {{ Refundalance }}
                </h2>
            </div>

            <!-- Ticket type -->
            <div
                class="col-md-3 login-content"           
                style="display: block"
            >
                <!-- return_type -->
                    <div
                        class="input-div"
                        :class="{
                            'is-invalid input-div-err focus':
                                $v.Ticketfields.return_type
                                    .$error ||
                                errors.return_type,
                            ' is-valid focus': !$v.Ticketfields
                                .return_type.$invalid
                        }"
                    >
                        <div class="i">
                            <i
                                class="fas fa-clipboard-list"
                            ></i>
                        </div>
                        <div class="div">
                            <select
                                class=" input form-select"
                                aria-label="Default select example"
                                :class="{
                                    'is-invalid':
                                        $v.Ticketfields
                                            .return_type
                                            .$error ||
                                        errors.return_type,
                                    ' is-valid': !$v
                                        .Ticketfields
                                        .return_type.$invalid
                                }"
                                v-model="
                                    Ticketfields.return_type
                                "
                                name="return_type"
                                id="return_type"
                                required
                                @blur="
                                    $v.Ticketfields.return_type.$touch()
                                "
                                @click="clear('return_type')"
                            >
                                <option
                                    selected
                                    disabled
                                    value=""
                                    >Select Ticket Return Type</option
                                >
                                <option value="Cash"
                                    >Cash</option
                                >

                                <option value="Card"
                                    >Card</option
                                >                              
                               
                            </select>
                        </div>
                    </div>

                    <span
                        class="invalid-feedback"
                        v-if="
                            $v.Ticketfields.return_type.$error
                        "
                    >
                        <p
                            v-if="
                                !$v.Ticketfields.return_type
                                    .required
                            "
                        >
                            Ticket Return Type is required.
                        </p>
                    </span>

                    <span
                        v-if="errors && errors.return_type"
                        class="invalid-feedback"
                    >
                        {{ errors.return_type[0] }}
                    </span>
                <!-- End return_type -->
            </div>
            <!-- End Ticket type -->
            
            <!-- Ticket qty -->
            <div
                class="col-md-3 login-content"
                v-show="showTicketQTY"
                style="display: block"
            >
                <div class="form-group row" style="margin-bottom: 0px">
                    <!-- Ticket quntyty -->
                    <div
                        class="input-div"
                        :class="{
                            'is-invalid input-div-err focus':
                                $v.Ticketfields.quty.$error || errors.quty,
                            ' is-valid focus': !$v.Ticketfields.quty.$invalid,
                        }"
                    >
                        <div class="i">
                            <i class="fas fa-ticket-alt"></i>
                        </div>
                        <div class="div">
                            <label
                                for="quty"
                                class="col-md-12 col-form-label text-md-left"
                                >Ticket Quantity</label
                            >
                            <input
                                type="number"
                                min="0"
                                pattern="[0-9]*"
                                class="input form-control"
                                :class="{
                                    'is-invalid focus':
                                        $v.Ticketfields.quty.$error ||
                                        errors.quty,
                                    ' is-valid focus':
                                        !$v.Ticketfields.quty.$invalid,
                                }"
                                v-model="Ticketfields.quty"
                                name="quty"
                                id="quty"
                                v-on:keyup.enter="addTicket"
                                @input="$v.Ticketfields.quty.$touch()"
                                @blur="$v.Ticketfields.quty.$touch()"
                                ref="quty"
                            />
                        </div>
                    </div>

                    <span
                        class="invalid-feedback"
                        v-if="$v.Ticketfields.quty.$error"
                    >
                        <p v-if="!$v.Ticketfields.quty.required">
                            The Ticket Quantity field is required.
                        </p>
                        <p v-if="!$v.Ticketfields.quty.numeric">
                            The Ticket Quantity format is only numbers.
                        </p>
                        <p v-if="!$v.Ticketfields.quty.quty">
                            The Ticket Quantity not be negative and grater than
                            0
                        </p>
                    </span>

                    <span v-if="errors && errors.quty" class="invalid-feedback">
                        {{ errors.quty[0] }}
                    </span>
                    <!-- End Ticket quantity -->
                </div>
            </div>
            <!-- End Ticket qty -->          

        </div>

        <div>
            <!-- Ticket Item Table -->
            <table class="table table-hover" id="ticket-list">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">SKU</th>
                        <th scope="col">Type</th>
                        <th scope="col">Ticket price</th>
                        <th scope="col">Ticket quantity</th>
                        <th scope="col">Price</th>
                        <th scope="col">R Ticket</th>
                        <th scope="col">R Price</th>
                        <th scope="col">Remove</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in ticketItemList" ref="tabale">
                        <th scope="row">{{ index }}</th>
                        <th scope="row" :class="[item.sku == 'G' ? 'G_class' : item.sku == 'SC'? 'SC_class' : item.sku == 'C' ? 'C_class' :item.sku == 'T' ? 'T_class' :  item.sku == 'TC'? 'TC_class' :'FS_class'  ] " >{{ item.sku }}</th>

                        <td>{{ item.type }}</td>
                        <td>{{ item.ticketprice }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.price.toFixed(2) }}</td>
                        <td>
                            {{ item.rquantity == null ? 0 : item.rquantity }}
                        </td>
                        <td>
                            {{
                                item.rprice == null ? 0 : item.rprice.toFixed(2)
                            }}
                        </td>

                        <td v-if="item.isrefunded !='refunded' && !enabaleRefund">
                            <a @click="RemoveTicekt(index)"
                                ><!-- 0 block  -->
                                <i class="fas fa-minus-circle text-danger"></i
                            ></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Ticket Item Table -->

            <!-- original Ticket Item Table -->
            <div v-if="originaldata">
                <p style="font-weight: bold;padding-bottom:5px">Original Invoice</p>
                <table class="table table-hover" id="ticket-list">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Type</th>
                            <th scope="col">Ticket quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in originaldata.ticket"
                            ref="tabale"
                        >
                            <th scope="row">{{ index }}</th>
                            <td>{{ item.type }}</td>
                            <td>{{ item.quantity }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- original Ticket Item Table -->

            <!-- Payment Section -->
            <div class="container">
                <div class="row" v-if="enabaleRefund">
                    <button
                        type="btn"
                        :class="btnClass"
                        id="issueBtn"
                        :disabled="disabaleRefundBtn"
                        v-if="enabaleRefund"
                        @click="Issue()"
                    >
                        Refund This Ticket
                    </button>

                    <div class="row text-center">
                        <span v-for="(error, sec) in errors">
                            <p class="text-danger">{{ error }}</p>
                        </span>
                    </div>
                </div>

                <div v-if="RefundAllTicket > 0">
                    <div
                        class="row cards"
                        v-if="!enabaleRefund"
                        style="margin-top: 20px"
                    >
                        <div class="col-md-12">
                            <form @submit.prevent="getPermission()">
                                <!-- Authorization -->
                                <div class="row login-content">
                                    <div class="col-md-6">
                                        <!-- Ticket quntyty -->
                                        <div
                                            class="input-div"
                                            :class="{
                                                'is-invalid input-div-err focus':
                                                    $v.Authorization.empID
                                                        .$error || errors.empID,
                                                ' is-valid focus':
                                                    !$v.Authorization.empID
                                                        .$invalid,
                                            }"
                                        >
                                            <div class="i">
                                                <i
                                                    class="fas fa-ticket-alt"
                                                ></i>
                                            </div>
                                            <div class="div">
                                                <label
                                                    for="empID"
                                                    class="col-md-12 col-form-label text-md-left"
                                                    >Officer Employee ID</label
                                                >
                                                <input
                                                    type="text"
                                                    class="input form-control"
                                                    :class="{
                                                        'is-invalid focus':
                                                            $v.Authorization
                                                                .empID.$error ||
                                                            errors.empID,
                                                        ' is-valid focus':
                                                            !$v.Authorization
                                                                .empID.$invalid,
                                                    }"
                                                    v-model="
                                                        Authorization.empID
                                                    "
                                                    name="empID"
                                                    id="empID"
                                                    @input="
                                                        $v.Authorization.empID.$touch()
                                                    "
                                                    @blur="
                                                        $v.Authorization.empID.$touch()
                                                    "
                                                    ref="empID"
                                                />
                                            </div>
                                        </div>

                                        <span
                                            class="invalid-feedback"
                                            v-if="$v.Authorization.empID.$error"
                                        >
                                            <p
                                                v-if="
                                                    !$v.Authorization.empID
                                                        .required
                                                "
                                            >
                                                The Officer Employee ID field is
                                                required.
                                            </p>
                                        </span>

                                        <!-- End Ticket quantity -->
                                    </div>

                                    <div class="col-md-6">
                                        <!-- Ticket quantity -->
                                        <div
                                            class="input-div"
                                            :class="{
                                                'is-invalid input-div-err focus':
                                                    $v.Authorization.empPassword
                                                        .$error ||
                                                    errors.empPassword,
                                                ' is-valid focus':
                                                    !$v.Authorization
                                                        .empPassword.$invalid,
                                            }"
                                        >
                                            <div class="i">
                                                <i class="fas fa-lock"></i>
                                            </div>
                                            <div class="div">
                                                <label
                                                    for="empPassword"
                                                    class="col-md-12 col-form-label text-md-left"
                                                    >Officer Employee
                                                    Password</label
                                                >
                                                <input
                                                    type="password"
                                                    class="input form-control"
                                                    :class="{
                                                        'is-invalid focus':
                                                            $v.Authorization
                                                                .empPassword
                                                                .$error ||
                                                            errors.empPassword,
                                                        ' is-valid focus':
                                                            !$v.Authorization
                                                                .empPassword
                                                                .$invalid,
                                                    }"
                                                    v-model="
                                                        Authorization.empPassword
                                                    "
                                                    name="empPassword"
                                                    id="empPassword"
                                                    @input="
                                                        $v.Authorization.empPassword.$touch()
                                                    "
                                                    @blur="
                                                        $v.Authorization.empPassword.$touch()
                                                    "
                                                    ref="empPassword"
                                                />
                                            </div>
                                        </div>

                                        <span
                                            class="invalid-feedback"
                                            v-if="
                                                $v.Authorization.empPassword
                                                    .$error
                                            "
                                        >
                                            <p
                                                v-if="
                                                    !$v.Authorization
                                                        .empPassword.required
                                                "
                                            >
                                                The Officer Employee Password
                                                field is required.
                                            </p>
                                        </span>

                                        <!-- End Ticket quantity -->
                                    </div>
                                </div>
                                <!-- Authorization -->

                                <div class="row text-center">
                                    <span v-for="(error, sec) in errors">
                                        <p class="text-danger">{{ error }}</p>
                                    </span>
                                </div>

                                <button
                                    type="submit"
                                    class="btn-small-width btn-invoice btn-danger"
                                    id="permissionBtn"
                                    :disabled="disabledStatus"
                                    v-on:keyup.enter="getPermission()"
                                >
                                    Get Permission
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Payment Section -->
        </div>
    </div>
</template>

<style scoped>
form {
    width: 100%;
}

p {
    font-size: 15px;
    margin: 0px;
}

.card-single {
    padding: 1rem;
    background-color: #e8daef;
}

th,
td {
    border: 1px solid black;
}

.original h2,
label {
    color: #8e44ad;
    font-weight: bold;
}

.btn-invoice-background-err {
    background-color: #cb4335;
}

.btn-invoice-background-refund-start {
    background-color: #239b56;
}
</style>

<script>
import { required, numeric, decimal, helpers } from "vuelidate/lib/validators";
const quty = helpers.regex("nameVal", /^[1-9]\d*$/);
const q = helpers.regex("nameVal", /^(0|[1-9]\d*)$/);
const config = {
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
    },
};
let formData = new FormData();

export default {
    mounted() {
        window.app = this;
    },

    created() {
        this.ticketItemList = this.invoiceticket;
        if (this.originaldata == null) {
            this.InvoiceBalance = this.invoicedata.paymenttype;
            this.CashBalanace = this.invoicedata.CashBalanace;
            this.InvoiceAmount = this.invoicedata.amount;
            this.OriginalInvoiceAmount = this.invoicedata.amount;
            this.AllTicket = this.invoicedata.tickets;
        } else {
            this.OriginalInvoiceAmount = this.originaldata.amount;
            this.AllTicket = this.originaldata.tickets;
            this.AllRefundAmount = this.ramount;
            this.RefundTicket = this.rticket;
        }       

        window.addEventListener("keydown", (e) => {
            if (e.key == "w" || e.key == "W") {
                this.$refs.quty.focus();
            } else if (e.key == "v" || e.key == "V") {
                this.TabaleRowEdit();
            } else {
            }
        });
    },
    props: [
        "invoiceticket",
        "invoicedata",
        "originaldata",
        "ramount",
        "rticket",       
    ],

    data() {
        return {
           
            Ticketfields: {
                quty: "",
                return_type:""
            },

            Authorization: {
                empID: "",
                empPassword: "",
            },

            ticketItemList: [
                {
                    type: "",
                    ticketprice: "",
                    price: "",
                    quantity: "",
                    rprice: 0,
                    rquantity: 0,
                },
            ],

            rticketItemList: [
                {
                    rprice: 0,
                    rquantity: 0,
                },
            ],

            InvoiceBalance: 0,
            CashBalanace: 0,
            RefundCashBalanace: 0,
            Refundalance: 0,
            OriginalInvoiceAmount: 0,
            cardcash: "",
            // CardNumber:'',
            // CardBank:'',
            // CardType:'',
            cashdisabled: 0,
            disabled: 1,
            carddisabled: 0,
            enabaleRefund: false,
            showTicketQTY: false,
            disabledStatus: false,
            disabaleRefundBtn: true,
            AllRefundAmount: 0,
            btnClass: "btn-small-width btn-invoice",
            RefundRemove: [],          
            InvoiceAmount: 0,
            AllTicket: 0,
            RefundTicket: 0,
            RefundAllTicket: 0,
            errors: {},
            EditQytIndex: "",
        };
    },

    validations: {
        Ticketfields: {
            quty: { required, numeric, quty },
            return_type:{required}
        },
        Authorization: {
            empID: { required },
            empPassword: { required },
        },

        CashBalanace: { required, decimal },
        CashAmount: { required, decimal },
        CardAmount: { required, decimal },
        //    CardNumber:{required,numeric,maxLength:maxLength(4),minLength: minLength(4)},
        //    CardBank:{required},
        //    CardType:{required},

        ticketItemList: {
            type: { required },
        },
    },

    methods: {
        TabaleRowEdit() {
            var rows =
                document.getElementById("ticket-list").children[1].children;
            var selectedRow = 0;
            document.onkeydown = function (e) {
                //Clear out old row's color
                rows[selectedRow].style.backgroundColor = "#FFFFFF";
                //Calculate new row
                if (e.key == "ArrowUp") {
                    window.app.showTicketQTY = false;
                    selectedRow--;
                } else if (e.key == "ArrowDown") {
                    window.app.showTicketQTY = false;
                    selectedRow++;
                } else if (e.key == "k" || e.key == "K" && window.app.disabaleRefundBtn) {
                    window.app.RemoveTicekt(selectedRow);
                } else if (e.key == "l" || e.key == "L" && window.app.disabaleRefundBtn) {
                    window.app.showTicketQTY = true;
                    window.app.EditTicketQuty(selectedRow);
                }

                if (selectedRow >= rows.length) {
                    selectedRow = 0;
                } else if (selectedRow < 0) {
                    selectedRow = rows.length - 1;
                }
                //Set new row's color
                rows[selectedRow].style.backgroundColor = "#8888FF";
            };

            rows[0].style.backgroundColor = "#81C0F7";
        },      

        addTicket() {
            if (Number(this.Ticketfields.quty) > 0) {
                if (this.Ticketfields.quty.match(/^(0|[1-9]\d*)$/) == null) {
                } else {
                    if (this.originaldata == null) {
                        //fresh invoice with no any refund
                        if (
                            this.ticketItemList[this.EditQytIndex].quantity >=
                            Number(this.Ticketfields.quty)
                        ) {
                            //check refund ticket quantity not be grater than
                            const data =
                                Number(
                                    this.ticketItemList[this.EditQytIndex]
                                        .rquantity
                                ) + Number(this.Ticketfields.quty);

                            if (
                                data >
                                Number(
                                    this.ticketItemList[this.EditQytIndex]
                                        .quantity
                                )
                            ) {
                                Toast.fire({
                                    icon: "error",
                                    title: "Refund ticket quantity is higher than original quantity (r+q)",
                                });
                            } else if (
                                this.ticketItemList[this.EditQytIndex]
                                    .quantity == Number(this.Ticketfields.quty)
                            ) {
                                //if equla remove
                                this.rticketItemList.push({
                                    rquantity: this.Ticketfields.quty,
                                    rprice:
                                        this.Ticketfields.quty *
                                        this.ticketItemList[this.EditQytIndex]
                                            .ticketprice,
                                });

                                this.ticketItemList[
                                    this.EditQytIndex
                                ].rquantity = this.Ticketfields.quty;
                                this.ticketItemList[this.EditQytIndex].rprice =
                                this.Ticketfields.quty *
                                this.ticketItemList[this.EditQytIndex]
                                        .ticketprice;
                                this.ticketItemList[this.EditQytIndex].isrefunded ="refunded"                              

                                this.TicketData();
                                this.$refs.quty.blur();
                                this.clearfeild();
                            } else if (
                                this.ticketItemList[this.EditQytIndex]
                                    .rquantity > 0
                            ) {
                                // if have refund quantity still
                                const data =
                                    Number(
                                        this.ticketItemList[this.EditQytIndex]
                                            .rquantity
                                    ) + Number(this.Ticketfields.quty);
                             
                                if (
                                    data >
                                    Number(
                                        this.ticketItemList[this.EditQytIndex]
                                            .quantity
                                    )
                                ) {
                                    Toast.fire({
                                        icon: "error",
                                        title: "Refund quantity exceed please check ticket quantity",
                                    });
                                } else {
                                    this.rticketItemList.push({
                                        rquantity: this.Ticketfields.quty,
                                        rprice:
                                            this.Ticketfields.quty *
                                            this.ticketItemList[
                                                this.EditQytIndex
                                            ].ticketprice,
                                    });

                                    this.ticketItemList[
                                        this.EditQytIndex
                                    ].rquantity =
                                        Number(this.Ticketfields.quty) +
                                        Number(
                                            this.ticketItemList[
                                                this.EditQytIndex
                                            ].rquantity
                                        );
                                    this.ticketItemList[
                                        this.EditQytIndex
                                    ].rprice =
                                        Number(
                                            this.ticketItemList[
                                                this.EditQytIndex
                                            ].rquantity
                                        ) *
                                        Number(
                                            this.ticketItemList[
                                                this.EditQytIndex
                                            ].ticketprice
                                        );

                                    this.TicketData();
                                    this.$refs.quty.blur();
                                    this.clearfeild();

                                    if (
                                        this.ticketItemList[this.EditQytIndex]
                                            .rquantity ==
                                        this.ticketItemList[this.EditQytIndex]
                                            .quantity
                                    ) {
                                        this.rticketItemList.push({
                                            rquantity: this.Ticketfields.quty,
                                            rprice:
                                                this.Ticketfields.quty *
                                                this.ticketItemList[
                                                    this.EditQytIndex
                                                ].ticketprice,
                                        });

                                        this.ticketItemList[
                                            this.EditQytIndex
                                        ].rquantity =
                                            Number(this.Ticketfields.quty) +
                                            Number(
                                                this.ticketItemList[
                                                    this.EditQytIndex
                                                ].rquantity
                                            );
                                        this.ticketItemList[
                                            this.EditQytIndex
                                        ].rprice =
                                            Number(
                                                this.ticketItemList[
                                                    this.EditQytIndex
                                                ].rquantity
                                            ) *
                                            Number(
                                                this.ticketItemList[
                                                    this.EditQytIndex
                                                ].ticketprice
                                            );
                                        this.ticketItemList[this.EditQytIndex].isrefunded ="refunded"
                                        this.TicketData();
                                        this.$refs.quty.blur();
                                        this.clearfeild();
                                    }
                                }
                            } else {
                                //if no any refund quantity still
                                this.rticketItemList.push({
                                    rquantity: this.Ticketfields.quty,
                                    rprice:
                                        this.Ticketfields.quty *
                                        this.ticketItemList[this.EditQytIndex]
                                            .ticketprice,
                                });

                                this.ticketItemList[
                                    this.EditQytIndex
                                ].rquantity = this.Ticketfields.quty;
                                this.ticketItemList[this.EditQytIndex].rprice =
                                this.Ticketfields.quty *
                                this.ticketItemList[this.EditQytIndex]
                                        .ticketprice;
                                this.TicketData();
                                this.$refs.quty.blur();
                                this.clearfeild();
                            }
                        } else {
                            Toast.fire({
                                icon: "error",
                                title: "Refund ticket quantity is higher than original quantity",
                            });
                        }
                    } else {
                        //not null
                        this.ticketItemList[this.EditQytIndex].rquantity =
                            this.ticketItemList[this.EditQytIndex].rquantity ==
                            null
                                ? 0
                                : this.ticketItemList[this.EditQytIndex]
                                      .rquantity;
                        const data =
                            Number(
                                this.ticketItemList[this.EditQytIndex].rquantity
                            ) + Number(this.Ticketfields.quty);

                        if (
                            this.ticketItemList[this.EditQytIndex].isrefunded =='refunded'
                        ) {
                            Toast.fire({
                                icon: "error",
                                title: "Cannot refund",
                            });
                        } else if (
                            data >
                            Number(
                                this.ticketItemList[this.EditQytIndex].quantity
                            )
                        ) {
                            Toast.fire({
                                icon: "error",
                                title: "Refund ticket quantity is higher than original quantity (r+q)",
                            });
                        } else if (
                            this.ticketItemList[this.EditQytIndex].quantity ==
                            data
                        ) {
                            // if refund+quantity == original
                            this.rticketItemList.push({
                                rquantity: this.Ticketfields.quty,
                                rprice:
                                    this.Ticketfields.quty *
                                    this.ticketItemList[this.EditQytIndex]
                                        .ticketprice,
                            });

                            this.ticketItemList[this.EditQytIndex].rquantity =
                                Number(this.Ticketfields.quty) +
                                Number(
                                    this.ticketItemList[this.EditQytIndex]
                                        .rquantity
                                );
                            this.ticketItemList[this.EditQytIndex].rprice =
                                Number(
                                    this.ticketItemList[this.EditQytIndex]
                                        .rquantity
                                ) *
                                Number(
                                    this.ticketItemList[this.EditQytIndex]
                                        .ticketprice
                                );

                            this.ticketItemList[this.EditQytIndex].isrefunded ="refunded"
                            this.TicketData();
                            this.$refs.quty.blur();
                            this.clearfeild();
                        } else {
                            this.rticketItemList.push({
                                rquantity: this.Ticketfields.quty,
                                rprice:
                                    this.Ticketfields.quty *
                                    this.ticketItemList[this.EditQytIndex]
                                        .ticketprice,
                            });

                            this.ticketItemList[this.EditQytIndex].rquantity =
                                Number(this.Ticketfields.quty) +
                                Number(
                                    this.ticketItemList[this.EditQytIndex]
                                        .rquantity
                                );
                            this.ticketItemList[this.EditQytIndex].rprice =
                                Number(
                                    this.ticketItemList[this.EditQytIndex]
                                        .rquantity
                                ) *
                                this.ticketItemList[this.EditQytIndex]
                                    .ticketprice;
                            this.ticketItemList[this.EditQytIndex].quantity =
                                this.ticketItemList[this.EditQytIndex].quantity;
                            this.ticketItemList[this.EditQytIndex].type =
                                this.ticketItemList[this.EditQytIndex].type;
                            this.ticketItemList[this.EditQytIndex].ticketprice =
                            this.ticketItemList[
                                    this.EditQytIndex
                                ].ticketprice;

                            this.TicketData();
                            this.$refs.quty.blur();
                            this.clearfeild();
                        }
                    }
                }
            }
        },

        getPermission() {
            this.disabledStatus = true;
            var spinner =
                '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div> Getting Permission...';
            $("#permissionBtn").html(spinner);

            this.errors = [];
            formData.append("empID", this.Authorization.empID);
            formData.append("empPassword", this.Authorization.empPassword);
            formData.append("return_type", this.Ticketfields.return_type);        

            axios
                .post("/cashier/request-refund", formData, config)
                .then((response) => {
                    this.showTicketQTY = false;
                    if (response.data.data == true) {
                        this.enabaleRefund = response.data.data;
                        Toast.fire({
                            icon: "success",
                            title: "Enable To refund",
                        });
                        this.disabaleRefundBtn = false;
                    } else {
                        this.disabledStatus = false;

                        var spinner =
                            '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div> Getting Permission Failed Try Again ...';
                        $("#permissionBtn").html(spinner);
                        Toast.fire({
                            icon: "error",
                            title: "Password Not Match",
                        });
                    }
                })
                .catch((error) => {
                    this.disabledStatus = false;
                    var spinner =
                        '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div> Getting Permission Failed Try Again ...';
                    $("#permissionBtn").html(spinner);
                    if (error.response.status == 422) {
                        this.errors = error.response.data.errors;
                        Toast.fire({
                            icon: "error",
                            title: error.response.data.errors.message,
                        });
                    } else if (error.response.status == 500) {
                        Toast.fire({
                            icon: "error",
                            title: error.response.data.errors.message,
                        });
                    } else {
                    }
                });
        },

        Issue() {          
            this.disabaleRefundBtn = true;
            var spinner =
                '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span> | </div> Refunding ...';
            $("#issueBtn").html(spinner);

            if (this.RefundAllTicket == 0) {
                this.btnClass =
                    "btn-small-width btn-invoice btn-invoice-background-err";
                Toast.fire({
                    icon: "error",
                    title: "Cannot Refund, No Any Tickets",
                });
                $("#issueBtn").text("Cannot Refund, No Any Tickets");
            } else {
                formData.append(
                    "ticket",
                    this.ticketItemList.length == 0
                        ? null
                        : JSON.stringify(this.ticketItemList)
                );
                formData.append("invoice_id", this.invoicedata.invoice_id);
                formData.append("ramount", this.Refundalance);
                formData.append("rtickets", this.RefundAllTicket);
                formData.append("refund_type", this.Ticketfields.return_type);

                axios
                    .post("/cashier/create-refund", formData, config)
                    .then((response) => {
                        window.location.href = "/cashier/cashier-summary";
                    })
                    .catch((error) => {
                        this.disabaleRefundBtn = false;
                        this.btnClass =
                            "btn-small-width btn-invoice btn-invoice-background-err";
                        $("#issueBtn").text("Error");

                        if (error.response.status == 422) {
                            this.errors = error.response.data.errors;
                        } else if (
                            error.response.data.errors.message == "drawer error"
                        ) {
                            Toast.fire({
                                icon: "error",
                                title: "Cash Drawer Error",
                                text: "your cash drawer balance is not sufficient for process this refund",
                            });
                        } else if (error.response.status == 500) {
                            Toast.fire({
                                icon: "error",
                                title: error.response.data.errors.message,
                            });
                        } else {
                        }
                    });
            }
        },

        EditTicketQuty(index) {
            this.EditQytIndex = index;
        },

        RemoveTicekt(index) {
            if (this.ticketItemList[index].rquantity > 0) {
                console.log("hi");
                this.rticketItemList.push({
                    rquantity:
                        this.ticketItemList[index].quantity -
                        this.ticketItemList[index].rquantity,
                    rprice:
                        (this.ticketItemList[index].quantity -
                            this.ticketItemList[index].rquantity) *
                        Number(this.ticketItemList[index].ticketprice),
                });

                this.ticketItemList[index].rquantity =
                    this.ticketItemList[index].quantity;
                this.ticketItemList[index].rprice =
                    this.ticketItemList[index].quantity *
                    Number(this.ticketItemList[index].ticketprice);
                this.ticketItemList[index].isrefunded ="refunded"
                console.log("remove when prvous refund");
                    
            } else {
                console.log("hi sfd");

                this.ticketItemList[index].rquantity =
                    this.ticketItemList[index].quantity - 0;
                this.ticketItemList[index].rprice =
                    (this.ticketItemList[index].quantity - 0) *
                    Number(this.ticketItemList[index].ticketprice);
                this.ticketItemList[index].isrefunded ="refunded"
                console.log("remove");

                this.rticketItemList.push({
                    rquantity: this.ticketItemList[index].quantity - 0,
                    rprice:
                        (this.ticketItemList[index].quantity - 0) *
                        Number(this.ticketItemList[index].ticketprice),
                });
            }         
             this.TicketData();
        },

        TicketData() {
            this.Refundalance = this.rticketItemList
                .reduce((acc, item) => {
                    return acc + Number(item.rprice);
                }, 0)
                .toFixed(2);

            this.RefundAllTicket = this.rticketItemList.reduce((add, itemm) => {
                return add + Number(itemm.rquantity);
            }, 0);
        },

        clearfeild() {
            this.Ticketfields.quty = "";
            this.$v.$reset();
        },
    },
};
</script>
