<template>
    <div>
        <!-- Creat New Ticket -->
        <div
            class="modal fade "
            id="NewTicetModel"
            tabindex="-1"
            role="dialog"
            aria-labelledby="NewTicetModelLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-md " role="document">
                <div class="modal-content">
                    <div :class="headerClass">
                        <h5
                            class="modal-title"
                            v-show="!editmode"
                            id="NewTicetModelLabel"
                        >
                            {{ ticektHeading }}
                        </h5>
                        <h5
                            class="modal-title"
                            v-show="editmode"
                            id="NewTicetModelLabel"
                        >
                            {{ ticektHeading }}
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            @click="CloseModel"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body login-content">
                        <form
                            class="full-with-form"
                            @submit.prevent="
                                editmode ? EditTicket() : CreateTicekt()
                            "
                        >
                            <div class="form-group row" style="padding:25px">
                                <div class=" col-md-12">
                                    <!-- Ticket Type -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.Ticketfields
                                                    .ticket_display_name
                                                    .$error ||
                                                errors.ticket_display_name,
                                            ' is-valid focus': !$v.Ticketfields
                                                .ticket_display_name.$invalid
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-ticket-alt"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="ticket_display_name"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Ticket Display Name</label
                                            >
                                            <input
                                                type="text"
                                                class=" input form-control"
                                                :class="{
                                                    'is-invalid focus':
                                                        $v.Ticketfields
                                                            .ticket_display_name
                                                            .$error ||
                                                        errors.ticket_display_name,
                                                    ' is-valid focus': !$v
                                                        .Ticketfields
                                                        .ticket_display_name
                                                        .$invalid
                                                }"
                                                v-model="
                                                    Ticketfields.ticket_display_name
                                                "
                                                name="ticket_display_name"
                                                id="ticket_display_name"
                                                @input="
                                                    $v.Ticketfields.ticket_display_name.$touch()
                                                "
                                                @blur="
                                                    $v.Ticketfields.ticket_display_name.$touch()
                                                "
                                                @click="
                                                    clear('ticket_display_name')
                                                "
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="
                                            $v.Ticketfields.ticket_display_name
                                                .$error
                                        "
                                    >
                                        <p
                                            v-if="
                                                !$v.Ticketfields
                                                    .ticket_display_name
                                                    .required
                                            "
                                        >
                                            The Ticket Display Name field is
                                            required.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.Ticketfields
                                                    .ticket_display_name
                                                    .alphawithspa
                                            "
                                        >
                                            The Ticket Display Name format is
                                            only contain letters and spaces.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.Ticketfields
                                                    .ticket_display_name
                                                    .minLength
                                            "
                                        >
                                            The Ticket Display Name must be at
                                            least 3 characters.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.Ticketfields
                                                    .ticket_display_name
                                                    .maxLength
                                            "
                                        >
                                            The Ticket Display Name must not be
                                            greater than 50 characters.
                                        </p>
                                    </span>

                                    <span
                                        v-if="
                                            errors && errors.ticket_display_name
                                        "
                                        class="invalid-feedback"
                                    >
                                        {{ errors.ticket_display_name[0] }}
                                    </span>
                                    <!-- End Ticket Type -->

                                    <!-- ticket_type -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.Ticketfields.ticket_type
                                                    .$error ||
                                                errors.ticket_type,
                                            ' is-valid focus': !$v.Ticketfields
                                                .ticket_type.$invalid
                                        }"
                                    >
                                        <div class="i">
                                            <i
                                                class="fas fa-clipboard-list"
                                            ></i>
                                        </div>
                                        <div class="div">
                                            <select
                                                class=" input form-select"
                                                aria-label="Default select example"
                                                :class="{
                                                    'is-invalid':
                                                        $v.Ticketfields
                                                            .ticket_type
                                                            .$error ||
                                                        errors.ticket_type,
                                                    ' is-valid': !$v
                                                        .Ticketfields
                                                        .ticket_type.$invalid
                                                }"
                                                v-model="
                                                    Ticketfields.ticket_type
                                                "
                                                name="ticket_type"
                                                id="ticket_type"
                                                required
                                                @blur="
                                                    $v.Ticketfields.ticket_type.$touch()
                                                "
                                                @click="clear('ticket_type')"
                                            >
                                                <option
                                                    selected
                                                    disabled
                                                    value=""
                                                    >Select Ticket Type</option
                                                >
                                                <option value="Gate Tickets"
                                                    >Gate Ticket</option
                                                >

                                                <option value="Boat Tickets"
                                                    >Boat Ticket</option
                                                >
                                                <option
                                                    value="Golf Cart Tickets"
                                                    >Golf Cart Ticket</option
                                                >
                                                <option
                                                    value="Wedding Photography Tickets"
                                                    >Wedding Photography
                                                    Tickets</option
                                                >
                                            </select>
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="
                                            $v.Ticketfields.ticket_type.$error
                                        "
                                    >
                                        <p
                                            v-if="
                                                !$v.Ticketfields.ticket_type
                                                    .required
                                            "
                                        >
                                            The Ticket Type field is required.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.ticket_type"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.ticket_type[0] }}
                                    </span>
                                    <!-- End ticket_type -->

                                    <!-- Ticket Citizenship -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.Ticketfields
                                                    .ticket_citizenship
                                                    .$error ||
                                                errors.ticket_citizenship,
                                            ' is-valid focus': !$v.Ticketfields
                                                .ticket_citizenship.$invalid
                                        }"
                                    >
                                        <div class="i">
                                            <i
                                                class="fas fa-clipboard-list"
                                            ></i>
                                        </div>
                                        <div class="div">
                                            <select
                                                class=" input form-select"
                                                aria-label="Default select example"
                                                :class="{
                                                    'is-invalid':
                                                        $v.Ticketfields
                                                            .ticket_citizenship
                                                            .$error ||
                                                        errors.ticket_citizenship,
                                                    ' is-valid': !$v
                                                        .Ticketfields
                                                        .ticket_citizenship
                                                        .$invalid
                                                }"
                                                v-model="
                                                    Ticketfields.ticket_citizenship
                                                "
                                                name="ticket_citizenship"
                                                id="ticket_citizenship"
                                                required
                                                @blur="
                                                    $v.Ticketfields.ticket_citizenship.$touch()
                                                "
                                                @click="
                                                    clear('ticket_citizenship')
                                                "
                                            >
                                                <option
                                                    selected
                                                    disabled
                                                    value=""
                                                    >Citizenship
                                                </option>
                                                <option
                                                    value="Citizen of Sri Lanka"
                                                    >Citizen of Sri Lanka
                                                </option>
                                                <option
                                                    value="Non-citizen of Sri Lanka"
                                                >
                                                    Non-citizen of Sri
                                                    Lanka</option
                                                >
                                            </select>
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="
                                            $v.Ticketfields.ticket_citizenship
                                                .$error
                                        "
                                    >
                                        <p
                                            v-if="
                                                !$v.Ticketfields
                                                    .ticket_citizenship.required
                                            "
                                        >
                                            The Ticket Citizenship field is
                                            required.
                                        </p>
                                    </span>

                                    <span
                                        v-if="
                                            errors && errors.ticket_citizenship
                                        "
                                        class="invalid-feedback"
                                    >
                                        {{ errors.ticket_citizenship[0] }}
                                    </span>
                                    <!-- End Ticket Citizenship -->

                                    <!-- Ticket Category -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.Ticketfields.ticket_category
                                                    .$error ||
                                                errors.ticket_category,
                                            ' is-valid focus': !$v.Ticketfields
                                                .ticket_category.$invalid
                                        }"
                                    >
                                        <div class="i">
                                            <i
                                                class="fas fa-clipboard-list"
                                            ></i>
                                        </div>
                                        <div class="div">
                                            <select
                                                class=" input form-select"
                                                aria-label="Default select example"
                                                :class="{
                                                    'is-invalid':
                                                        $v.Ticketfields
                                                            .ticket_category
                                                            .$error ||
                                                        errors.ticket_category,
                                                    ' is-valid': !$v
                                                        .Ticketfields
                                                        .ticket_category
                                                        .$invalid
                                                }"
                                                v-model="
                                                    Ticketfields.ticket_category
                                                "
                                                name="ticket_category"
                                                id="ticket_category"
                                                required
                                                @blur="
                                                    $v.Ticketfields.ticket_category.$touch()
                                                "
                                                @click="
                                                    clear('ticket_category')
                                                "
                                            >
                                                <option
                                                    selected
                                                    disabled
                                                    value=""
                                                    >Ticket Category</option
                                                >
                                                <option value="Adult"
                                                    >Adult</option
                                                >
                                                <option value="Child"
                                                    >Child</option
                                                >
                                                <option
                                                    value="Senior Citizen of Sri Lanka"
                                                    >Senior Citizen of Sri
                                                    Lanka</option
                                                >
                                                <option value="Group"
                                                    >Group
                                                </option>
                                                <option
                                                    value="To Take Wedding Photos Before the botanical gardens open"
                                                    >To Take Wedding Photos
                                                    Before the botanical gardens
                                                    open
                                                </option>
                                                <option
                                                    value="o Take Wedding Photos After the botanical gardens open"
                                                    >To Take Wedding Photos
                                                    After the botanical gardens
                                                    open
                                                </option>
                                                <option value="For 7 people"
                                                    >For 7 people
                                                </option>
                                                <option value="For 3 people"
                                                    >For 3 people
                                                </option>
                                                <option value="For 22 people"
                                                    >For 22 people
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="
                                            $v.Ticketfields.ticket_category
                                                .$error
                                        "
                                    >
                                        <p
                                            v-if="
                                                !$v.Ticketfields.ticket_category
                                                    .required
                                            "
                                        >
                                            The Ticket Category field is
                                            required.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.ticket_category"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.ticket_category[0] }}
                                    </span>
                                    <!-- End Ticket Category -->

                                    <!-- Ticket sku -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.Ticketfields
                                                    .sku
                                                    .$error ||
                                                errors.sku,
                                            ' is-valid focus': !$v.Ticketfields
                                                .sku.$invalid
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-ticket-alt"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="ticket_sku"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Ticket SKU</label
                                            >
                                            <input
                                                type="text"
                                                class=" input form-control"
                                                :class="{
                                                    'is-invalid focus':
                                                        $v.Ticketfields
                                                            .sku
                                                            .$error ||
                                                        errors.sku,
                                                    ' is-valid focus': !$v
                                                        .Ticketfields
                                                        .sku
                                                        .$invalid
                                                }"
                                                v-model="
                                                    Ticketfields.sku
                                                "
                                                name="ticket_sku"
                                                id="ticket_sku"
                                                @input="
                                                    $v.Ticketfields.sku.$touch()
                                                "
                                                @blur="
                                                    $v.Ticketfields.sku.$touch()
                                                "
                                                @click="
                                                    clear(
                                                        'ticket_sku'
                                                    )
                                                "
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="
                                            $v.Ticketfields.sku
                                                .$error
                                        "
                                    >
                                        <p
                                            v-if="
                                                !$v.Ticketfields
                                                    .sku
                                                    .required
                                            "
                                        >
                                            The Ticket SKU is
                                            required.
                                        </p>                                     
                                                                                
                                    </span>

                                    <span
                                        v-if="
                                            errors &&
                                                errors.sku
                                        "
                                        class="invalid-feedback"
                                    >
                                        {{ errors.sku[0] }}
                                    </span>
                                    <!-- End Ticket sku -->

                                    <!-- Ticket Sinhala -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.Ticketfields
                                                    .ticket_label_sinhala
                                                    .$error ||
                                                errors.ticket_label_sinhala,
                                            ' is-valid focus': !$v.Ticketfields
                                                .ticket_label_sinhala.$invalid
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-ticket-alt"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="ticket_label_sinhala"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Ticket Label in Sinhala</label
                                            >
                                            <input
                                                type="text"
                                                class=" input form-control"
                                                :class="{
                                                    'is-invalid focus':
                                                        $v.Ticketfields
                                                            .ticket_label_sinhala
                                                            .$error ||
                                                        errors.ticket_label_sinhala,
                                                    ' is-valid focus': !$v
                                                        .Ticketfields
                                                        .ticket_label_sinhala
                                                        .$invalid
                                                }"
                                                v-model="
                                                    Ticketfields.ticket_label_sinhala
                                                "
                                                name="ticket_label_sinhala"
                                                id="ticket_label_sinhala"
                                                @input="
                                                    $v.Ticketfields.ticket_label_sinhala.$touch()
                                                "
                                                @blur="
                                                    $v.Ticketfields.ticket_label_sinhala.$touch()
                                                "
                                                @click="
                                                    clear(
                                                        'ticket_label_sinhala'
                                                    )
                                                "
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="
                                            $v.Ticketfields.ticket_label_sinhala
                                                .$error
                                        "
                                    >
                                        <p
                                            v-if="
                                                !$v.Ticketfields
                                                    .ticket_label_sinhala
                                                    .required
                                            "
                                        >
                                            The Ticket Type Sinhala Label is
                                            required.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.Ticketfields
                                                    .ticket_label_sinhala
                                                    .sinhala
                                            "
                                        >
                                            The Ticket Type Sinhala Label format
                                            is only contain Sinhala letters and
                                            spaces.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.Ticketfields
                                                    .ticket_label_sinhala
                                                    .minLength
                                            "
                                        >
                                            The Ticket Type Sinhala Label must
                                            be at least 3 characters.
                                        </p>
                                      
                                    </span>

                                    <span
                                        v-if="
                                            errors &&
                                                errors.ticket_label_sinhala
                                        "
                                        class="invalid-feedback"
                                    >
                                        {{ errors.ticket_label_sinhala[0] }}
                                    </span>
                                    <!-- End Ticket Sinhala -->

                                    <!-- Ticket Tamil -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.Ticketfields
                                                    .ticket_label_tamil
                                                    .$error ||
                                                errors.ticket_label_tamil,
                                            ' is-valid focus': !$v.Ticketfields
                                                .ticket_label_tamil.$invalid
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-ticket-alt"></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="ticket_label_tamil"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Ticket Label in Tamil</label
                                            >
                                            <input
                                                type="text"
                                                class=" input form-control"
                                                :class="{
                                                    'is-invalid focus':
                                                        $v.Ticketfields
                                                            .ticket_label_tamil
                                                            .$error ||
                                                        errors.ticket_label_tamil,
                                                    ' is-valid focus': !$v
                                                        .Ticketfields
                                                        .ticket_label_tamil
                                                        .$invalid
                                                }"
                                                v-model="
                                                    Ticketfields.ticket_label_tamil
                                                "
                                                name="ticket_label_tamil"
                                                id="ticket_label_tamil"
                                                @input="
                                                    $v.Ticketfields.ticket_label_tamil.$touch()
                                                "
                                                @blur="
                                                    $v.Ticketfields.ticket_label_tamil.$touch()
                                                "
                                                @click="
                                                    clear('ticket_label_tamil')
                                                "
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="
                                            $v.Ticketfields.ticket_label_tamil
                                                .$error
                                        "
                                    >
                                        <p
                                            v-if="
                                                !$v.Ticketfields
                                                    .ticket_label_tamil.required
                                            "
                                        >
                                            The Ticket Type field is required.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.Ticketfields
                                                    .ticket_label_tamil.tamil
                                            "
                                        >
                                            The Ticket Type format is only
                                            contain Tamil letters and spaces.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.Ticketfields
                                                    .ticket_label_tamil
                                                    .minLength
                                            "
                                        >
                                            The Ticket Type must be at least 3
                                            characters.
                                        </p>
                                      
                                    </span>

                                    <span
                                        v-if="
                                            errors && errors.ticket_label_tamil
                                        "
                                        class="invalid-feedback"
                                    >
                                        {{ errors.ticket_label_tamil[0] }}
                                    </span>
                                    <!-- End Ticket Tamil -->

                                    <!-- Ticket Price -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.Ticketfields.price.$error ||
                                                errors.price,
                                            ' is-valid focus': !$v.Ticketfields
                                                .price.$invalid
                                        }"
                                    >
                                        <div class="i">
                                            <i
                                                class="fas fa-money-bill-alt"
                                            ></i>
                                        </div>
                                        <div class="div">
                                            <label
                                                for="price"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Ticket Price</label
                                            >
                                            <input
                                                type="text"
                                                class=" input form-control"
                                                :class="{
                                                    'is-invalid focus':
                                                        $v.Ticketfields.price
                                                            .$error ||
                                                        errors.price,
                                                    ' is-valid focus': !$v
                                                        .Ticketfields.price
                                                        .$invalid
                                                }"
                                                v-model="Ticketfields.price"
                                                name="price"
                                                id="price"
                                                @input="
                                                    $v.Ticketfields.price.$touch()
                                                "
                                                @blur="
                                                    $v.Ticketfields.price.$touch()
                                                "
                                                @click="clear('price')"
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="$v.Ticketfields.price.$error"
                                    >
                                        <p
                                            v-if="
                                                !$v.Ticketfields.price.required
                                            "
                                        >
                                            The Ticket Price field is required.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.Ticketfields.price
                                                    .alphawithspa
                                            "
                                        >
                                            The Ticket Price format is only
                                            contain letters and spaces.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.Ticketfields.price.minLength
                                            "
                                        >
                                            The Ticket Price must be at least 3
                                            characters.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.Ticketfields.price.maxLength
                                            "
                                        >
                                            The Ticket Price must not be greater
                                            than 50 characters.
                                        </p>
                                    </span>

                                    <span
                                        v-if="errors && errors.price"
                                        class="invalid-feedback"
                                    >
                                        {{ errors.price[0] }}
                                    </span>
                                    <!-- End Ticket Price -->

                                    <!-- Select Allow Centers -->
                                    <div v-show="!editmode">
                                        <strong
                                            >Select Allow Center
                                            <small class="text-danger"
                                                >*</small
                                            ></strong
                                        >
                                        <div
                                            class="form-check text-left"
                                            v-for="(center,
                                            index) in CenterData"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                :value="center.centercode"
                                                id="center.name"
                                                v-bind:key="center.name"
                                                @input="AddOrRemoveCenter"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="flexCheckDefault"
                                            >
                                                {{ center.name }}
                                            </label>
                                        </div>
                                    </div>

                                    <div v-show="editmode">
                                        <strong
                                            >Select Allow / Not Allow Center
                                            <small class="text-danger"
                                                >*</small
                                            ></strong
                                        >
                                        <div
                                            class="form-check text-left"
                                            v-for="(center,
                                            index) in CenterData"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                :value="center.centercode"
                                                id="center.name"
                                                v-bind:key="center.name"
                                                :checked="
                                                    Ticketfields.allowCenter.includes(
                                                        center.centercode
                                                    )
                                                "
                                                @input="AddOrRemoveCenter"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="flexCheckDefault"
                                            >
                                                {{ center.name }}
                                            </label>
                                        </div>
                                    </div>
                                    <!-- End Select Allow Centers -->


                                    <!-- Online and counter allow -->
                                    <div>
                                        <strong
                                            >This Ticket Allow ,
                                            <small class="text-danger"
                                                >*</small
                                            ></strong>
                                            <div style="padding-top:10px">
                                            <label class="radio-inline">
                                                <input type="radio"  id="online" value="For Online" v-model="Ticketfields.allow_for" name="online" > For Online
                                            </label>

                                            <label class="radio-inline">
                                                <input type="radio"   id="counter" value="For Counter" v-model="Ticketfields.allow_for" name="counter">  For Counter
                                            </label>

                                            <label class="radio-inline">
                                                <input type="radio"   id="both" value="For Both" v-model="Ticketfields.allow_for" name="both"> For Both
                                            </label>
                                            </div>         
                                       
                                    </div>
                                    <!-- End online and counter allow -->
                                </div>
                            </div>

                            <div class="row modal-footer border-0">
                                <div class="col-md-6">
                                    <button
                                        v-show="!editmode"
                                        type="button"
                                        class="btn-small-width btn-warning"
                                        @click="clearFields"
                                    >
                                        Clear All Fields
                                    </button>
                                    <button
                                        v-show="editmode"
                                        type="button"
                                        class="btn-small-width btn-danger"
                                        @click="CloseModel"
                                    >
                                        Close
                                    </button>
                                </div>
                                <div class="col-md-3">
                                    <button
                                        type="submit"
                                        class="btn-small-width btn-success"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Create New Ticket -->

        <div v-show="loading" class="text-center">
            <div
                class="spinner-border spinner-border-lg text-success"
                role="status"
            >
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- Ticket Data table -->
        <div class="TicketData" v-show="!loading">
            <div class="login-content" style="display:block">
                <div class="form-group row  align-items-center">
                    <div class=" col-md-6">
                        <div
                            class="input-div"
                            :class="{
                                'is-invalid input-div-err focus':
                                    $v.tableData.search.$error || errors.search,
                                ' is-valid focus': !$v.tableData.search.$invalid
                            }"
                        >
                            <div class="i">
                                <i class="fas fa-search"></i>
                            </div>
                            <div class="div">
                                <label
                                    for="search"
                                    class="col-md-12 col-form-label text-md-left"
                                    >Search by using ticket type</label
                                >
                                <input
                                    type="text"
                                    class=" input form-control"
                                    :class="{
                                        'is-invalid focus':
                                            $v.tableData.search.$error ||
                                            errors.search,
                                        ' is-valid focus': !$v.tableData.search
                                            .$invalid
                                    }"
                                    v-model="tableData.search"
                                    name="search"
                                    id="search"
                                    @input="getTicketData()"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="control text-left">
                            <div class="select">
                                Show Rows
                                <select
                                    v-model="tableData.length"
                                    @change="getTicketData()"
                                    style="margin:10px"
                                >
                                    <option
                                        v-for="(records, index) in perPage"
                                        :key="index"
                                        :value="records"
                                        >{{ records }}</option
                                    >
                                </select>
                            </div>
                        </div>
                    </div>

                    <div
                        v-can="'Create Ticket'"
                        class="col-md-3 align-items-right"
                    >
                        <button
                            type="button"
                            class="btn-small-width btn-success-new "
                            data-toggle="modal"
                            data-target="#NewTicetModel"
                            @click="OpenNewTicekt"
                        >
                            <i class="fas fa-ticket-alt"></i> Create new Ticket
                        </button>
                    </div>
                </div>
            </div>

            <datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
            >
                <tbody v-for="ticekt in TicketData">
                    <tr
                        :key="ticekt.id"
                        v-if="ticekt.status == 0"
                        style="background-color:#fba5a54a"
                    >
                        <td>{{ ticekt.ticket_display_name }}</td>
                        <td>{{ ticekt.ticket_type }}</td>
                        <td>{{ ticekt.ticket_citizenship }}</td>
                        <td>{{ ticekt.ticket_category }}</td>
                        <td>{{ ticekt.price }}</td>
                        <td>{{ ticekt.allowCenter }}</td>
                        <td v-can="'Edit Ticket'">
                            <i class="fas fa-ban text-danger"></i>
                        </td>

                        <td v-canelase="'Edit Ticket'">
                            -
                        </td>

                        <td v-can="'UnBlock Ticket'">
                            <a @click="EnabalOrDisabale(ticekt.id, true)"
                                ><!-- 0 block  -->
                                <i class="far fa-eye-slash text-danger"></i
                            ></a>
                        </td>
                    </tr>
                    <tr :key="'block' + ticekt.id" v-else>
                        <td>{{ ticekt.ticket_display_name }}</td>
                        <td>{{ ticekt.ticket_type }}</td>
                        <td>{{ ticekt.ticket_citizenship }}</td>
                        <td>{{ ticekt.ticket_category }}</td>
                        <td>{{ ticekt.price }}</td>
                        <td>{{ ticekt.allowCenter }}</td>
                        <td v-can="'Edit Ticket'">
                            <a @click="OpenEditTicket(ticekt)">
                                <i class="far fa-edit text-warning"></i
                            ></a>
                        </td>
                        <td v-canelase="'Edit Ticket'">
                            -
                        </td>
                        <td v-can="'Block Ticket'">
                            <a @click="EnabalOrDisabale(ticekt.id, false)"
                                ><!-- 1 not block  -->
                                <i class="far fa-eye text-success"></i
                            ></a>
                        </td>
                    </tr>
                </tbody>
            </datatable>
            <pagination
                :pagination="pagination"
                @prev="getTicketData(pagination.prevPageUrl)"
                @next="getTicketData(pagination.nextPageUrl)"
            >
            </pagination>
        </div>
        <!-- End Ticket table -->
    </div>
</template>

<style scoped>
a:hover {
    cursor: pointer;
}
</style>

<script>
import {
    required,
    minLength,
    decimal,
    helpers
} from "vuelidate/lib/validators";
import { alphawithspa } from "vuelidate/lib/validators/alphawithspace";
const sinhala = helpers.regex("sinhala", /^[\u0D80-\u0DFF\s]+$/);
const tamil = helpers.regex("tamil", /^[\u0B80-\u0BFF\s]+$/);

const config = {
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json"
    }
};
let formData = new FormData();

export default {
    created() {
        this.AuthRole = Role;
        this.getTicketData();
    },
    data() {
        let sortOrders = {};

        let columns = [
            {
                width: "20%",
                label: "Ticket Display Name",
                name: "display_name"
            },
            { width: "10%", label: "Type", name: "type" },
            { width: "10%", label: "Citizenship", name: "ticket_citizenship" },
            { width: "10%", label: "Category", name: "Category" },
            { width: "10%", label: "Price", name: "price" },
            { width: "20%", label: "Allow Centers", name: "allow" },
            { width: "10%", label: "Edit", name: "edit" },
            { width: "10%", label: "Publish/Un-Publish", name: "block" }
        ];

        columns.forEach(column => {
            sortOrders[column.name] = -1;
        });
        return {
            Ticketfields: {
                index: "",
                sku:"",
                ticket_display_name: "",
                ticket_label_sinhala: "",
                ticket_label_tamil: "",
                ticket_type: "",
                ticket_citizenship: "",
                ticket_category: "",
                price: "",
                allow_for: "",
                allowCenter: []
            },

            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "desc",
                role: ""
            },

            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: ""
            },

            headerClass: "modal-header",
            ticektHeading: "Create New Ticket",
            avatar: "/img/avatar.svg",
            picval: "",
            editmode: false,
            loading: true,
            status: "",
            success: false,
            statusText: "",
            errors: {},
            AllRoleData: [],
            AuthRole: [],
            TicketData: [],
            CenterData: [],

            columns: columns,
            sortKey: "name",
            sortOrders: sortOrders,
            perPage: ["10", "20", "30"]
        };
    },

    validations: {
        Ticketfields: {
            ticket_display_name: {
                required,
                minLength: minLength(3),
                alphawithspa
            },
            ticket_label_sinhala: {
                required,
                minLength: minLength(3),
                sinhala
            },
            sku:{ required},
            ticket_label_tamil: { required, minLength: minLength(3), tamil },
            ticket_type: { required },
            ticket_citizenship: { required },
            ticket_category: { required },
            price: { required, decimal },
            allowCenter: { required },
            allow_for:{required}
        },

        tableData: {
            search: { required }
        }
    },

    methods: {
        //Ticket Section
        AddOrRemoveCenter(e) {
            if (e.target.checked == true) {
                this.Ticketfields.allowCenter.push(e.target.value);
            } else {
                this.Ticketfields.allowCenter = _.without(
                    this.Ticketfields.allowCenter,
                    e.target.value
                );
            }
        },

        CreateTicekt() {
            formData.append("_method", "POST");
            formData.append(
                "ticket_display_name",
                this.Ticketfields.ticket_display_name
            );
            formData.append(
                "ticket_label_sinhala",
                this.Ticketfields.ticket_label_sinhala
            );
            formData.append(
                "ticket_label_tamil",
                this.Ticketfields.ticket_label_tamil
            );
            formData.append("ticket_type", this.Ticketfields.ticket_type);
            formData.append(
                "ticket_citizenship",
                this.Ticketfields.ticket_citizenship
            );
            formData.append(
                "ticket_category",
                this.Ticketfields.ticket_category
            );
            formData.append("sku", this.Ticketfields.sku);
            formData.append("price", this.Ticketfields.price);
            formData.append(
                "allowCenter",
                JSON.stringify(this.Ticketfields.allowCenter)
            );
            formData.append("allow_for",this.Ticketfields.allow_for);


            axios
                .post("/ticket/create-ticket", formData, config)
                .then(response => {
                    this.CloseModel();
                    Toast.fire({
                        icon: "success",
                        title: "New Ticket Added"
                    });
                    this.CenterData = [];
                    this.getTicketData();
                })
                .catch(error => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong!"
                    });
                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.ticektHeading =
                            "Create New Ticket - Validation Erros";
                        this.errors = error.response.data.errors;
                    } else {
                        this.ticektHeading =
                            "Create New Ticket - Something went wrong /" +
                            error.response.data.errors.message;
                    }
                });
        },

        EditTicket() {
            formData.append("ticketid", this.Ticketfields.index);
            formData.append(
                "ticket_display_name",
                this.Ticketfields.ticket_display_name
            );
            formData.append(
                "ticket_label_sinhala",
                this.Ticketfields.ticket_label_sinhala
            );
            formData.append(
                "ticket_label_tamil",
                this.Ticketfields.ticket_label_tamil
            );
            formData.append("ticket_type", this.Ticketfields.ticket_type);
            formData.append(
                "ticket_citizenship",
                this.Ticketfields.ticket_citizenship
            );
            formData.append(
                "ticket_category",
                this.Ticketfields.ticket_category
            );
            formData.append("sku", this.Ticketfields.sku);
            formData.append("price", this.Ticketfields.price);
            formData.append(
                "allowCenter",
                JSON.stringify(this.Ticketfields.allowCenter)
            );
            formData.append("allow_for",this.Ticketfields.allow_for);

            axios
                .post("/ticket/edit-ticket", formData, config)
                .then(response => {
                    this.CenterData = [];
                    this.CloseModel();
                    Toast.fire({
                        icon: "success",
                        title: "Ticket Information Updated"
                    });
                    this.getTicketData();
                })
                .catch(error => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong!"
                    });
                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.ticektHeading = "Edit Ticket - Validation Error";
                        this.errors = error.response.data.errors;
                    } else {
                        this.ticektHeading =
                            "Edit Ticket - Something went wrong /" +
                            error.response.data.errors.message;
                    }
                });
        },

        getTicketData(url = "/ticket/get-ticket-data") {
            this.tableData.draw++;
            this.tableData.role = this.AuthRole;

            axios
                .get(url, { params: this.tableData })
                .then(response => {
                    let data = response.data;
                    this.CenterData = response.data.centerData;

                    if (this.tableData.draw == data.draw) {
                        this.TicketData = data.data.data;
                        this.configPagination(data.data);
                    }
                    this.loading = false;
                })
                .catch(errors => {
                    this.loading = false;

                    if (errors.response.status == 403) {
                        Toast.fire({
                            icon: "error",
                            title:
                                "Forbidden , User does not have the right roles "
                        });
                    }
                });
        },

        OpenNewTicekt() {
            $("#NewTicetModel").modal({
                backdrop: "static",
                keyboard: false,
                show: true
            });
            this.ticektHeading = "Create New Ticket";
            this.clearFields();
            this.clearError();
        },

        OpenEditTicket(TicketData) {
            this.editmode = true;
            $("#NewTicetModel").modal({
                backdrop: "static",
                keyboard: false,
                show: true
            });
            this.clearError();
            this.ticektHeading = "Edit Ticket ";
            this.Ticketfields.index = TicketData.id;
            this.Ticketfields.ticket_display_name =
                TicketData.ticket_display_name;
            this.Ticketfields.ticket_type = TicketData.ticket_type;
            this.Ticketfields.sku = TicketData.sku;
            this.Ticketfields.ticket_label_sinhala =
                TicketData.ticket_label_sinhala;
            this.Ticketfields.ticket_label_tamil =
                TicketData.ticket_label_tamil;
            this.Ticketfields.ticket_citizenship =
                TicketData.ticket_citizenship;
            this.Ticketfields.ticket_category = TicketData.ticket_category;
            this.Ticketfields.price = TicketData.price;
            this.Ticketfields.allowCenter = TicketData.allowCenter;
            this.Ticketfields.allow_for = TicketData.allow_for;
        },

        CloseModel() {
            $("#NewTicetModel").modal("hide");
            this.clearFields();
        },
        //End Ticket Section

        //Status Section
        EnabalOrDisabale($ticketID, status) {
            if (status == true) {
                this.statusText = "Publish";
            } else {
                this.statusText = "Un-Publish";
            }

            Swal.fire({
                title: "Are you sure?",
                text:
                    "You won't be able to " +
                    this.statusText +
                    " this Ticket Item",
                showCancelButton: true,
                confirmButtonColor: "#239B56",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, " + this.statusText + " this !"
            }).then(result => {
                if (result.isConfirmed) {
                    formData.append("ticketid", $ticketID);
                    formData.append("status", status);

                    axios
                        .post(
                            "/ticket/edit-visibility-update",
                            formData,
                            config
                        )
                        .then(response => {
                            Toast.fire({
                                icon: "success",
                                title: "Ticket Status Updated"
                            });
                            this.getTicketData();
                        })
                        .catch(error => {
                            Toast.fire({
                                icon: "error",
                                title:
                                    "Something went wrong!" +
                                    error.response.data.errors.message
                            });
                        });
                }
            });
        },
        //End status Section

        //Data table Section
        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

        sortBy(key) {
            this.sortKey = key;
            this.sortOrders[key] = this.sortOrders[key] * -1;
            this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
            this.getTicketData();
        },

        getIndex(array, key, value) {
            return array.findIndex(i => i[key] == value);
        },
        //End Data Table section

        //Clear Section
        clear(feild) {
            this.$delete(this.errors, feild);
        },

        clearFields() {
            this.editmode = "";
            this.Ticketfields.index = "";
            this.Ticketfields.ticket_display_name = "";
            this.Ticketfields.ticket_type = "";
            this.Ticketfields.ticket_citizenship = "";
            this.Ticketfields.ticket_category = "";
            this.Ticketfields.price = "";
            this.Ticketfields.ticket_label_sinhala = "";
            this.Ticketfields.ticket_label_tamil = "";
            this.Ticketfields.sku = "";
            this.Ticketfields.allowCenter = [];
            this.Ticketfields.allow_for ="";
            this.ticektHeading = "";
            this.headerClass = "modal-header";
            this.ticektHeading = "Create New Ticket";
            this.clearError();
        },

        clearError() {
            this.$v.$reset();
            this.errors = [];
        }
        //End Clear Section
    }
};
</script>
