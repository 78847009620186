<template>
    <div>
        <!-- Create New Client -->
        <div
            class="modal fade "
            id="NewClientModel"
            tabindex="-1"
            role="dialog"
            aria-labelledby="NewClientModelLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-md " role="document">
                <div class="modal-content">
                    <div :class="headerClass">
                        <h5
                            class="modal-title"
                            v-show="!editmode"
                            id="NewClientModelLabel"
                        >
                            {{ clientHeading }}
                        </h5>
                        <h5
                            class="modal-title"
                            v-show="editmode"
                            id="NewClientModelLabel"
                        >
                            {{ clientHeading }}
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            @click="CloseModel"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body login-content">
                        <form
                            class="full-with-form"
                            @submit.prevent="
                                editmode ? EditClient() : CreateClient()
                            "
                        >
                            <div class="form-group row" style="padding:25px">
                                <div class=" col-md-12">
                                    <!-- Client Type -->
                                    <div
                                        class="input-div"
                                        :class="{
                                            'is-invalid input-div-err focus':
                                                $v.Clientfields
                                                    .client_application_name
                                                    .$error ||
                                                errors.client_application_name,
                                            ' is-valid focus': !$v.Clientfields
                                                .client_application_name.$invalid
                                        }"
                                    >
                                        <div class="i">
                                            <i class="fas fa-client-alt"></i>
                                        </div>
                                        
                                        <div class="div">
                                            <label
                                                for="client_application_name"
                                                class="col-md-12 col-form-label text-md-left"
                                                >Client Application Name</label
                                            >
                                            <input
                                                type="text"
                                                class=" input form-control"
                                                :class="{
                                                    'is-invalid focus':
                                                        $v.Clientfields
                                                            .client_application_name
                                                            .$error ||
                                                        errors.client_application_name,
                                                    ' is-valid focus': !$v
                                                        .Clientfields
                                                        .client_application_name
                                                        .$invalid
                                                }"
                                                v-model="
                                                    Clientfields.client_application_name
                                                "
                                                name="client_application_name"
                                                id="client_application_name"
                                                @input="
                                                    $v.Clientfields.client_application_name.$touch()
                                                "
                                                @blur="
                                                    $v.Clientfields.client_application_name.$touch()
                                                "
                                                @click="
                                                    clear('client_application_name')
                                                "
                                            />
                                        </div>
                                    </div>

                                    <span
                                        class="invalid-feedback"
                                        v-if="
                                            $v.Clientfields.client_application_name
                                                .$error
                                        "
                                    >
                                        <p
                                            v-if="
                                                !$v.Clientfields
                                                    .client_application_name
                                                    .required
                                            "
                                        >
                                            The Client Application Name field is
                                            required.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.Clientfields
                                                    .client_application_name
                                                    .alphawithspa
                                            "
                                        >
                                            The Client Application Name format is
                                            only contain letters and spaces.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.Clientfields
                                                    .client_application_name
                                                    .minLength
                                            "
                                        >
                                            The Client Application Name must be at
                                            least 3 characters.
                                        </p>
                                        <p
                                            v-if="
                                                !$v.Clientfields
                                                    .client_application_name
                                                    .maxLength
                                            "
                                        >
                                            The Client Application Name must not be
                                            greater than 50 characters.
                                        </p>
                                    </span>

                                    <span
                                        v-if="
                                            errors && errors.client_application_name
                                        "
                                        class="invalid-feedback"
                                    >
                                        {{ errors.client_application_name[0] }}
                                    </span>
                                    <!-- End Client Type -->    
                                </div>
                            </div>

                            <div class="row modal-footer border-0">
                                <div class="col-md-6">
                                    <button
                                        v-show="!editmode"
                                        type="button"
                                        class="btn-small-width btn-warning"
                                        @click="clearFields"
                                    >
                                        Clear All Fields
                                    </button>
                                    <button
                                        v-show="editmode"
                                        type="button"
                                        class="btn-small-width btn-danger"
                                        @click="CloseModel"
                                    >
                                        Close
                                    </button>
                                </div>
                                <div class="col-md-3">
                                    <button
                                        type="submit"
                                        class="btn-small-width btn-success"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Create New Client -->

        <div v-show="loading" class="text-center">
            <div
                class="spinner-border spinner-border-lg text-success"
                role="status"
            >
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- Client Data table -->
        <div class="ClientData" v-show="!loading">
            <div class="login-content" style="display:block">
                <div class="form-group row  align-items-center">
                    <div class=" col-md-6">
                        <div
                            class="input-div"
                            :class="{
                                'is-invalid input-div-err focus':
                                    $v.tableData.search.$error || errors.search,
                                ' is-valid focus': !$v.tableData.search.$invalid
                            }"
                        >
                            <div class="i">
                                <i class="fas fa-search"></i>
                            </div>
                            <div class="div">
                                <label
                                    for="search"
                                    class="col-md-12 col-form-label text-md-left"
                                    >Search by using client type</label
                                >
                                <input
                                    type="text"
                                    class=" input form-control"
                                    :class="{
                                        'is-invalid focus':
                                            $v.tableData.search.$error ||
                                            errors.search,
                                        ' is-valid focus': !$v.tableData.search
                                            .$invalid
                                    }"
                                    v-model="tableData.search"
                                    name="search"
                                    id="search"
                                    @input="getClientData()"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="control text-left">
                            <div class="select">
                                Show Rows
                                <select
                                    v-model="tableData.length"
                                    @change="getClientData()"
                                    style="margin:10px"
                                >
                                    <option
                                        v-for="(records, index) in perPage"
                                        :key="index"
                                        :value="records"
                                        >{{ records }}</option
                                    >
                                </select>
                            </div>
                        </div>
                    </div>

                    <div
                        v-can="'Create Client'"
                        class="col-md-3 align-items-right"
                    >
                        <button
                            type="button"
                            class="btn-small-width btn-success-new "
                            data-toggle="modal"
                            data-target="#NewClientModel"
                            @click="OpenNewClient"
                        >
                            <i class="fas fa-client-alt"></i> Create new Client
                        </button>
                    </div>
                </div>
            </div>

            <datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
            >
                <tbody v-for="client in ClientData">
                    <tr  :key="client.id">
                        <td>{{ client.name }}</td>
                        <td>{{ client.id }}</td>
                        <td>{{ client.secret }}</td>        
                        <td>{{ client.redirect }}</td>                            
                        <td v-can="'Edit Client'">
                            <i class="fas fa-ban text-danger"></i>
                        </td>

                        <td v-canelase="'Edit Client'">
                            -
                        </td>

                        <td v-can="'UnBlock Client'">
                            <a @click="EnabalOrDisabale(client.id, true)"
                                ><!-- 0 block  -->
                                <i class="far fa-eye-slash text-danger"></i
                            ></a>
                        </td>
                    </tr>
                    
                </tbody>
            </datatable>
            <pagination
                :pagination="pagination"
                @prev="getClientData(pagination.prevPageUrl)"
                @next="getClientData(pagination.nextPageUrl)"
            >
            </pagination>
        </div>
        <!-- End Client table -->
    </div>
</template>

<style scoped>
a:hover {
    cursor: pointer;
}
</style>

<script>
import {
    required,
    minLength,
    decimal,
    helpers
} from "vuelidate/lib/validators";
import { alphawithspa } from "vuelidate/lib/validators/alphawithspace";
const sinhala = helpers.regex("sinhala", /^[\u0D80-\u0DFF\s]+$/);
const tamil = helpers.regex("tamil", /^[\u0B80-\u0BFF\s]+$/);

const config = {
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json"
    }
};
let formData = new FormData();

export default {
    created() {
        this.AuthRole = Role;
        this.getClientData();
    },
    data() {
        let sortOrders = {};

        let columns = [
            {
                width: "20%",
                label: "Client Application Name",
                name: "display_name"
            },         
            { width: "10%", label: "Client ID", name: "client_citizenship" },
            { width: "10%", label: "Secret", name: "Category" },
            { width: "10%", label: "Redirect URL", name: "price" },     
            { width: "10%", label: "Edit", name: "edit" },
            { width: "10%", label: "Publish/Un-Publish", name: "block" }
        ];

        columns.forEach(column => {
            sortOrders[column.name] = -1;
        });
        return {
            Clientfields: {
                index: "",
                sku:"",
                client_application_name: "",
                client_label_sinhala: "",
                client_label_tamil: "",
                client_type: "",
                client_citizenship: "",
                client_category: "",
                price: "",
                allow_for: "",
                allowCenter: []
            },

            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "desc",
                role: ""
            },

            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: ""
            },

            headerClass: "modal-header",
            clientHeading: "Create New Client",
            avatar: "/img/avatar.svg",
            picval: "",
            editmode: false,
            loading: true,
            status: "",
            success: false,
            statusText: "",
            errors: {},
            AllRoleData: [],
            AuthRole: [],
            ClientData: [],
            CenterData: [],

            columns: columns,
            sortKey: "name",
            sortOrders: sortOrders,
            perPage: ["10", "20", "30"]
        };
    },

    validations: {
        Clientfields: {
            client_application_name: {
                required,
                minLength: minLength(3),
                alphawithspa
            },
            client_label_sinhala: {
                required,
                minLength: minLength(3),
                sinhala
            },
            sku:{ required},
            client_label_tamil: { required, minLength: minLength(3), tamil },
            client_type: { required },
            client_citizenship: { required },
            client_category: { required },
            price: { required, decimal },
            allowCenter: { required },
            allow_for:{required}
        },

        tableData: {
            search: { required }
        }
    },

    methods: {
        //Client Section
        AddOrRemoveCenter(e) {
            if (e.target.checked == true) {
                this.Clientfields.allowCenter.push(e.target.value);
            } else {
                this.Clientfields.allowCenter = _.without(
                    this.Clientfields.allowCenter,
                    e.target.value
                );
            }
        },

        CreateClient() {
            formData.append("_method", "POST");
            formData.append(
                "name",
                this.Clientfields.client_application_name
            );
            formData.append(
                "redirect",
                'http://example.com/callback'
            );
            axios
                .post("/oauth/clients", formData, config)
                .then(response => {
                    this.CloseModel();
                    Toast.fire({
                        icon: "success",
                        title: "New Client Added"
                    });
                    this.CenterData = [];
                     this.getClientData();
                })
                .catch(error => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong!"
                    });
                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.clientHeading =
                            "Create New Client - Validation Errors";
                        this.errors = error.response.data.errors;
                    } else {
                        this.clientHeading =
                            "Create New Client - Something went wrong /" +
                            error.response.data.errors.message;
                    }
                });
        },

        EditClient() {
            formData.append("clientid", this.Clientfields.index);
            formData.append(
                "client_application_name",
                this.Clientfields.client_application_name
            );
            formData.append(
                "client_label_sinhala",
                this.Clientfields.client_label_sinhala
            );
            formData.append(
                "client_label_tamil",
                this.Clientfields.client_label_tamil
            );
            formData.append("client_type", this.Clientfields.client_type);
            formData.append(
                "client_citizenship",
                this.Clientfields.client_citizenship
            );
            formData.append(
                "client_category",
                this.Clientfields.client_category
            );
            formData.append("sku", this.Clientfields.sku);
            formData.append("price", this.Clientfields.price);
            formData.append(
                "allowCenter",
                JSON.stringify(this.Clientfields.allowCenter)
            );
            formData.append("allow_for",this.Clientfields.allow_for);

            axios
                .post("/client/edit-client", formData, config)
                .then(response => {
                    this.CenterData = [];
                    this.CloseModel();
                    Toast.fire({
                        icon: "success",
                        title: "Client Information Updated"
                    });
                    this.getClientData();
                })
                .catch(error => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong!"
                    });
                    this.headerClass = "modal-header-err";
                    if (error.response.status == 422) {
                        this.clientHeading = "Edit Client - Validation Error";
                        this.errors = error.response.data.errors;
                    } else {
                        this.clientHeading =
                            "Edit Client - Something went wrong /" +
                            error.response.data.errors.message;
                    }
                });
        },

        getClientData() {
            this.loading=true;
            axios
                .get("/application-setting/security-oauth-clients")
                .then((response) => {
            this.loading=false;

                    console.log(response);
                    this.ClientData = response.data;
                })
                .catch((error) => {
                    Toast.fire({
                        icon: "error",
                        title: "Something went wrong! ",
                    });
                });
        },

        OpenNewClient() {
            $("#NewClientModel").modal({
                backdrop: "static",
                keyboard: false,
                show: true
            });
            this.clientHeading = "Create New Client";
            this.clearFields();
            this.clearError();
        },

        OpenEditClient(ClientData) {
            this.editmode = true;
            $("#NewClientModel").modal({
                backdrop: "static",
                keyboard: false,
                show: true
            });
            this.clearError();
            this.clientHeading = "Edit Client ";
            this.Clientfields.index = ClientData.id;
            this.Clientfields.client_application_name =
                ClientData.client_application_name;
            this.Clientfields.client_type = ClientData.client_type;
            this.Clientfields.sku = ClientData.sku;
            this.Clientfields.client_label_sinhala =
                ClientData.client_label_sinhala;
            this.Clientfields.client_label_tamil =
                ClientData.client_label_tamil;
            this.Clientfields.client_citizenship =
                ClientData.client_citizenship;
            this.Clientfields.client_category = ClientData.client_category;
            this.Clientfields.price = ClientData.price;
            this.Clientfields.allowCenter = ClientData.allowCenter;
            this.Clientfields.allow_for = ClientData.allow_for;
        },

        CloseModel() {
            $("#NewClientModel").modal("hide");
            this.clearFields();
        },
        //End Client Section

        //Status Section
        EnabalOrDisabale($clientID, status) {
            if (status == true) {
                this.statusText = "Publish";
            } else {
                this.statusText = "Un-Publish";
            }

            Swal.fire({
                title: "Are you sure?",
                text:
                    "You won't be able to " +
                    this.statusText +
                    " this Client Item",
                showCancelButton: true,
                confirmButtonColor: "#239B56",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, " + this.statusText + " this !"
            }).then(result => {
                if (result.isConfirmed) {
                    formData.append("clientid", $clientID);
                    formData.append("status", status);

                    axios
                        .post(
                            "/client/edit-visibility-update",
                            formData,
                            config
                        )
                        .then(response => {
                            Toast.fire({
                                icon: "success",
                                title: "Client Status Updated"
                            });
                            this.getClientData();
                        })
                        .catch(error => {
                            Toast.fire({
                                icon: "error",
                                title:
                                    "Something went wrong!" +
                                    error.response.data.errors.message
                            });
                        });
                }
            });
        },
        //End status Section

        //Data table Section
        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

        sortBy(key) {
            this.sortKey = key;
            this.sortOrders[key] = this.sortOrders[key] * -1;
            this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
            this.getClientData();
        },

        getIndex(array, key, value) {
            return array.findIndex(i => i[key] == value);
        },
        //End Data Table section

        //Clear Section
        clear(feild) {
            this.$delete(this.errors, feild);
        },

        clearFields() {
            this.editmode = "";
            this.Clientfields.index = "";
            this.Clientfields.client_application_name = "";
            this.Clientfields.client_type = "";
            this.Clientfields.client_citizenship = "";
            this.Clientfields.client_category = "";
            this.Clientfields.price = "";
            this.Clientfields.client_label_sinhala = "";
            this.Clientfields.client_label_tamil = "";
            this.Clientfields.sku = "";
            this.Clientfields.allowCenter = [];
            this.Clientfields.allow_for ="";
            this.clientHeading = "";
            this.headerClass = "modal-header";
            this.clientHeading = "Create New Client";
            this.clearError();
        },

        clearError() {
            this.$v.$reset();
            this.errors = [];
        }
        //End Clear Section
    }
};
</script>
